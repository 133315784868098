import { SatelliteLayer } from '@/models/SatelliteType';

const notCloudCorrection = [
  SatelliteLayer.TRUE_COLOR,
  SatelliteLayer.COLOR_INFRARED,
  SatelliteLayer.RVI,
];

const notPresentation = [SatelliteLayer.TRUE_COLOR, SatelliteLayer.COLOR_INFRARED];

export const hasCloudCorrection = (presentation: string, layerType: SatelliteLayer): boolean => presentation === ''
  && !notCloudCorrection.includes(layerType);

export const hasPresentation = (layerType: SatelliteLayer): boolean => layerType
  && !notPresentation.includes(layerType);

export const layerUrl = (settings, fieldId: number) => {
  const {
    layerType, presentationType, presentation, cloudCorrection, imageDt,
  } = settings;

  const presentationLayer = presentationType && hasPresentation(layerType) ? `-${presentationType}` : '';
  const cloudCorrectionLayer = cloudCorrection && hasCloudCorrection(presentation, layerType) ? '-CC' : '';

  const layer = layerType + presentationLayer + cloudCorrectionLayer;
  return `/api/sat_data/image/${fieldId}?time=${imageDt}&layers=${layer}`;
};
