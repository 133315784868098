import script from "./TaskCard.vue?vue&type=script&lang=ts&setup=true"
export * from "./TaskCard.vue?vue&type=script&lang=ts&setup=true"

import "./TaskCard.vue?vue&type=style&index=0&id=13982085&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QCardActions,QSpace,QBtn});
