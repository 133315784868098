import script from "./ListOrganization.vue?vue&type=script&lang=ts&setup=true"
export * from "./ListOrganization.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QToolbar,QBtn,QToolbarTitle,QLinearProgress,QVirtualScroll});
