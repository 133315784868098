import script from "./ItemListMeteo.vue?vue&type=script&lang=ts&setup=true"
export * from "./ItemListMeteo.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QItemLabel});qInstall(script, 'directives', {Ripple});
