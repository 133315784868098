import CrudService from '@/api/configs/crud.service';
import { Organization } from '@/models/Organization';

class OrganizationApiService extends CrudService<Organization, Organization> {
  protected ENDPOINT = 'organizations';

  protected normalizeData(data:Organization) {
    return data;
  }
}

const organizationApiService = new OrganizationApiService();

export default organizationApiService;
