import BaseCrud from '@/api/configs/base-crud';

class AreaSchemeDateAnalysisApiService extends BaseCrud<string> {
    protected ENDPOINT = (fieldId: number, elementaryAreasSchemeId: number) => `fields/${fieldId}/elementary_area_scheme/${elementaryAreasSchemeId}/analysis`;

    getAllDatesAreaScheme(
      fieldId: number,
      elementaryAreasSchemeId: number,
    ) {
      return super.getData(`${this.ENDPOINT(fieldId, elementaryAreasSchemeId)}/all_dates`);
    }

    getAllDatesField(
      fieldId: number,
    ) {
      return super.getData(`/fields/${fieldId}/all_dates`);
    }
}

const areaSchemeDateAnalysisApiService = new AreaSchemeDateAnalysisApiService();

export default areaSchemeDateAnalysisApiService;
