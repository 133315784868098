import script from "./ReportResults.vue?vue&type=script&lang=ts&setup=true"
export * from "./ReportResults.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardSection,QSeparator,QAvatar});
