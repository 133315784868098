import { Point } from 'ol/geom';
import { Feature } from 'ol';
import Map from '@/modules/map/data/Map';
import {
  Ref, UnwrapRef,
} from 'vue';
import AssayType from '@/dialogs/AssayDialog/types/AssayType';
import useDrawAutoBionic from '@/dialogs/AssayDialog/hooks/useDrawAutoBionic';
import useDrawAutoGost from '@/dialogs/AssayDialog/hooks/useDrawAutoGost';

export default function useDrawAuto(
  map: Map,
  positionsPolygons: Ref<UnwrapRef<{polygon: Feature, point: Feature<Point> }[][]>>,
  polygons: Ref<UnwrapRef<Feature[]>>,
  points: Ref<UnwrapRef<Feature<Point>[]>>,
  activeGridType: Ref<UnwrapRef<AssayType>>,
  startNumberPolygon: Ref<UnwrapRef<number>>,
) {
  const drawAutoInteraction = activeGridType.value === AssayType.Gost
    ? useDrawAutoGost(
      map,
      points,
      positionsPolygons,
      startNumberPolygon,
    )
    : useDrawAutoBionic(map, points, startNumberPolygon);

  const finishDrawAuto = () => {
    drawAutoInteraction.finishDrawing();
  };

  return { drawAutoInteraction, finishDrawAuto };
}
