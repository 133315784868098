import Feature from 'ol/Feature';
import {
  cratePolygon, formatGeoJSONReadFeatures, formatGeoJSONWriteFeature, formatGeoJSONWriteFeatures,
} from '@/helpers/map';
import bbox from '@turf/bbox';
import { randomPoint } from '@turf/random';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import clustersKmeans from '@turf/clusters-kmeans';
import { featureCollection, point } from '@turf/helpers';
import voronoi from '@turf/voronoi';
import intersect from '@turf/intersect';
import {
  getCenter, getHeight, getTopLeft, getWidth,
} from 'ol/extent';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';
import centerOfMass from '@turf/center-of-mass';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import DataFeature from '@/dialogs/AssayDialog/types/DataFeature';
import { Coordinate } from 'ol/coordinate';
import { getArea, getLength } from 'ol/sphere';
import AssayType from '@/dialogs/AssayDialog/types/AssayType';
import FieldFeature from '@/modules/map/features/FieldFeature';

export default function useGrid() {
  const {
    setPolygonInPoint, setPointInPolygon, getPositionsFeature, setDataFeature,
  } = useAdditionalFunctionsFeature();

  const getGridBionic = (fieldFeature: FieldFeature, newGridArea = 3) => {
    if (fieldFeature.props.area <= newGridArea) {
      const result = fieldFeature.clone();
      result.setProperties({
        area: fieldFeature.props.area,
      });
      return [result];
    }
    const fieldFeatureProjection = formatGeoJSONWriteFeature(fieldFeature);
    const polygonBbox = bbox(fieldFeatureProjection);

    const randomPoints = randomPoint(10000, { bbox: polygonBbox });
    randomPoints.features = randomPoints.features
      .filter((feature) => (
        // @ts-ignore
        booleanPointInPolygon(feature.geometry.coordinates, fieldFeatureProjection)));

    const numberOfClusters = Math.round(
      (getArea(fieldFeature.getGeometry()) * 0.0001) / newGridArea,
    );

    const clusteredPoints = clustersKmeans(randomPoints, {
      numberOfClusters,
    });

    const centroidPoints = [];
    for (let i = 0; i < numberOfClusters; i += 1) {
      const feature = clusteredPoints.features.find(
        (feature) => (feature.properties.cluster === i),
      );
      centroidPoints[i] = point(feature.properties.centroid);
    }

    const voronoiPolygons = voronoi(
      { type: 'FeatureCollection', features: centroidPoints },
      { bbox: polygonBbox },
    );

    const clippedVoronoiPolygons = voronoiPolygons.features.map((feature) => {
      // @ts-ignore
      const clippedFeature = intersect(feature.geometry, fieldFeatureProjection);
      clippedFeature.properties.area = Math.round(
        ((getArea(cratePolygon(clippedFeature.geometry))) / 10000) * 100,
      ) / 100;

      return clippedFeature;
    });

    return formatGeoJSONReadFeatures(
      featureCollection(clippedVoronoiPolygons),
    );
  };

  const getGridScale = (
    polygonFeature: Feature,
    newGridArea,
    centerPoint: Coordinate,
    { widthScale, heightScale }: {widthScale: number, heightScale: number},
  ) => {
    const extent = polygonFeature.getGeometry().getExtent();
    const k = Math.sqrt((newGridArea * 10000) / (widthScale * heightScale));
    const sizeX = 1 / getLength(
      new LineString([[extent[0], extent[1]], [extent[0], extent[1] + 1]]),
    );
    const sizeY = 1 / getLength(
      new LineString([[extent[0], extent[1]], [extent[0] + 1, extent[1]]]),
    );
    const sizeSquareWidth = sizeX * k * widthScale;
    const sizeSquareHeight = sizeY * k * heightScale;

    const polygonsGrid = [];

    const gridWidth = getWidth(extent);
    const gridHeight = getHeight(extent);

    const gridSize = gridWidth > gridHeight ? gridWidth : gridHeight;

    const colsCount = Math.ceil(Math.abs(gridSize / sizeSquareWidth)) + 2;
    const rowsCound = Math.ceil(Math.abs(gridSize / sizeSquareHeight)) + 2;

    const topLeftPoint = getTopLeft(extent);
    const centerExtent = getCenter(extent);
    if (centerExtent[0] !== centerPoint[0] || centerExtent[1] !== centerPoint[1]) {
      topLeftPoint[0] = centerPoint[0] - (colsCount * sizeSquareWidth) / 2;
      topLeftPoint[1] = centerPoint[1] + (rowsCound * sizeSquareHeight) / 2;
    } else {
      topLeftPoint[0] -= Math.round(((colsCount
          - Math.ceil(Math.abs(gridWidth / sizeSquareWidth))) / 2)) * sizeSquareWidth;
      topLeftPoint[1] += Math.round(((rowsCound
          - Math.ceil(Math.abs(gridHeight / sizeSquareHeight))) / 2)) * sizeSquareHeight;
    }

    const [x, y] = topLeftPoint;

    for (let i = 0; i <= rowsCound - 1; i += 1) {
      for (let j = 0; j <= colsCount - 1; j += 1) {
        const polygon = [
          [x + (sizeSquareWidth * j), y - (sizeSquareHeight * i)],
          [x + (sizeSquareWidth * (j + 1)), y - (sizeSquareHeight * i)],
          [x + (sizeSquareWidth * (j + 1)), y - (sizeSquareHeight * (i + 1))],
          [x + (sizeSquareWidth * j), y - (sizeSquareHeight * (i + 1))],
          [x + (sizeSquareWidth * j), y - (sizeSquareHeight * i)],
        ];
        polygonsGrid.push([polygon]);
      }
    }

    const grid = new Feature({ geometry: new MultiPolygon(polygonsGrid) });
    grid.setStyle([new Style({
      stroke: new Stroke({
        color: 'rgba(239,239,239,0.73)',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(57,73,80,0)',
      }),
    })]);

    return { grid, colsCount };
  };

  const getCenterGrids = (polygons: Feature[], activeGridType: AssayType) => {
    const centerPoints = formatGeoJSONWriteFeatures(polygons as Feature[]).features
      .map((item) => {
        const centerPolygon = centerOfMass(item);
        centerPolygon.properties = item.properties;
        return centerPolygon;
      });

    const formatCenterPoints = formatGeoJSONReadFeatures(
      featureCollection(centerPoints),
    ) as Feature<Point>[];

    const newPositionsPolygons = [];
    if (activeGridType === AssayType.Gost) {
      polygons.forEach((polygon, i) => {
        const [y, x] = getPositionsFeature(polygon);
        if (newPositionsPolygons[y] == null) {
          newPositionsPolygons[y] = [];
        }

        const point = formatCenterPoints[i];
        newPositionsPolygons[y][x] = { polygon, point };
        setPointInPolygon(polygon, point);
        setPolygonInPoint(point, polygon);

        const dataFeature: DataFeature = {
          index: null,
          attributes: null,
          num: null,
        };

        setDataFeature(polygon, dataFeature);
        setDataFeature(point, dataFeature);
      });
    } else {
      polygons.forEach((polygon, i) => {
        const point = formatCenterPoints[i];
        setPointInPolygon(polygon, point);
        setPolygonInPoint(point, polygon);

        const dataFeature: DataFeature = {
          index: null,
          attributes: null,
          num: null,
        };

        setDataFeature(polygon, dataFeature);
        setDataFeature(point, dataFeature);
      });
    }

    return { newPositionsPolygons, centerPoints: formatCenterPoints };
  };

  return {
    getGridBionic, getGridScale, getCenterGrids,
  };
}
