import {
  Fill, Stroke, Style, Text,
} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';

const fill = new Fill({
  color: 'rgba(255,255,255,0.4)',
});

const stroke = new Stroke({
  color: '#3399CC',
  width: 1.25,
});

export default function useFeatureElements() {
  const createPoint = (
    coordinate: Coordinate,
    textTop: string,
    titleCenter: string,
  ) => {
    const cashPoint = {};
    let font = '';
    let oldResolution = 0;
    const stylePoint = (feature, resolution) => {
      if (oldResolution !== resolution) {
        const size = (40 / resolution) > 20 ? 20 : (40 / resolution);

        font = `${size}px roboto,sans-serif`;
        oldResolution = resolution;
      }
      const id = feature.ol_uid;

      if (cashPoint[id]) {
        const styles = cashPoint[id];
        styles.forEach((style) => {
          style.getText().setFont(font);
        });
      } else {
        cashPoint[id] = [new Style({
          text: new Text({
            font,
            text: textTop,
            fill: new Fill({
              color: '#333',
            }),
            stroke: new Stroke({
              color: '#fff', width: 2,
            }),
            textAlign: 'center',
            textBaseline: 'bottom',
            offsetY: -5,
            offsetX: 0,
            overflow: true,
          }),
          image: new CircleStyle({
            fill,
            stroke,
            radius: 5,
          }),
          fill,
          stroke,
        }), new Style({
          text: new Text({
            font,
            text: titleCenter,
            fill: new Fill({
              color: '#333',
            }),
            stroke: new Stroke({
              color: '#fff', width: 2,
            }),
            textAlign: 'center',
            textBaseline: 'top',
            offsetY: 6,
            offsetX: 0,
            overflow: true,
          }),
        }),
        ];
      }

      return cashPoint[id];
    };

    const pointFeature = new Feature({ geometry: new Point(coordinate) });
    pointFeature.setStyle(stylePoint);

    return pointFeature;
  };
  return { createPoint };
}
