import * as Excel from 'exceljs/dist/exceljs.min';
import { Organization, SpeciesData } from '@/modules/species-structure/species-table/types/SpeciesData';
import {
  createArrayLengthText, saveExcelFile, setTotalColor, updateMaxWidthCells, updateWidthColumns,
} from '@/helpers/excel';

class ExcelService {
  initDownload(columns: Partial<any>[]) {
    const workbook = new Excel.Workbook();
    workbook.creator = 'KleverFarmer';
    workbook.lastModifiedBy = 'KleverFarmer';

    const worksheet = workbook.addWorksheet('Sheet', {
      views: [
        { state: 'frozen', ySplit: 1 },
      ],
    });

    const arrayLengthText = createArrayLengthText(columns.length);

    const rowHeader = worksheet.addRow(columns.map(({ label }) => label));
    rowHeader.alignment = { horizontal: 'center' };
    rowHeader.font = {
      bold: true,
    };

    updateMaxWidthCells(rowHeader, arrayLengthText);

    const districtMergeCells = { start: 2, end: 1 };
    const organizationMergeCells = { start: 2, end: 1 };

    return {
      worksheet,
      workbook,
      districtMergeCells,
      organizationMergeCells,
      arrayLengthText,
    };
  }

  exportRegionReportAsExcelFile(
    headers: Partial<any>[],
    speciesData: SpeciesData[],
    regionTitle: string,
  ) {
    const {
      worksheet,
      workbook,
      districtMergeCells,
      organizationMergeCells,
      arrayLengthText,
    } = this.initDownload(headers);

    let regionSumArea = 0;

    speciesData.forEach(({ district, organizations }) => {
      let districtSumArea = 0;
      organizations.forEach(({ title, species }) => {
        species.forEach(({ area, specieTitle }) => {
          const newRow = worksheet.addRow([district, title, specieTitle, area.toFixed(2)]);
          districtMergeCells.end += 1;
          organizationMergeCells.end += 1;
          districtSumArea += area;
          updateMaxWidthCells(newRow, arrayLengthText);
        });
        worksheet.mergeCells(`B${organizationMergeCells.start}:B${organizationMergeCells.end}`);
        worksheet.getCell(`B${organizationMergeCells.start}`).alignment = { vertical: 'middle', horizontal: 'center' };
        organizationMergeCells.start = organizationMergeCells.end + 1;
      });
      worksheet.mergeCells(`A${districtMergeCells.start}:A${districtMergeCells.end}`);
      worksheet.getCell(`A${districtMergeCells.start}`).alignment = { vertical: 'middle', horizontal: 'center' };
      const rowTotal = worksheet.addRow(['Итого:', '', '', districtSumArea.toFixed(2)]);

      setTotalColor(rowTotal, '96C8FB');

      districtMergeCells.start = districtMergeCells.end + 2;
      districtMergeCells.end = districtMergeCells.start - 1;
      organizationMergeCells.start += 1;
      organizationMergeCells.end = organizationMergeCells.start - 1;
      regionSumArea += districtSumArea;
    });

    const rowTotalResult = worksheet.addRow([`Итого по ${regionTitle}:`, '', '', regionSumArea.toFixed(2)]);

    setTotalColor(rowTotalResult, '87b4e1');

    updateMaxWidthCells(rowTotalResult, arrayLengthText);
    updateWidthColumns(worksheet, arrayLengthText);

    saveExcelFile(workbook, 'KleverFarmer:');
  }

  exportDistrictReportAsExcelFile(
    columns: Partial<any>[],
    speciesData: Organization[],
    districtTitle: string,
  ) {
    const {
      worksheet,
      workbook,
      organizationMergeCells,
      arrayLengthText,
    } = this.initDownload(columns);

    let districtSumArea = 0;

    speciesData.forEach(({ title, species }) => {
      let organizationSumArea = 0;
      species.forEach(({ area, specieTitle }) => {
        const newRow = worksheet.addRow([title, specieTitle, area.toFixed(2)]);
        organizationMergeCells.end += 1;
        organizationSumArea += area;
        updateMaxWidthCells(newRow, arrayLengthText);
      });
      worksheet.mergeCells(`A${organizationMergeCells.start}:A${organizationMergeCells.end}`);
      worksheet.getCell(`A${organizationMergeCells.start}`).alignment = { vertical: 'middle', horizontal: 'center' };
      const rowTotal = worksheet.addRow(['Итого:', '', organizationSumArea.toFixed(2)]);
      setTotalColor(rowTotal, '96C8FB');

      organizationMergeCells.start = organizationMergeCells.end + 2;
      organizationMergeCells.end = organizationMergeCells.start - 1;

      districtSumArea += organizationSumArea;
    });

    const rowTotalResult = worksheet.addRow([`Итого по ${districtTitle}:`, '', districtSumArea.toFixed(2)]);

    setTotalColor(rowTotalResult, '87b4e1');

    updateMaxWidthCells(rowTotalResult, arrayLengthText);

    updateWidthColumns(worksheet, arrayLengthText);

    saveExcelFile(workbook, 'KleverFarmer:');
  }

  exportOrgReportAsExcelFile(columns: Partial<any>[], speciesData: Organization[]) {
    const {
      worksheet,
      workbook,
      organizationMergeCells,
      arrayLengthText,
    } = this.initDownload(columns);

    const { species, title } = speciesData[0];

    let organizationSumArea = 0;
    species.forEach(({ area, specieTitle }) => {
      const newRow = worksheet.addRow([title, specieTitle, area.toFixed(2)]);
      organizationMergeCells.end += 1;
      organizationSumArea += area;
      updateMaxWidthCells(newRow, arrayLengthText);
    });
    worksheet.mergeCells(`A${organizationMergeCells.start}:A${organizationMergeCells.end}`);
    worksheet.getCell(`A${organizationMergeCells.start}`).alignment = { vertical: 'middle', horizontal: 'center' };
    const rowTotal = worksheet.addRow(['Итого:', '', organizationSumArea.toFixed(2)]);
    setTotalColor(rowTotal, '96C8FB');

    updateWidthColumns(worksheet, arrayLengthText);

    saveExcelFile(workbook, 'KleverFarmer:');
  }
}

const excelService = new ExcelService();

export default excelService;
