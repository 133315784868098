import CrudService from '@/api/configs/crud.service';
import { SowCycle } from '@/modules/field/types/SowCycle';

class SowCycleApiService extends CrudService<SowCycle, SowCycle> {
  protected ENDPOINT = 'sow_cycles';

  protected normalizeData(data: SowCycle) {
    return data;
  }
}

const sowCycleApiService = new SowCycleApiService();

export default sowCycleApiService;
