import { AxiosRequestConfig } from 'axios';
import CrudService from '@/api/configs/crud.service';

class SatelliteApiService extends CrudService {
  protected ENDPOINT = 'sat_data';

  protected normalizeData(data: any) {
    return data;
  }

  pull(options?: AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT}/pull`, options);
  }
}
const satelliteApiService = new SatelliteApiService();

export default satelliteApiService;
