import { Region } from '@/dialogs/FieldManageDialog/types/Region';
import BaseCrud from '@/api/configs/base-crud';

class RegionTitleApiService extends BaseCrud<Region> {
  ENDPOINT = 'regions';

  getRegionById(id: number) {
    return super.getById(`${this.ENDPOINT}/${id}`);
  }
}
const regionTitleApiService = new RegionTitleApiService();

export default regionTitleApiService;
