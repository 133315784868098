const routeNames = {
  error_403: 'error_403',
  error_404: 'error_404',
  default: 'default',
  districts: 'districts',
  organizations: 'organizations',
  fields: 'fields',
  fieldsId: 'fields_id',
  directories: 'directories',
  directoriesOperations: 'directories_operations',
  directoriesMachineries: 'directories_machineries',
  splitMap: 'split_map',
  scoutingTask: 'scouting_task',
  meteos: 'meteos',
  meteoHealth: 'meteo_health',
  organizationProfile: 'organizationProfile',
  speciesDistribution: 'species_distribution',
  speciesTable: 'species_table',
  fieldsTable: 'fields_table',
  fieldsAnalysisReport: 'fields_analysis_report',
  meteosId: 'meteos_id',
  login: 'login',
  fieldOperations: 'field_operations',
  fieldHistory: 'field_history',
  fieldSatellite: 'field_satellite',
  fieldScouting: 'field_scouting',
};

export default routeNames;
