import BaseCrud from '@/api/configs/base-crud';
import DailyForecast from '@/modules/field/types/DailyForecast';

class DailyForecastApiService extends BaseCrud<DailyForecast> {
  ENDPOINT = 'daily_forecast';

  getById5Days(fieldId: number, options?) {
    return super.getData(`/forecasts/${fieldId}/daily`, options);
  }
}

const dailyForecastApiService = new DailyForecastApiService();

export default dailyForecastApiService;
