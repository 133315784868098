export type Method = {
  alias: string,
  text: string
};

export enum MethodsAlias {
  poll = 'poll',
  api = 'api',
  mqtt = 'mqtt'
}
