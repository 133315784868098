import { Observable, of } from 'rxjs';
import httpService from '@/api/configs/http.service';
import { SubStage } from '@/dialogs/ReportScoutingTaskDialog/types/GrowthStage';

class StagesApiService {
  getStages(specieIds: number[]): Observable<SubStage[]> {
    return specieIds.length ? httpService.get<SubStage[]>(`get_stages?specieIds=${JSON.stringify(specieIds)}`) : of([]);
  }
}

const stagesApiService = new StagesApiService();

export default stagesApiService;
