import {
  computed, onMounted, onUnmounted, Ref, ref, UnwrapRef, watch,
} from 'vue';
import { Feature } from 'ol';
import {
  LineString, MultiLineString, MultiPoint,
} from 'ol/geom';
import Map from '@/modules/map/data/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Coordinate } from 'ol/coordinate';
import {
  Fill, Stroke, Style,
} from 'ol/style';
import CircleStyle from 'ol/style/Circle';

export default function useShowRoute(
  map: Map,
  samplingPoints: Ref<UnwrapRef<Feature<MultiPoint>>>,
  locationEquipment: Ref<UnwrapRef<Feature<MultiLineString>>>,
) {
  const pointsSource = new VectorSource({
    wrapX: false,
  });

  const pointsVector = new VectorLayer({
    source: pointsSource,
    zIndex: 6,
    style: () => new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: '#25de10',
        }),
        stroke: new Stroke({
          color: 'rgb(239,0,0)',
          width: 2,
        }),
        radius: 6,
      }),
    }),
  });

  const routeSource = new VectorSource({
    wrapX: false,
  });

  const routeVector = new VectorLayer({
    source: routeSource,
    zIndex: 3,
  });

  let currentLineStringFeature = null;

  const fieldFeature = map.source.getFeatureById('field');

  const isShowToggleRoute = computed(() => !!(samplingPoints.value || locationEquipment.value));
  const isShowRoute = ref(false);

  const getIsInsideField = (coordinate: Coordinate) => fieldFeature
    .getGeometry().intersectsCoordinate(coordinate);

  const setStyleLineStringFeature = (lineStringFeature: Feature<LineString>) => {
    const isInsideField = lineStringFeature.get('isInsideField');
    lineStringFeature.setStyle(new Style({
      stroke: new Stroke({ color: isInsideField ? '#f44336' : '#2196f3', width: 3 }),
    }));
  };

  const getNewLineStringFeature = (coordinate?: Coordinate) => {
    const isInsideField = coordinate ? getIsInsideField(coordinate) : false;
    const newLineStringFeature = new Feature({ geometry: new LineString(coordinate || []) });
    newLineStringFeature.set('isInsideField', isInsideField);
    setStyleLineStringFeature(newLineStringFeature);
    return newLineStringFeature;
  };

  const addPointOrCreateLineString = (coordinate: Coordinate) => {
    const isInsideField = fieldFeature.getGeometry().intersectsCoordinate(coordinate);
    if (isInsideField === currentLineStringFeature.get('isInsideField')) {
      currentLineStringFeature.getGeometry().appendCoordinate(coordinate);
      return;
    }

    const newLineStringFeature = new Feature({ geometry: new LineString([coordinate]) });
    newLineStringFeature.set('isInsideField', isInsideField);
    setStyleLineStringFeature(newLineStringFeature);

    routeSource.addFeature(newLineStringFeature);
    currentLineStringFeature = newLineStringFeature;
  };

  const handlerChangeShowRoute = (status) => {
    pointsSource.clear();
    routeSource.clear();
    if (status) {
      pointsSource.addFeature(samplingPoints.value as Feature<MultiPoint>);
      locationEquipment.value.getGeometry().getLineStrings().forEach((lineStrings) => {
        currentLineStringFeature = getNewLineStringFeature();
        routeSource.addFeature(currentLineStringFeature);
        lineStrings.getCoordinates().forEach((coordinate) => {
          addPointOrCreateLineString(coordinate);
        });
      });
    }
  };

  watch([samplingPoints, locationEquipment], () => {
    handlerChangeShowRoute(isShowRoute.value);
  });

  onMounted(() => {
    map.addLayers([pointsVector, routeVector]);
  });

  onUnmounted(() => {
    map.map.removeLayer(routeVector);
    map.map.removeLayer(pointsVector);
  });

  return { isShowToggleRoute, isShowRoute, handlerChangeShowRoute };
}
