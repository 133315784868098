export default function useNumberPhone() {
  function getMaskPhone(numberPhone: string): string {
    if (numberPhone?.length) {
      return numberPhone[1] === '3'
        ? '+#75 (##) ### - ## - ##'
        : '+7 (###) ### - ## - ##';
    }
    return '+# (###) ### - ## - ##';
  }

  function getValidPhone(numberPhone: string): string {
    return numberPhone.replace(/[^+\d]/g, '');
  }

  return { getMaskPhone, getValidPhone };
}
