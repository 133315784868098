import { MethodsAlias } from '@/dialogs/MeteoStationDialog/types/PollMethod';

const methods = [
  {
    alias: MethodsAlias.poll,
    text: 'Серверный опрос',
  },
  {
    alias: MethodsAlias.api,
    text: 'API',
  },
  {
    alias: MethodsAlias.mqtt,
    text: 'MQTT',
  },
];
export default methods;
