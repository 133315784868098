import Dialog from 'quasar/src/plugins/Dialog.js';;

export default function useAlertDialog(title: string, labelOk = 'Да', labelCancel = 'Нет') {
  return Dialog.create({
    title,
    ok: {
      label: labelOk,
    },
    cancel: {
      label: labelCancel,
      color: 'negative',
    },
    persistent: true,
  });
}
