import Qs from 'qs';
import httpService from '@/api/configs/http.service';

class ImagesApiService {
    ENDPOINT = 'images';

    delete(ids: number[]) {
      return httpService.delete(`${this.ENDPOINT}/?${Qs.stringify({ ids }, { arrayFormat: 'brackets' })}`);
    }
}

const imagesApiService = new ImagesApiService();

export default imagesApiService;
