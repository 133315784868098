export enum MachineryType {
    automotive= 'automotive',
    trailer='trailer'
}

export const MachineryTypeLabel = {
  [MachineryType.automotive]: 'Cамоходный',
  [MachineryType.trailer]: 'Прицеп',
};

type Machinery = {
    id?: number;
    title: string;
    type: MachineryType;
    srp?: string;
};

export default Machinery;
