import { Style, Stroke, Fill } from 'ol/style';
import ElementType from '@/modules/map/contracts/ElemetType';
import BasePolygonFeature from '@/modules/map/features/BasePolygonFeature';
import { District } from '@/models/District';

export default class DistrictFeature extends BasePolygonFeature<District> {
  type = ElementType.District;

  constructor(props: District) {
    super();
    this.setProps(props);
    this.setDefaultStyle();
    this.setGeometryFeature(props.geometry);
  }

  private getDefaultStyle() {
    return new Style({
      stroke: new Stroke({
        color: '#AAAAAA',
        width: 1.5,
      }),
      fill: new Fill({
        color: '#AAAAAA40',
      }),
    });
  }

  setDefaultStyle() {
    return this.setStyle([
      this.getDefaultStyle(),
    ]);
  }

  setSelectStyle() {
    return [];
  }
}
