import Projection from 'ol/proj/Projection';
import Units from 'ol/proj/Units';
import ImageLayer from 'ol/layer/Image';
import { Extent } from 'ol/extent';
import Static from 'ol/source/ImageStatic';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { layerUrl } from '@/helpers/Satellite';
import FieldFeature from '@/modules/map/features/FieldFeature';
import { SettingsSatellites } from '@/models/SatelliteType';

type ColorBlend = {
  index: number,
  step: number,
};

type DataBarGraph = {
  index: number,
  length: number,
  activeColor: number[],
  color: number[],
  colors: string[]
};
const COLOR_BLEND = [
  { color: [0, 0, 0], index: -0.2, step: 0 }, // -0.2 black
  { color: [237, 237, 237], index: 0, step: 0 }, // 0 grey
  { color: [237, 237, 237], index: 0.18, step: 0 }, // 0.18 grey
  { color: [230, 56, 54], index: 0.2, step: 0.02 / (7 * 181 * 183) }, // 0.2 red
  { color: [250, 140, 0], index: 0.28, step: 0.08 / (20 * 84 * 54) }, // 0.28 orange
  { color: [252, 217, 54], index: 0.36, step: 0.08 / (2 * 77 * 54) }, // 0.36 yellow
  { color: [125, 179, 66], index: 0.44, step: 0.08 / (127 * 38 * 12) }, // 0.44 light green
  { color: [84, 140, 46], index: 0.52, step: 0.08 / (41 * 39 * 20) }, // 0.52
  { color: [46, 125, 51], index: 0.6, step: 0.08 / 2850 }, // 0.6 green
  { color: [28, 94, 33], index: 1, step: 0.4 / 10044 }, // 1 green darken
];

export default class SatelliteImage {
  imageLayer: ImageLayer<any>;

  private projection: Projection;

  private extent: Extent;

  formatColorBland = COLOR_BLEND
    .map((item) => ({ ...item, colorStr: this.getStringColor(item.color) })).reverse();

  defaultCanvasImg = document.createElement('canvas');

  contextImg = this.defaultCanvasImg.getContext('2d');

  defaultActiveColors: {[key: string]: number[]} = {};

  activeColors: {[key: string]: number[]} = {};

  protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public loading: Observable<boolean> = this.loadingSubject.asObservable();

  protected dataSubject: Subject<DataBarGraph[]> = new Subject<DataBarGraph[]>();

  public data: Observable<DataBarGraph[]> = this.dataSubject.asObservable();

  // {[key: index]: object colors}
  dataBarGraph: DataBarGraph[] = [];

  pixelArea: number;

  leftPosition = 0;

  rightPosition = 1;

  redArea = 0;

  greenArea = 0;

  orangeArea = 0;

  isInit = false;

  get isChangePosition() {
    return this.leftPosition > 0 || this.rightPosition < 1;
  }

  constructor(public feature: FieldFeature, public settingsData: SettingsSatellites) {
    this.setSettingsData(settingsData);

    this.extent = feature.getGeometry().getExtent();

    this.projection = new Projection({
      code: 'EPSG:3857',
      units: Units.PIXELS,
      extent: this.extent,
    });

    const source = new Static({
      url: layerUrl(settingsData, feature.props.id),
      projection: this.projection,
      imageExtent: this.extent,
    });

    this.imageLayer = new ImageLayer({
      extent: this.extent,
      source,
      zIndex: 3,
    });
  }

  setSettingsData(settingsData: SettingsSatellites) {
    this.settingsData = settingsData;
    this.initImg(layerUrl(settingsData, this.feature.props.id));
  }

  initImg(url) {
    this.loadingSubject.next(true);
    this.clearCanvas();
    const image = new Image();
    image.onload = () => {
      this.defaultCanvasImg.width = image.width;
      this.defaultCanvasImg.height = image.height;

      this.contextImg.drawImage(image, 0, 0);

      const colorToIndex: {[key: string]: number} = {};

      const colorToColorBland: {[key: string]: ColorBlend} = {};

      const colorsBland = Object.fromEntries(this.formatColorBland.map((item) => {
        const dataColorBland: ColorBlend = {
          index: item.index,
          step: item.step,
        };

        colorToColorBland[item.colorStr] = dataColorBland;

        return [
          item.colorStr,
          dataColorBland,
        ];
      }));

      const pixImg = this.contextImg.getImageData(0, 0, image.width, image.height).data;

      let lengthActivePixels = 0;

      for (let i = 0, n = pixImg.length; i < n; i += 4) {
        if (pixImg[i + 3] === 0) {
          continue;
        }
        lengthActivePixels += 1;

        const rgb = [pixImg[i], pixImg[i + 1], pixImg[i + 2]];

        const rgbStr = this.getStringColor(rgb);

        if (rgbStr in colorToIndex) {
          const index = colorToIndex[rgbStr];
          this.dataBarGraph[index].length += 1;
          continue;
        }

        const findItemColor = this.formatColorBland.find(({ color: rgbColor }, index, arr) => {
          const nextItemColor = arr[index + 1]?.color;
          return nextItemColor
            && this.isRange(nextItemColor[0], rgbColor[0], rgb[0], true)
            && this.isRange(nextItemColor[1], rgbColor[1], rgb[1], true)
            && this.isRange(nextItemColor[2], rgbColor[2], rgb[2], false);
        });

        if (!findItemColor) {
          continue;
        }
        const {
          color: findColor,
          index: findIndex,
          step: findStep,
          colorStr: findColorStr,
        } = findItemColor;

        colorToColorBland[rgbStr] = colorsBland[findColorStr];
        this.defaultActiveColors[rgbStr] = rgb;

        const index = Math.round((findIndex - (Math.abs(rgb[0] - findColor[0])
          * Math.abs(rgb[1] - findColor[1])
          * Math.abs(rgb[2] - findColor[2])
          * findStep)) * 100);

        colorToIndex[rgbStr] = index;

        if (index in this.dataBarGraph) {
          this.dataBarGraph[index].colors.push(rgbStr);
          this.dataBarGraph[index].length += 1;
        } else {
          this.dataBarGraph[index] = {
            length: 1,
            color: rgb,
            index: index / 100,
            activeColor: null,
            colors: [rgbStr],
          };
        }
      }
      this.pixelArea = this.feature.props.area / lengthActivePixels;
      this.activeColors = { ...this.defaultActiveColors };

      this.createAndSetSource();
      this.loadingSubject.next(false);
      this.isInit = true;
    };
    image.src = url;
  }

  createAndSetSource() {
    const canvasPrint = document.createElement('canvas');
    canvasPrint.width = this.defaultCanvasImg.width;
    canvasPrint.height = this.defaultCanvasImg.height;

    const ctxPrint = canvasPrint.getContext('2d');

    ctxPrint.drawImage(this.defaultCanvasImg, 0, 0);
    this.updateColor(ctxPrint);

    const source = new Static({
      url: canvasPrint.toDataURL('image/png'),
      projection: this.projection,
      imageExtent: this.extent,
    });

    this.imageLayer.setSource(source);
    this.dataSubject.next(this.dataBarGraph);
  }

  updateColor(ctx: CanvasRenderingContext2D) {
    const imageData = ctx.getImageData(
      0,
      0,
      this.defaultCanvasImg.width,
      this.defaultCanvasImg.height,
    );
    const { data } = imageData;
    for (let i = 0, n = data.length; i < n; i += 4) {
      if (data[i + 3] === 0) {
        continue;
      }

      const rgb = [data[i], data[i + 1], data[i + 2]];

      const rgbStr = this.getStringColor(rgb);

      const [r, g, b] = this.activeColors[rgbStr];
      imageData.data[i] = r;
      imageData.data[i + 1] = g;
      imageData.data[i + 2] = b;
    }
    ctx.putImageData(imageData, 0, 0);
  }

  setPositions({ min: left, max: right }: {min: number, max: number}) {
    this.loadingSubject.next(true);
    const settingsLeft = this.leftPosition < left ? {
      start: this.leftPosition * 100,
      end: left * 100,
      color: [255, 0, 0],
    } : {
      start: left * 100,
      end: this.leftPosition * 100,
      color: null,
    };
    this.leftPosition = left;
    this.updateDataBarGraphColor(settingsLeft.start, settingsLeft.end, settingsLeft.color);

    const settingsRight = this.rightPosition > right ? {
      start: right * 100,
      end: this.rightPosition * 100,
      color: [0, 125, 0],
    } : {
      start: this.rightPosition * 100,
      end: right * 100,
      color: null,
    };
    this.rightPosition = right;
    this.updateDataBarGraphColor(settingsRight.start, settingsRight.end, settingsRight.color);
    this.updateActiveColor();
    this.createAndSetSource();
    this.loadingSubject.next(false);
  }

  updateDataBarGraphColor(start: number, end: number, color: number[]) {
    for (let i = start, n = end; i < n; i += 1) {
      if (i in this.dataBarGraph) {
        this.dataBarGraph[i].activeColor = color;
      }
    }
  }

  private updateActiveColor() {
    this.activeColors = { ...this.defaultActiveColors };
    this.redArea = 0;
    this.greenArea = 0;
    Object.values(this.dataBarGraph).forEach(({ activeColor, colors, length }) => {
      if (activeColor) {
        if (activeColor[0] === 0) { // green
          this.greenArea += length;
        } else {
          this.redArea += length; // red
        }
      } else if (this.isChangePosition) {
        this.orangeArea += length;
      }

      const getActiveColor = this.isChangePosition ? () => [255, 165, 0]
        : (color: string) => this.activeColors[color];

      colors.forEach((color) => {
        this.activeColors[color] = activeColor || getActiveColor(color);
      });
    });
    this.redArea = Math.round(this.redArea * this.pixelArea * 100) / 100;
    this.greenArea = Math.round(this.greenArea * this.pixelArea * 100) / 100;
    this.orangeArea = Math.round(this.orangeArea * this.pixelArea * 100) / 100;
  }

  private clearCanvas() {
    const { width: oldWidth, height: oldHeight } = this.defaultCanvasImg;
    this.contextImg.clearRect(0, 0, oldWidth, oldHeight);
  }

  getStringColor(rgb: number[]): string {
    return rgb.join(',');
  }

  isRange(start: number, end: number, item: number, equally = false) {
    if (start > end) {
      return (equally ? start >= item : start > item) && item >= end;
    }
    return (equally ? start <= item : start <= item) && item <= end;
  }
}
