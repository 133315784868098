import CrudService from '@/api/configs/crud.service';
import { Operation } from '@/modules/field/types/Operation';

class OperationApiService extends CrudService<Operation, Operation> {
  protected ENDPOINT = 'operations';

  protected normalizeData(data: Operation) {
    return data;
  }
}

const operationApiService = new OperationApiService();

export default operationApiService;
