import { OrganizationTypeAlias } from '@/dialogs/OrganizationDialog/types/OrganizationType';

const organizationTypes = [
  {
    alias: OrganizationTypeAlias.Household,
    text: 'Хозяйство',
  },
  {
    alias: OrganizationTypeAlias.District,
    text: 'Администрация района',
  },
  {
    alias: OrganizationTypeAlias.Region,
    text: 'Администрация региона',
  },
];
export default organizationTypes;
