enum AssayType {
    Gost = 'Gost',
    Bionic = 'Bionic',
}
export default AssayType;

export const assayLabel = {
  [AssayType.Gost]: 'ГОСТ',
  [AssayType.Bionic]: 'Бионическое',
};
