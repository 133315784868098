import { Coordinate } from 'ol/coordinate';
import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
import AnalysesData, { AnalysesType } from '@/dialogs/AssayDialog/types/AnalysesData';
import { getSecurityDataByIndicator } from '@/dialogs/FieldManageDialog/helpers/helpers';

export default function useCanvasHelpers() {
  const getCoordinateCanvas = (
    [x1, y1]: Coordinate,
    [x, y]: Coordinate,
    zoom = 1,
  ): Coordinate => [Math.abs(Math.round((x - x1) / zoom)), Math.abs(Math.round((y - y1) / zoom))];

  const getFormatPositionsPolygons = (polygon, leftTopPoint: number[]) => {
    const coordinatePolygon = polygon.getType() === 'MultiPolygon'
      ? polygon.getCoordinates() : [polygon.getCoordinates()];
    // @ts-ignore
    return coordinatePolygon[0]
      .map<Coordinate[]>((polygonItem: any[]) => polygonItem
        .map<Coordinate>((coordinate) => getCoordinateCanvas(leftTopPoint, coordinate)));
  };

  const drawPoint = (ctxPrint: CanvasRenderingContext2D, point: Coordinate) => {
    const [xPoint, yPoint] = point;
    ctxPrint.beginPath();
    ctxPrint.arc(xPoint, yPoint, 8, 0, 2 * Math.PI, true);
    ctxPrint.fill();
    ctxPrint.stroke();
    ctxPrint.closePath();
  };

  const drawPolygon = (
    ctxPrint: CanvasRenderingContext2D,
    positionsPolygons: Coordinate[][],
    color: string,
  ) => {
    positionsPolygons.forEach((polygon) => {
      ctxPrint.beginPath();
      ctxPrint.moveTo(polygon[0][0], polygon[0][1]);
      polygon.forEach(([x, y]) => ctxPrint.lineTo(x, y));
      // eslint-disable-next-line no-param-reassign
      ctxPrint.fillStyle = color;
      // eslint-disable-next-line no-param-reassign
      ctxPrint.strokeStyle = '#3399CC';
      ctxPrint.fill();
      ctxPrint.stroke();
      ctxPrint.closePath();
    });
  };

  const drawNames = (
    ctxPrint: CanvasRenderingContext2D,
    dataAreasImage,
    titleCenterPoint: 'centerPoint' | 'point',
    titleStatusIndicator: string[],
    indicator: string,
  ) => {
    /* eslint-disable no-param-reassign */
    ctxPrint.beginPath();
    ctxPrint.font = '20px Arial';
    ctxPrint.textAlign = 'center';
    ctxPrint.fillStyle = 'black';

    dataAreasImage.forEach((item) => {
      const {
        num, area, analyses,
      } = item;
      const [x, y] = item[titleCenterPoint];
      ctxPrint.font = '18px Arial';
      ctxPrint.fillText(num, x, y - 20);
      ctxPrint.font = '12px Arial';
      ctxPrint.fillText(`${area}Га`, x, y + 20);
      const analyse = analyses && analyses[indicator];
      if (analyse) {
        ctxPrint.fillText(`${titleStatusIndicator[num]}`, x, y + 35);
      }
    });

    ctxPrint.closePath();
    /* eslint-enable */
  };

  const createCanvasElement = (width: number, height: number) => {
    const canvasPrint = document.createElement('canvas');
    canvasPrint.width = width;
    canvasPrint.height = height;

    const ctxPrint = canvasPrint.getContext('2d');

    return { canvasPrint, ctxPrint };
  };

  const getColorAndTitleIndicator = (
    indicator: IndicatorType,
    analyses: AnalysesData,
    analysesTypes: AnalysesType,
  ) => {
    let colorElementaryArea = '#FFFFFF66';
    let title = '';
    if (analyses != null
      && indicator in analyses
      && analyses[indicator] != null) {
      const dataIndicator = analyses[indicator];
      const {
        color: newColor,
        title: newTitle,
      } = getSecurityDataByIndicator(indicator, analysesTypes, dataIndicator);

      colorElementaryArea = newColor;
      title = newTitle;
    }
    return { color: colorElementaryArea, title };
  };

  return {
    getCoordinateCanvas,
    getFormatPositionsPolygons,
    drawPoint,
    drawPolygon,
    createCanvasElement,
    drawNames,
    getColorAndTitleIndicator,
  };
}
