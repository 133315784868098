import { serialize } from 'object-to-formdata';
import { ScoutingReport } from '@/models/ScoutingReport';
import FormCrudService from '@/api/configs/form-crud.service';

class ReportScoutingApiService extends FormCrudService<ScoutingReport> {
  protected ENDPOINT = 'scouting_reports';

  protected normalizeData(data: ScoutingReport) {
    const options = {
      indices: true,
    };

    return serialize(
      data,
      options,
    );
  }
}

const reportScoutingApiService = new ReportScoutingApiService();

export default reportScoutingApiService;
