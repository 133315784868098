import transformTranslate from '@turf/transform-translate';
import intersect from '@turf/intersect';
import { Feature } from 'ol';
import { formatGeoJSONWriteFeature, getLineByPolygon } from '@/helpers/map';
import { Point } from 'ol/geom';
import booleanIntersects from '@turf/boolean-intersects';
import { Feature as FeatureTurf } from '@turf/helpers';
import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';

export default function useDrawAutoHelpers() {
  const {
    getIsRemove,
    getDataFeature,
    getGeoJsonFeature,
    getPolygonByPoint,
    getPointByPolygon,
  } = useAdditionalFunctionsFeature();

  const reducePolygon = (polygon: FeatureTurf) => {
    const fieldTransform1 = transformTranslate(
      polygon,
      0.001,
      45,
    );

    const fieldTransform2 = transformTranslate(
      polygon,
      -0.001,
      45,
    );
    // @ts-ignore
    const transform1 = intersect(polygon, fieldTransform1);
    // @ts-ignore
    const transform2 = intersect(transform1 || polygon, fieldTransform2);

    return transform2 || polygon;
  };

  const getEdgePolygons = (map: Map, polygons: Feature[]) => {
    const fieldGeoJson = formatGeoJSONWriteFeature(map.source.getFeatureById('field'));

    const line = getLineByPolygon(reducePolygon(fieldGeoJson));
    // @ts-ignore
    return polygons
      .filter((item) => !getIsRemove(getPointByPolygon(item) as Feature<Point>)
        // @ts-ignore
        && booleanIntersects(getGeoJsonFeature(item), line));
  };

  const isActivePolygon = (map: Map, activeElementaryPolygon: Feature) => getDataFeature(
    activeElementaryPolygon,
  ).index == null
    && map.pointSource
      .getFeatures().some((pointFeature) => getDataFeature(pointFeature).index == null
        && !getIsRemove(pointFeature)
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        && getPolygonByPoint(pointFeature as Feature<Point>).ol_id
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        === activeElementaryPolygon.ol_id);

  return { reducePolygon, getEdgePolygons, isActivePolygon };
}
