import FieldFeature from '@/modules/map/features/FieldFeature';
import { Field } from '@/modules/field/types/Field';
import fieldApiService from '@/api/fieldApiService';
import useManagerMap from '@/modules/map/hooks/useManagerMap';
import { useFieldStore, useMapStore } from '@/stores';

export default function useFieldMap() {
  const fieldStore = useFieldStore();
  const mapStore = useMapStore();
  const { source, addSourceFeature } = useManagerMap();

  const updateFieldData = (field: Field) => {
    const { fields } = mapStore;
    const fieldFeature = fields.find((item) => item.props.id === field.id);

    fieldFeature.setProps(field);
    fieldFeature.setDefaultStyle();
    fieldFeature.setGeometryFeature(field.geometry);

    if (fieldStore.activeField.id === field.id) {
      fieldFeature.setSelectStyle();
      fieldStore.setActiveField(field);
    }
  };

  const loadAndUpdateField = (id: number) => {
    fieldApiService.getByID(id)
      .subscribe((field) => {
        updateFieldData(field);
      });
  };

  const addField = (field: FieldFeature) => {
    const { fields } = mapStore;
    addSourceFeature(field);
    mapStore.setFields([...fields, field] as FieldFeature[]);
  };

  const removeField = (idField: number) => {
    const { fields } = mapStore;
    const fieldFeature = fields.find((item) => item.props.id === idField);
    // @ts-ignore
    source.removeFeature(fieldFeature);
    mapStore.setFields(fields.filter(({ props: { id } }) => id !== idField) as FieldFeature[]);
  };

  return {
    loadAndUpdateField, updateFieldData, addField, removeField,
  };
}
