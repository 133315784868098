import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import { MeteoHealth } from '@/modules/station-operability/types/MeteoHealth';

class MeteoHealthApiService extends BaseCrud<MeteoHealth> {
  protected ENDPOINT = 'meteo_nodes/health';

  get(options?: AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT}`, options);
  }
}
const meteoHealthApiService = new MeteoHealthApiService();

export default meteoHealthApiService;
