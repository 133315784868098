import { Point as PointGeom } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import {
  Style, Stroke, Fill, Circle as CircleStyle,
} from 'ol/style';
import { Point } from '@turf/helpers';
import ElementType from '@/modules/map/contracts/ElemetType';
import FeatureRoot from '@/modules/map/features/FeatureRoot';
import { MeteoMap } from '@/models/Meteo';

export default class MeteoFeature extends FeatureRoot<MeteoMap, PointGeom> {
  type = ElementType.Meteo;

  constructor(props) {
    super();
    this.setProps(props);
    this.setDefaultStyle();
    this.setGeometryFeature(props.location);
  }

  setGeometryFeature(geometry: Point) {
    super.setGeometry(new PointGeom(fromLonLat(geometry.coordinates)));
  }

  setDefaultStyle() {
    this.setStyle([
      new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: '#42A5F5',
            width: 1.5,
          }),
          fill: new Fill({
            color: '#42A5F540',
          }),
          radius: 10,
        }),
      }),
    ]);
  }

  setSelectStyle() {
    this.setStyle([
      new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: '#42A5F5',
            width: 4,
          }),
          fill: new Fill({
            color: '#42A5F540',
          }),
          radius: 10,
        }),
      }),
    ]);
  }
}
