import { AxiosRequestConfig } from 'axios';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Crop } from '@/dialogs/CycleFieldDialog/types/Crop';
import BaseCrud from '@/api/configs/base-crud';

class VarietiesApiService extends BaseCrud<Crop> {
  ENDPOINT = 'varieties';

  private varieties: Crop[];

  get(options?: AxiosRequestConfig) {
    if (this.varieties) {
      return of(this.varieties);
    }
    return super.getData(this.ENDPOINT, options)
      .pipe(
        tap((data) => {
          this.varieties = data;
        }),
      );
  }
}

const varietiesApiService = new VarietiesApiService();

export default varietiesApiService;
