import Projection from 'ol/proj/Projection';
import Units from 'ol/proj/Units';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import LayerGroup from 'ol/layer/Group';
import { useRoute } from 'vue-router';
import { toRefs } from 'vue';
import FieldFeature from '@/modules/map/features/FieldFeature';

import useManagerMap from '@/modules/map/hooks/useManagerMap';
import { layerUrl } from '@/helpers/Satellite';
import { SatelliteLayer, SatelliteType, SettingsSatellites } from '@/models/SatelliteType';
import SatelliteImage from '@/modules/field/data/SatelliteImage';
import { useMapStore } from '@/stores';

export default function useSatelliteMap() {
  const { map } = useManagerMap();
  const mapStore = useMapStore();
  const $route = useRoute();

  const {
    settingsSatellites, satelliteImage, satelliteLayer, itemsTimeList,
  } = toRefs(mapStore);

  const removeSatelliteLayer = () => {
    if (satelliteLayer.value) {
      map.removeLayer(satelliteLayer.value as ImageLayer<any> | LayerGroup);
      mapStore.removeSatelliteLayer();
    }
  };

  const getImageLayer = (feature: FieldFeature, settingsData: SettingsSatellites) => {
    const extent = feature.getGeometry().getExtent();

    const projection = new Projection({
      code: 'EPSG:3857',
      units: Units.PIXELS,
      extent,
    });

    const source = new Static({
      url: layerUrl(settingsData, feature.props.id),
      projection,
      imageExtent: extent,
    });

    return new ImageLayer({
      extent, source, zIndex: 3,
    });
  };

  const setLayer = (settingsData: SettingsSatellites) => {
    if (!settingsData.imageDt) return;

    const { fields } = mapStore;

    mapStore.setSettingsSatellite(settingsData);
    removeSatelliteLayer();

    if (!settingsData.layerType) {
      return;
    }

    if (settingsData.applyToAll) {
      const layers = fields
        .filter((itemField) => itemField?.props?.props?.hasSat)
        .map((featureField) => getImageLayer(featureField as FieldFeature, settingsData));

      satelliteLayer.value = new LayerGroup({
        layers,
      });
    } else {
      const fieldId = Number($route.params.id);

      const featureField = fields.find((item) => item.props.id === fieldId);

      const { layerType, presentationType } = settingsData;

      if (layerType === SatelliteLayer.NDVI && !presentationType) {
        const newSatelliteImage = new SatelliteImage(featureField as FieldFeature, settingsData);

        mapStore.setSatelliteImage(newSatelliteImage);
        mapStore.setSatelliteLayer(newSatelliteImage.imageLayer);
      } else {
        mapStore.setSatelliteLayer(getImageLayer(featureField as FieldFeature, settingsData));
      }
    }
    map.addLayer(satelliteLayer.value as ImageLayer<any> | LayerGroup);
  };

  const setItemsTimeList = (value: SatelliteType[]) => {
    mapStore.setItemsTimeList(value);
  };

  const setSettingsSatellite = (value: SettingsSatellites) => {
    mapStore.setSettingsSatellite(value);
  };

  const setChangeSettingsTimeList = (value: (form: SettingsSatellites) => void) => {
    mapStore.setChangeSettingsTimeList(value);
  };

  return {
    setLayer,
    getImageLayer,
    settingsSatellites,
    itemsTimeList,
    removeSatelliteLayer,
    satelliteLayer,
    satelliteImage,
    setItemsTimeList,
    setSettingsSatellite,
    setChangeSettingsTimeList,
  };
}
