export enum SatelliteLayer {
  TRUE_COLOR = 'TRUE_COLOR',
  COLOR_INFRARED = 'COLOR_INFRARED',
  NDVI = 'NDVI',
  EVI = 'EVI',
  NDWI = 'NDWI',
  LAI = 'LAI',
  NDRE = 'NDRE',
  GNDVI = 'GNDVI',
  ARVI = 'ARVI',
  REPO = 'REPO',
  RVI = 'RVI',
}

export enum SatellitePresentation {
  DISP = 'DISP',
  DYN = 'DYN',
}

export type SettingsSatellites = {
  applyToAll?: boolean,
  cloudiness?: number,
  imageDt: string,
  presentationType?: string,
  layerType: SatelliteLayer,
  fieldId?: number,
};
export type SatelliteType = {
  id: number
  cloudiness: number,
  imageDt: string,
  fieldId: number,
};
