import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';

class CropsChartApiService extends BaseCrud {
  protected ENDPOINT = '{type}/sow_structure';

  private getCropsData(id: number, type?: string, options?: AxiosRequestConfig) {
    const newEndpoint = this.ENDPOINT.replace('{type}', type);
    return super.getData(`${newEndpoint}/${id}`, options);
  }

  getRegionsData(id: number, params) {
    return this.getCropsData(id, 'regions', { params });
  }

  getDistrictsData(id: number, params) {
    return this.getCropsData(id, 'districts', { params });
  }

  getOrganizationsData(id: number, params) {
    return this.getCropsData(id, 'organizations', { params });
  }
}

const cropsChartApiService = new CropsChartApiService();

export default cropsChartApiService;
