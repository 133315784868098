import moment from 'moment';
import { finalize } from 'rxjs/operators';
import {
  computed, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { FORMATS_OF_DATA } from '@/helpers/Constants';
import satelliteApiService from '@/api/satelliteApiService';
import useSatelliteMap from '@/hooks/useSatelliteMap';
import { SatelliteLayer, SettingsSatellites } from '@/models/SatelliteType';
import { hasPresentation as hasPresentationGlobal } from '@/helpers/Satellite';
import routeNames from '@/router/routeNames';

export default function useSatelliteSettings() {
  const $route = useRoute();
  const {
    setLayer: setMapLayer, satelliteImage: satelliteMapImage,
    settingsSatellites: form, itemsTimeList: satelliteData, setItemsTimeList,
    setSettingsSatellite, setChangeSettingsTimeList,
  } = useSatelliteMap();

  const isActiveTimeList = ref(false);

  const loading = ref(false);

  const satelliteImage = ref(null);

  watch(form, (newSettingsSatellite) => {
    if (newSettingsSatellite && newSettingsSatellite.fieldId === form.value.fieldId
        && newSettingsSatellite.applyToAll === form.value.applyToAll) {
      form.value = newSettingsSatellite;
    }
  });

  const satelliteDateFormat = computed(() => satelliteData.value?.map(
    (el) => moment(el.imageDt).format('YYYY/MM/DD'),
  ) || []);

  // eslint-disable-next-line no-nested-ternary
  const fieldId = computed(() => ($route.name
  === routeNames.fieldsId
    && $route.params?.id
    ? Number($route.params.id)
    : undefined
  ));

  const selectedData = computed(() => {
    const { imageDt } = form.value;
    return imageDt
      ? satelliteData.value?.find((el) => moment(el.imageDt).format(FORMATS_OF_DATA.date)
        === imageDt)
      : null;
  });

  const hasPresentation = computed(() => hasPresentationGlobal(form.value.layerType));

  const updateSatelliteData = () => {
    loading.value = true;
    satelliteApiService.get({
      params: {
        cloudiness: form.value.cloudiness,
        field: form.value.applyToAll ? undefined : fieldId.value,
      },
    })
      .pipe(
        finalize(() => {
          loading.value = false;
        }),
      )
      .subscribe((data) => {
        if (isActiveTimeList.value) {
          setItemsTimeList(satelliteData.value);
        }
        satelliteData.value = data;
      });
  };

  const updateSatelliteImage = (form:SettingsSatellites) => {
    if (!form.applyToAll && form.layerType === SatelliteLayer.NDVI
      && !form.presentationType && form.imageDt) {
      satelliteImage.value = satelliteMapImage.value;
    } else {
      satelliteImage.value = null;
    }
  };

  const setLayer = (newForm: SettingsSatellites) => {
    isActiveTimeList.value = true;
    setMapLayer(newForm);
    updateSatelliteImage(newForm);
    setChangeSettingsTimeList(setLayer);
    setItemsTimeList(satelliteData.value);
    setSettingsSatellite({ ...newForm });
  };

  const allowedDates = (val: string): boolean => satelliteDateFormat.value?.includes(val);

  return {
    allowedDates,
    setLayer,
    updateSatelliteImage,
    updateSatelliteData,
    satelliteImage: satelliteMapImage,
    selectedData,
    satelliteDateFormat,
    form,
    loading,
    fieldId,
    hasPresentation,
    satelliteData,
  };
}
