import { kTypes, nTypes, pTypes } from '@/dialogs/AssayDialog/constants/optionsTypes';
import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';

export enum Indicator {
  veryLow = 0,
  low = 1,
  medium = 2,
  high = 3,
  veryHigh= 4
}

export const titleIndicator = {
  [Indicator.veryLow]: 'Очень низкое',
  [Indicator.low]: 'Низкое',
  [Indicator.medium]: 'Среднее',
  [Indicator.high]: 'Повышенное',
  [Indicator.veryHigh]: 'Высокое',
};

// index color === Indicator
// index indicator item === Indicator

export const groupingOfSoilsAccordingToAvailability:
    {[key in IndicatorType]: {color: string[], indicator: any}} = {
      [IndicatorType.n]: {
        color: ['#ccffff', '#ccffcc', '#ccff99', '#ccff66', '#99cc00'],
        indicator: {
          [nTypes[0]]: [30, 35.5, 60.5, 85.5, 100],
          [nTypes[1]]: [120, 140, 160, 180, 200],
          [nTypes[2]]: [5, 6.5, 11.5, 22.5, 45],
        },
      },
      [IndicatorType.p]: {
        color: ['#ddebf7', '#bdd7ee', '#9bc2e6', '#2f75b5', '#1f4e78'],
        indicator: {
          [pTypes[0]]: [10, 23, 38, 53, 60],
          [pTypes[1]]: [25, 37.5, 75.5, 125, 250],
          [pTypes[2]]: [21, 35.5, 75.5, 125, 200],
          [pTypes[3]]: [13, 23, 38, 53, 60],
        },
      },
      [IndicatorType.k]: {
        color: ['#fce4d6', '#f8cbad', '#f4b084', '#ed7d31', '#c65911'],
        indicator: {
          [kTypes[0]]: [100, 150.0, 250.0, 350.0, 500],
          [kTypes[1]]: [40, 60.0, 100.0, 145.0, 250],
          [kTypes[2]]: [20, 30.0, 60.0, 100.0, 150],
          [kTypes[3]]: [50, 75.0, 125.0, 175.0, 250],
          [kTypes[4]]: [84, 98.0, 112.0, 126.0, 140],
        },
      },
      [IndicatorType.ca]: {
        color: ['#ffffcc', '#ffcc99', '#ff9966', '#ff6600', '#ff0000'],
        indicator: [25, 38.0, 75.0, 125.0, 175],
      },
      [IndicatorType.s]: {
        color: ['#ffffff', '#ffffcc', '#ffff99', '#ffff66', '#ffff00'],
        indicator: [7, 15, 25, 35, 45],
      },

      [IndicatorType.mg]: {
        color: ['#f2f2f2', '#e7e6e6', '#bfbfbf', '#a6a6a6', '#808080'],
        indicator: [5, 8.0, 15.0, 25.0, 35],
      },
      [IndicatorType.na]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [5, 8.0, 15.0, 25.0, 35],

      },
      [IndicatorType.cl]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [0.6, 0.8, 1.5, 2.5, 3.5],

      },
      [IndicatorType.fe]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [0.6, 0.8, 1.5, 2.5, 3.5],

      },
      [IndicatorType.b]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [0.1, 0.2, 0.4, 0.8, 1],

      },
      [IndicatorType.zn]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [0.7, 0.9, 1.1, 1.3, 1.5],

      },
      [IndicatorType.cu]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [1.3, 1.8, 2.3, 2.8, 3.3],

      },
      [IndicatorType.co]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [1.3, 1.8, 2.3, 2.8, 3.3],

      },
      [IndicatorType.mo]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [0.1, 0.15, 0.16, 0.19, 0.22],

      },
      [IndicatorType.mn]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#e7f593', '#92d050'],
        indicator: [30.0, 40.0, 50.0, 60.0, 70.0],

      },
      [IndicatorType.ph]: {
        color: ['#ff0000', '#ffc000', '#ffff00', '#8ea9db', '#7030a0'],
        indicator: [4.5, 6.0, 7.0, 8.0, 9],
      },
      [IndicatorType.humus]: {
        color: ['#fff2cc', '#ffd966', '#f4b084', '#c65911', '#833c0c'],
        indicator: [2, 3.0, 5.0, 8.0, 10],
      },
      [IndicatorType.salinization]: {
        color: ['#92d050', '#c6e0b4', '#b4c6e7', '#ffc000', '#ff0000'],
        indicator: [50.0, 70.0, 300.0, 500.0, 550.0],
      },
      [IndicatorType.soilCompaction]: {
        color: ['#ffffcc', '#ffcc99', '#ff9966', '#ff6600', '#ff0000'],
        indicator: [1, 1.1, 1.2, 1.4, 1.6],
      },
    };
