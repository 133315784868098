import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import { onMounted, onUnmounted, ref } from 'vue';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

export default function useHoverElementaryArea(map: Map) {
  const {
    getIsRemove, getPointByPolygon,
  } = useAdditionalFunctionsFeature();

  const hoverSource = new VectorSource({
    wrapX: false,
  });

  const hoverVector = new VectorLayer({
    source: hoverSource,
    zIndex: 2,
  });

  let selectedPolygonItem: Feature = null;

  const selectionElements = ref<Feature[]>(null);

  const clear = () => {
    hoverSource.clear();
    selectedPolygonItem = null;
  };

  const getStatusSelectionFeature = (feature: Feature) => (selectionElements.value
    ? selectionElements.value.includes(feature) : true);

  const checkSelectionFeature = (feature: Feature) => {
    if (feature && getStatusSelectionFeature(feature)) {
      selectedPolygonItem = feature;
      hoverSource.addFeature(selectedPolygonItem);
    }
  };

  const setSelectionFeatures = (features: Feature[]) => {
    clear();
    selectionElements.value = features;
    checkSelectionFeature(selectedPolygonItem);
  };

  const selectedPolygon = (e) => {
    const newLocation = map.map.getCoordinateFromPixel(e.pixel);

    if (selectedPolygonItem) {
      if (!selectedPolygonItem.getGeometry().containsXY(newLocation[0], newLocation[1])) {
        clear();
      } else {
        return;
      }
    }

    const newPolygonFeatures = map.staticSource.getFeaturesAtCoordinate(newLocation);
    if (newPolygonFeatures.length && !getIsRemove(getPointByPolygon(newPolygonFeatures[0]))) {
      selectedPolygonItem = newPolygonFeatures[0] as Feature;
      checkSelectionFeature(selectedPolygonItem);
    }
  };

  const onHoverPolygon = () => {
    map.map.on('pointermove', selectedPolygon);
  };

  const offHoverPolygon = () => {
    map.map.un('pointermove', selectedPolygon);
    if (selectedPolygonItem) {
      clear();
    }
    selectionElements.value = null;
  };

  onMounted(() => {
    map.addLayer(hoverVector);
  });

  onUnmounted(() => {
    map.map.un('pointermove', selectedPolygon);
    map.removeLayer(hoverVector);
  });

  return { onHoverPolygon, offHoverPolygon, setSelectionFeatures };
}
