import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { ability, defineAbilityFor } from '@/services/ability';
// eslint-disable-next-line import/no-cycle
import { useUserStore } from '@/stores';

const restoreUserData: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore();

  document.title = `КлеверFarmer. ${to.meta.title}`;
  if (to.meta.auth) {
    let { isAuthorized } = userStore;
    if (!isAuthorized) {
      userStore.fetchUser()
        .then(() => {
          isAuthorized = userStore.isAuthorized;
          if (!isAuthorized) {
            next('/login');
          } else {
            const { user } = userStore;
            ability.update(defineAbilityFor(user).rules);
            if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
              next('/403');
            }
            next();
          }
        })
        .catch(() => {
          next('/login');
        });
    } else {
      const { user } = userStore;
      ability.update(defineAbilityFor(user).rules);
      if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
        next('/403');
      }
      next();
    }
  } else {
    if (userStore.isUser === undefined) {
      userStore.fetchUser().then();
    }
    next();
  }
};

export default restoreUserData;
