import { Point as PointGeom } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import {
  Style, Icon,
} from 'ol/style';
import ElementType from '@/modules/map/contracts/ElemetType';
import FeatureRoot from '@/modules/map/features/FeatureRoot';
import barn from '@/assets/barn.png';
import { Organization } from '@/models/Organization';

export default class OrganizationFeature extends FeatureRoot<Organization, PointGeom> {
  type = ElementType.Organization;

  constructor(props) {
    super();
    this.setProps(props);
    this.setDefaultStyle();
    this.setGeometryFeature(props.location);
  }

  setGeometryFeature(geometry) {
    super.setGeometry(new PointGeom(fromLonLat(geometry)));
  }

  setDefaultStyle() {
    this.setStyle([
      new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          scale: 0.3,
          crossOrigin: 'anonymous',
          color: '#FFB74D',
          src: barn,
        }),
      }),
    ]);
  }

  setSelectStyle() {
    this.setStyle([
      new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          scale: 0.4,
          crossOrigin: 'anonymous',
          color: '#ffcb7c',
          src: barn,
        }),
      }),
    ]);
  }
}
