import { AxiosRequestConfig } from 'axios';
import { RegionDistrictByCoordinate } from '@/dialogs/FieldManageDialog/types/RegionDistrictByCoordinate';
import BaseCrud from '@/api/configs/base-crud';

class RegionCoordinateApiService extends BaseCrud<RegionDistrictByCoordinate> {
  ENDPOINT = 'regions';

  getByCoordinate(options?: AxiosRequestConfig) {
    return super.getById(`${this.ENDPOINT}/bycoord`, options);
  }
}

const regionCoordinateApiService = new RegionCoordinateApiService();

export default regionCoordinateApiService;
