import { Coordinate } from 'ol/coordinate';
import { LineString, Point } from 'ol/geom';
import { Feature } from 'ol';
import booleanIntersects from '@turf/boolean-intersects';
import { formatGeoJSONWriteFeature, getLineByPolygon } from '@/helpers/map';
import VectorSource from 'ol/source/Vector';
import length from '@turf/length';
import along from '@turf/along';
import { fromLonLat } from 'ol/proj';
import difference from '@turf/difference';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import Map from '@/modules/map/data/Map';
import { Draw } from 'ol/interaction';
import useErrors from '@/dialogs/AssayDialog/hooks/useErrors';
import { Ref, UnwrapRef } from 'vue';
import useDrawAutoHelpers from '@/dialogs/AssayDialog/hooks/useDrawAutoHelpers';
import { lineString } from '@turf/helpers';

export default function useDrawAutoBionic(
  map: Map,
  points: Ref<UnwrapRef<Feature<Point>[]>>,
  startNumberPolygon: Ref<UnwrapRef<number>>,
) {
  const {
    getPointByPolygon,
    getIsRemove,
    getDataFeature,
    getGeoJsonFeature,
  } = useAdditionalFunctionsFeature();

  const { errorSelectPolygonPoint } = useErrors();

  const { reducePolygon, getEdgePolygons, isActivePolygon } = useDrawAutoHelpers();

  const findPolygonByPoint = (coordinate: Coordinate) => {
    const polygons = map.staticSource.getFeaturesAtCoordinate(coordinate);
    if (polygons.length) {
      return polygons[0];
    }
    return null;
  };

  const getDirection = (
    firstElementaryAreaFeature: Feature,
    secondElementaryAreaFeature: Feature,
    startItemGroup: Feature[],
  ) => {
    const indexFirstElementaryArea = startItemGroup
      .findIndex((item) => item === firstElementaryAreaFeature);

    if (indexFirstElementaryArea !== -1) {
      const indexSecondElementaryAreaFeature = indexFirstElementaryArea + 1 in startItemGroup
        ? indexFirstElementaryArea + 1 : 0;

      return startItemGroup[indexSecondElementaryAreaFeature] === secondElementaryAreaFeature
        ? 1 : -1;
    }

    return 1;
  };

  const showActivePoints = (activeElementaryAreaFeature: Feature) => {
    const activePolygons = map.staticSource.getFeatures()
      .filter((elementaryAreaFeature) => !getIsRemove(getPointByPolygon(elementaryAreaFeature))
        && getDataFeature(elementaryAreaFeature).index == null
        && !!booleanIntersects(
          getGeoJsonFeature(elementaryAreaFeature),
          getGeoJsonFeature(activeElementaryAreaFeature),
        ));

    const activePoints = getEdgePolygons(map, activePolygons)
      .map((elementaryAreaFeature) => getPointByPolygon(elementaryAreaFeature));

    map.pointSource.clear();
    map.pointSource.addFeatures([...activePoints]);
  };

  const getGroupsElementaryAreaFeatures = (): Feature[][] => {
    const group = [];

    const fieldGeoJson = formatGeoJSONWriteFeature(map.source.getFeatureById('field'));

    let activeField = reducePolygon(fieldGeoJson);

    let polygons = map.staticSource.getFeatures();

    const mapSoursFind = new VectorSource({
      wrapX: false,
    });

    // @ts-ignore
    // eslint-disable-next-line no-unreachable-loop
    while (activeField && polygons.length) {
      let lineActiveField = getLineByPolygon(activeField);
      if (lineActiveField.type === 'FeatureCollection') {
        // eslint-disable-next-line prefer-destructuring
        lineActiveField = lineActiveField.features[0];
      }
      // TODO иногда приходят несколько линий надо исправить
      // eslint-disable-next-line no-loop-func
      const { intersects, notIntersects } = polygons.reduce((result, item) => {
        // @ts-ignore
        if (booleanIntersects(getGeoJsonFeature(item), lineActiveField)) {
          result.intersects.push(item);
        } else {
          result.notIntersects.push(item);
        }

        return result;
      }, { intersects: [], notIntersects: [] });

      mapSoursFind.addFeatures(intersects);

      lineActiveField = lineActiveField.geometry.type === 'MultiLineString'
        ? lineString(lineActiveField.geometry.coordinates[0])
        : lineActiveField;

      // -------------
      const lengthLine = length(lineActiveField);

      let distance = 0.001;
      const listPoints = [];
      while (lengthLine > distance) {
        // @ts-ignore
        const pointOnLine = along(lineActiveField, distance);
        listPoints.push(pointOnLine);
        distance += 0.001;
      }
      // ----------------

      // сортировка по порядку в зависимости от линии
      const sortItems = new Set();
      let activePolygon: Feature = null;
      listPoints.forEach((point) => {
        const coordinates = fromLonLat(point.geometry.coordinates);

        if (activePolygon && activePolygon.getGeometry().intersectsCoordinate(coordinates)) {
          return;
        }
        const newPolygons = mapSoursFind.getFeaturesAtCoordinate(coordinates);
        if (newPolygons.length === 1) {
          activePolygon = newPolygons[0] as Feature;
          if (!getIsRemove(getPointByPolygon(activePolygon))) {
            sortItems.add(activePolygon);
          }
        }
      });
      mapSoursFind.clear();
      //---------------

      activeField = intersects
        .reduce(
          (result, item) => (result ? difference(result, getGeoJsonFeature(item)) : result),
          activeField,
        );

      if (activeField) {
        // @ts-ignore
        activeField = reducePolygon(activeField);
      }

      polygons = notIntersects;
      group.push([...sortItems]);
    }

    return group;
  };

  const getListElementaryAreaFeaturesFromGroup = (
    firstElementaryAreaFeature: Feature,
    groupElementaryAreaFeatures: Feature[][],
  ) => {
    const listElementaryAreaFeatures = [];

    let startElementaryArea = firstElementaryAreaFeature;

    groupElementaryAreaFeatures.forEach((
      elementaryAreaFeatures,
      indexGroup,
      arrayGroup,
    ) => {
      const startIndex = elementaryAreaFeatures
        .findIndex((item) => item === startElementaryArea);

      listElementaryAreaFeatures
        .push(
          ...elementaryAreaFeatures.slice(startIndex),
          ...elementaryAreaFeatures.slice(0, startIndex),
        );

      if (indexGroup + 1 < arrayGroup.length) {
        const lastItemGeoJson = getGeoJsonFeature(
          listElementaryAreaFeatures[listElementaryAreaFeatures.length - 1] as Feature,
        );
        startElementaryArea = arrayGroup[indexGroup + 1]
          .find((item) => booleanIntersects(getGeoJsonFeature(item), lastItemGeoJson));
      }
    });
    return listElementaryAreaFeatures;
  };

  let firstElementaryAreaFeature = null;
  let secondElementaryAreaFeature = null;
  let listPolygonBionic = [];

  const drawAutoBionic = (coordinates: Coordinate[], geom: LineString) => {
    if (!geom) {
      listPolygonBionic = [];
      firstElementaryAreaFeature = findPolygonByPoint(coordinates[0] as number[]) as Feature;
      if (firstElementaryAreaFeature && isActivePolygon(map, firstElementaryAreaFeature)) {
        const startPoint = getPointByPolygon(firstElementaryAreaFeature) as Feature<Point>;

        const newCoordinate = startPoint.getGeometry().getCoordinates();

        const newLineString = new LineString([newCoordinate, coordinates[0]]);
        // eslint-disable-next-line no-param-reassign
        coordinates[0] = newCoordinate;

        showActivePoints(firstElementaryAreaFeature);

        return newLineString;
      }
      errorSelectPolygonPoint();

      // eslint-disable-next-line no-use-before-define
      drawAutoInteractionBionic.abortDrawing();
      return new LineString([]);
    }

    if (coordinates.length === 2) {
      geom.setCoordinates(coordinates);
    } else if (coordinates.length >= 3) {
      secondElementaryAreaFeature = findPolygonByPoint(coordinates[1] as number[]);
      if (secondElementaryAreaFeature) {
        // eslint-disable-next-line no-use-before-define
        drawAutoInteractionBionic.finishDrawing();
        return geom;
      }
      errorSelectPolygonPoint();
      // eslint-disable-next-line no-use-before-define
      drawAutoInteractionBionic.removeLastPoint();
    }

    return geom;
  };

  const drawAutoInteractionBionic = new Draw({
    source: map.lineSource,
    type: 'LineString',
    finishCondition: () => false,
    // @ts-ignore
    geometryFunction: drawAutoBionic,
  });

  drawAutoInteractionBionic.on('drawend', () => {
    let groupElementaryAreaFeatures = getGroupsElementaryAreaFeatures();

    const direction = getDirection(
      firstElementaryAreaFeature,
      secondElementaryAreaFeature,
      groupElementaryAreaFeatures[0],
    );

    if (direction === -1) {
      groupElementaryAreaFeatures = groupElementaryAreaFeatures.map((items) => items.reverse());
    }

    listPolygonBionic = getListElementaryAreaFeaturesFromGroup(
      firstElementaryAreaFeature,
      groupElementaryAreaFeatures,
    );

    listPolygonBionic.forEach((elementaryAreaFeature, index) => {
      const dataFeature = getDataFeature(elementaryAreaFeature);
      dataFeature.index = index;
      if (dataFeature.num == null) {
        dataFeature.num = Number(startNumberPolygon.value) + index;
      }
    });
    const line = new Feature({
      geometry: new LineString(listPolygonBionic
        .map((polygon) => getPointByPolygon(polygon).getGeometry().getCoordinates())),
    });

    map.pointSource.clear();
    map.pointSource.addFeatures(points.value as Feature<Point>[]);

    setTimeout(() => {
      map.lineSource.clear();
      map.lineSource.addFeature(line);
    }, 300);
  });

  return drawAutoInteractionBionic;
}
