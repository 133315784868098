<template>
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M21,28 L21,32 C21,32.5522847 20.5522847,33 20,33 C19.4477153,33 19,32.5522847 19,32
       L19,28 L21,28 Z M19,12 L19,16 C19,16.5522847 19.4477153,17 20,17 C20.5128358,17
        20.9355072,16.6139598 20.9932723,16.1166211 L21,16 L21,12 L29,12
         C30.1045695,12 31,12.8954305 31,14 L31,26 C31,27.1045695 30.1045695,28
          29,28 L21,28 L21,24 C21,23.4871642 20.6139598,23.0644928 20.1166211,23.0067277
           L20,23 C19.4871642,23 19.0644928,23.3860402 19.0067277,23.8833789 L19,24
            L19,28 L11,28 C9.8954305,28 9,27.1045695 9,26 L9,14 C9,12.8954305 9.8954305,12
             11,12 L19,12 Z M17,14 L11,14 L11,26 L17,26 L17,25 L12,25 L12,15 L17,15 L17,14
              Z M29,14 L22,14 L22,26 L29,26 L29,14 Z M20,18 C19.4871642,18 19.0644928,18.3860402
               19.0067277,18.8833789 L19,19 L19,21 C19,21.5522847 19.4477153,22 20,22 C20.5128358,22
                20.9355072,21.6139598 20.9932723,21.1166211 L21,21 L21,19 C21,18.4477153
                 20.5522847,18 20,18 Z M20,7 C20.5522847,7 21,7.44771525 21,8
                  L21,12 L19,12 L19,8 C19,7.44771525 19.4477153,7 20,7 Z"
      transform="translate(-9 -7)"
    />
  </svg>
</template>
