import pill from '@/assets/pill.svg';
import bug from '@/assets/bug-outline.svg';
import waves from '@/assets/waves.svg';
import redo from '@/assets/redo.svg';
import alert from '@/assets/alert-outline.svg';
import cannabis from '@/assets/cannabis.svg';
import tractor from '@/assets/tractor.svg';
import IncidentTypes from '@/models/IncidentTypesInterface';

const incidentTypes: {[key: string]: IncidentTypes} = {
  disease: {
    alias: 'disease', icon: 'mdi-pill', text: 'Заболевание', url: pill,
  },
  pest: {
    alias: 'pest', icon: 'mdi-bug-outline', text: 'Вредители и паразиты', url: bug,
  },
  technological: {
    alias: 'technological', icon: 'mdi-tractor', text: 'Технологическая ошибка', url: tractor,
  },
  flooding: {
    alias: 'flooding', icon: 'mdi-waves', text: 'Подтопление', url: waves,
  },
  lodging: {
    alias: 'lodging', icon: 'mdi-redo', text: 'Полегание', url: redo,
  },
  weed: {
    alias: 'weed', icon: 'mdi-cannabis', text: 'Сорняки', url: cannabis,
  },
  other: {
    alias: 'other', icon: 'mdi-alert-outline', text: 'Прочее', url: alert,
  },
};
export default incidentTypes;
