import Notify from 'quasar/src/plugins/Notify.js';;

export default function useNotifyMessages() {
  const dataSaveOnDBMsg = () => {
    Notify.create({
      type: 'positive',
      message: 'Данные сохранены! Как появится интернет, данные будут отправленный на сервер!',
    });
  };

  return { dataSaveOnDBMsg };
}
