import { Feature } from 'ol';
import {
  LineString, Point,
} from 'ol/geom';
import DataFeature from '@/dialogs/AssayDialog/types/DataFeature';
import { formatGeoJSONWriteFeature } from '@/helpers/map';

export default function useAdditionalFunctionsFeature() {
  const getArea = (feature: Feature): number => feature.get('area');
  const setArea = (feature: Feature, newArea: number) => { feature.set('area', newArea); };

  const getPositionsFeature = (feature: Feature): [number, number] => feature.get('positions');
  const setPositionsFeature = (feature: Feature, positions: [number, number]) => { feature.set('positions', positions); };

  const setIsRemove = (feature: Feature, status: boolean) => { feature.set('isRemove', status); };
  const getIsRemove = (feature: Feature) => feature.get('isRemove');

  const setNextPoint = (point: Feature<Point>, nextPoint: Feature<Point>) => {
    point.set('nextPoint', nextPoint);
  };
  const getNextPointFeature = (point: Feature<Point>) => point.get('nextPoint');

  const setBackPoint = (point: Feature<Point>, backPoint: Feature<Point>) => {
    point.set('backPoint', backPoint);
  };
  const getBackPointFeature = (point: Feature<Point>) => point.get('backPoint');

  const setLinePoint = (point: Feature<Point>, line: Feature<LineString>) => {
    point.set('line', line);
  };
  const getLinePoint = (point: Feature<Point>): Feature<LineString> => point.get('line');

  // @ts-ignore
  const getCoordinateFeature = (feature: Feature) => feature.getGeometry().getCoordinates();

  const setPolygonInPoint = (point: Feature<Point>, polygon: Feature) => point.set('polygon', polygon);

  const getPolygonByPoint = (point: Feature<Point>): Feature => point.get('polygon') as Feature;

  const setPointInPolygon = (polygon: Feature, point: Feature<Point>) => polygon.set('point', point);

  const getPointByPolygon = (polygon: Feature): Feature<Point> => polygon.get('point') as Feature<Point>;

  const setDataFeature = (feature: Feature, data: DataFeature) => feature.set('data', data);

  const getDataFeature = (feature: Feature): DataFeature => feature.get('data');

  const setGeoJsonFeature = (feature: Feature) => feature.set('geoJson', formatGeoJSONWriteFeature(feature));

  const getGeoJsonFeature = (feature: Feature): any => feature.get('geoJson');

  const clearDataFeature = (feature: Feature) => {
    const dataFeature = getDataFeature(feature);
    dataFeature.index = null;
    dataFeature.attributes = null;
  };

  const removeAllInfo = (point: Feature<Point>) => {
    setNextPoint(point, undefined);
    setLinePoint(point, undefined);
    setBackPoint(point, undefined);
    clearDataFeature(point);
  };

  const removeInfoRoleBackPoint = (point: Feature<Point>) => {
    const backPoint = getBackPointFeature(point);
    if (backPoint) {
      setNextPoint(backPoint, undefined);
      setLinePoint(backPoint, undefined);
    }
    setBackPoint(point, undefined);
    setIsRemove(point, undefined);
    clearDataFeature(point);
  };

  return {
    getPositionsFeature,
    setPositionsFeature,
    getPolygonByPoint,
    getPointByPolygon,
    setNextPoint,
    getNextPointFeature,
    setBackPoint,
    getBackPointFeature,
    setLinePoint,
    getLinePoint,
    getCoordinateFeature,
    removeAllInfo,
    removeInfoRoleBackPoint,
    setIsRemove,
    getIsRemove,
    setPolygonInPoint,
    setPointInPolygon,
    setDataFeature,
    getDataFeature,
    getArea,
    setArea,
    setGeoJsonFeature,
    getGeoJsonFeature,
  };
}
