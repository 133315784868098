/* eslint-disable */
/**
 * See workbox documentation for details about the lifecyle events of a service worker.
 * https://developers.google.com/web/tools/workbox/modules/workbox-window#important_service_worker_lifecycle_moments
 */
import { Workbox } from 'workbox-window';

function invokeServiceWorkerUpdateFlow(registration) {
  const status = confirm("Доступна новая версия приложения. Обновить сейчас?");
  if(status && registration.waiting) {
      // let waiting Service Worker know it should became active
      registration.waiting.postMessage('SKIP_WAITING')
    }
}

const register = async () => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // Workbox combines the ./src/sw.js file and injected manifest into the servicer-worker.js file in /dist
    // Uses vue.config.js workboxOptions.swSrc for the location of sw.js and swDest for the output location of 'service-worker.js'.
    // You can override the file names and locations by changing the values of workboxOptions in vue.config.js.
    const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    // wire up instance of registration so we can take further action on it.
    const registration = await wb.register();

    if (registration.waiting) {
      console.log('sw: show update');
      invokeServiceWorkerUpdateFlow(registration)
    }

    registration.addEventListener('updatefound', () => {
      console.log('sw:updatefound')
      if (registration.installing) {
        // wait until the new Service worker is actually installed (ready to take over)
        registration.installing.addEventListener('statechange', () => {
          console.log('sw:statechange')
          if (registration.waiting) {
            // if there's an existing controller (previous Service Worker), show the prompt
            if (navigator.serviceWorker.controller) {
              invokeServiceWorkerUpdateFlow(registration, wb)
            } else {
              // otherwise it's the first install, nothing to do
              console.log('Service Worker initialized for the first time')
            }
          }
        })
      }
    })

    let refreshing = false;

    // detect controller change and refresh the page
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log('sw:controllerchange refreshing', !refreshing)
      if (!refreshing) {
        window.location.reload()
        refreshing = true
      }
    })
  }
};

export default register;
