import { Forecast } from '@/dialogs/WeatherChartDialog/types/Forecast';
import BaseCrud from '@/api/configs/base-crud';

class ForecastApiService extends BaseCrud<Forecast> {
  ENDPOINT = 'forecasts';

  getByID(id: number, options?) {
    return super.getData(`${this.ENDPOINT}/${id}`, options);
  }
}

const forecastApiService = new ForecastApiService();

export default forecastApiService;
