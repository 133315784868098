import AnalysesData from '@/dialogs/AssayDialog/types/AnalysesData';
import { cratePolygon, formatGeoJSONReadFeatures, formatGeoJSONWriteFeature } from '@/helpers/map';
import flatten from '@turf/flatten';
import Feature from 'ol/Feature';
import { getArea } from 'ol/sphere';
import { MultiPolygon as TurfMultiPolygon } from '@turf/helpers/dist/js/lib/geojson';
import intersect from '@turf/intersect';
import { featureCollection } from '@turf/helpers';

export const getDefaultAttributes = (): AnalysesData => ({
  sampleNumber: null,
  n: null,
  p: null,
  k: null,
  s: null,
  ph: null,
  humus: null,
  salinization: null,
  salinizationType: null,
  ca: null,
  mg: null,
  na: null,
  fe: null,
  mn: null,
  zn: null,
  cu: null,
  b: null,
  mo: null,
  cl: null,
  co: null,
  soilCompaction: null,
});

export const getPolygonsOnMergeGridWithField = (
  fieldFeature: Feature,
  featureGrid: Feature,
  colsCount: number,
) => {
  const featureProjection = formatGeoJSONWriteFeature(fieldFeature);

  const featureGridProjection = flatten(
    formatGeoJSONWriteFeature(featureGrid)
      .geometry as TurfMultiPolygon,
  );

  const intersectFeatureGrid = featureGridProjection.features
    .reduce((array, feature, index) => {
      // @ts-ignore
      const intersectField = intersect(feature.geometry, featureProjection);
      if (intersectField) {
        intersectField.properties.positions = [Math.floor(index / colsCount), index % colsCount];
        intersectField.properties.area = Math.round(
          ((getArea(cratePolygon(intersectField.geometry))) / 10000) * 100,
        ) / 100;

        array.push(intersectField);
      }
      return array;
    }, []);

  return formatGeoJSONReadFeatures(featureCollection(intersectFeatureGrid));
};
