import script from "./Settings.vue?vue&type=script&lang=ts&setup=true"
export * from "./Settings.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSlider,QToggle,QBtn});
