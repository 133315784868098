import CrudService from '@/api/configs/crud.service';
import { OperationAttribute } from '@/modules/field/types/Operation';

class OperationAttributesApiService extends CrudService<OperationAttribute, OperationAttribute> {
  protected ENDPOINT = 'operation_attributes';

  protected normalizeData(data: OperationAttribute) {
    return data;
  }
}

const operationAttributesApiService = new OperationAttributesApiService();

export default operationAttributesApiService;
