import { defineStore } from 'pinia';
import { SatelliteType, SettingsSatellites } from '@/models/SatelliteType';
import ImageLayer from 'ol/layer/Image';
import LayerGroup from 'ol/layer/Group';
import SatelliteImage from '@/modules/field/data/SatelliteImage';
import Map from '@/modules/map/data/Map';
import MeteoFeature from '@/modules/map/features/MeteoFeature';
import FieldFeature from '@/modules/map/features/FieldFeature';
import ScoutingTaskFeature from '@/modules/map/features/ScoutingTaskFeature';
import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
import AreaSchemeFromImage from '@/dialogs/AssayDialog/types/AreaSchemeFromImage';
import ElementaryAreasScheme from '@/dialogs/AssayDialog/types/ElementaryAreasScheme';
import moment from 'moment/moment';

export type State = {
  settingsSatellites: SettingsSatellites;
  satelliteLayer: ImageLayer<any> | LayerGroup;
  satelliteImage: SatelliteImage;
  itemsTimeList: SatelliteType[];
  changeSettingsTimeList: (settingsTimeList: SettingsSatellites) => void;
  map: Map,
  meteos: MeteoFeature[];
  fields: FieldFeature[];
  fieldsByKey: FieldFeature[];
  scoutingTasks: ScoutingTaskFeature[];
  showScoutingAll: boolean;
  settingsAssay: {
    isNeedUpdateAssayData: boolean,
    indicator: IndicatorType,
    layer: ImageLayer<any> | LayerGroup;
    areaSchemes: AreaSchemeFromImage[],
    areaScheme: AreaSchemeFromImage,
    elementaryAreasScheme: ElementaryAreasScheme
    applyToAll: boolean,
    // analyses
    analysesDate: string,
    loadingAreaSchemes: boolean,
    fieldIdAnalysisDate: number,
    listAnalysisDate: {key: string, title: string}[],
    loadingListAnalysisDate: boolean,
    layout: 'scheme' | 'average',
    isHighlightField: boolean,
    dateStartAnalysis: string,
    interpolation: boolean,
  },
};

export const useMapStore = defineStore('map', {
  state: (): State => ({
    map: new Map(),
    settingsSatellites: {
      applyToAll: null,
      cloudiness: 20,
      imageDt: null,
      layerType: null,
      presentationType: '',
      fieldId: null,
    },
    satelliteLayer: null,
    satelliteImage: null,
    settingsAssay: {
      isNeedUpdateAssayData: false,
      applyToAll: null,
      indicator: null,
      analysesDate: null,
      layer: null,
      areaScheme: null,
      areaSchemes: [],
      elementaryAreasScheme: null,
      loadingAreaSchemes: false,
      fieldIdAnalysisDate: null,
      listAnalysisDate: [],
      loadingListAnalysisDate: false,
      layout: 'scheme',
      isHighlightField: false,
      dateStartAnalysis: moment().add(-183, 'days').format('YYYY-MM-DD'),
      interpolation: false,
    },
    itemsTimeList: [],
    changeSettingsTimeList: () => null,
    meteos: [],
    fields: [],
    fieldsByKey: [],
    scoutingTasks: [],
    showScoutingAll: false,
  }),
  actions: {
    removeSatelliteLayer() {
      this.setSatelliteLayer(null);
      this.setSatelliteImage(null);
    },
    setItemsTimeList(itemsTimeList: SatelliteType[]) {
      this.itemsTimeList = itemsTimeList;
    },
    setChangeSettingsTimeList(payload) {
      this.setChangedSettingsTimeList(payload);
    },

    setMap(meteos: MeteoFeature[]) {
      this.meteos = meteos;
    },
    setSatelliteImage(satelliteImage: SatelliteImage) {
      this.satelliteImage = satelliteImage;
    },
    setShowScoutingAll(showScoutingAll: boolean) {
      this.showScoutingAll = showScoutingAll;
    },
    setSatelliteLayer(satelliteLayer: ImageLayer<any> | LayerGroup) {
      this.satelliteLayer = satelliteLayer;
    },
    setMeteos(meteos: MeteoFeature[]) {
      this.meteos = meteos;
    },
    setFields(fields: FieldFeature[]) {
      this.fields = fields;
      const fieldsByKey = [];
      fields.forEach((item) => {
        fieldsByKey[item.props.id] = item;
      });

      this.fieldsByKey = fieldsByKey;
    },
    setScoutingTasks(scoutingTasks: ScoutingTaskFeature[]) {
      this.scoutingTasks = scoutingTasks;
    },
    setSettingsSatellite(settingsSatellites: SettingsSatellites) {
      this.settingsSatellites = settingsSatellites;
    },
    setChangedSettingsTimeList(changeSettingsTimeList:
                                (settingsTimeList: SettingsSatellites) => void) {
      this.changeSettingsTimeList = changeSettingsTimeList;
    },
    clearAllData() {
      this.$reset();
    },
  },
});
