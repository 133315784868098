import { computed, ref, toRefs } from 'vue';
import { Extent } from 'ol/extent';
import Projection from 'ol/proj/Projection';
import Units from 'ol/proj/Units';
import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import AreaSchemeFromImage from '@/dialogs/AssayDialog/types/AreaSchemeFromImage';
import optionsIndicators, { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
import { getSecurityDataByIndicator } from '@/dialogs/FieldManageDialog/helpers/helpers';
import LayerGroup from 'ol/layer/Group';
import useCanvasHelpers from '@/modules/map/components/Instruments/Sattings/Assay/components/useCanvasHelpers';
import ElementaryAreasScheme from '@/dialogs/AssayDialog/types/ElementaryAreasScheme';
import { cratePolygon } from '@/helpers/map';
import { fromLonLat } from 'ol/proj';
import centerOfMass from '@turf/center-of-mass';
import { groupingOfSoilsAccordingToAvailability } from '@/dialogs/AssayDialog/constants/spectrogramIndicators';
import useIsoBandsAssay from '@/modules/map/hooks/useIsoBandsAssay';
import useApiData from '@/modules/map/components/Instruments/Sattings/Assay/components/useApiData';
import useFeatureElements from '@/modules/map/components/Instruments/Sattings/Assay/components/useFeatureElements';
import { useFieldStore, useMapStore } from '@/stores';
import FieldFeature from '@/modules/map/features/FieldFeature';

const zoom = 1;

export default function useAnalysisLayer() {
  const mapStore = useMapStore();
  const fieldStore = useFieldStore();
  const { settingsAssay, map, fieldsByKey } = toRefs(mapStore);
  const { layer } = toRefs(settingsAssay.value);
  const fieldId = computed(() => fieldStore.activeField?.id);
  const suffix = ref(null);
  const { getIsoBands } = useIsoBandsAssay();
  const {
    getCoordinateCanvas, getFormatPositionsPolygons,
    drawPolygon, createCanvasElement, getColorAndTitleIndicator,
  } = useCanvasHelpers();
  const { getAreaSchemeByDate, getAllAreaScheme } = useApiData();
  const { createPoint } = useFeatureElements();

  const setLayer = (newLayer) => {
    const oldLayer = layer.value;
    // @ts-ignore
    oldLayer && map.value.removeLayer(oldLayer);
    newLayer && map.value.addLayer(newLayer);
    layer.value = newLayer;
  };

  const removeLayer = () => {
    map.value.pointAssaySource.clear();
    setLayer(null);
  };

  const createAndGetImageLayer = (extent: Extent, url: string) => {
    const projection = new Projection({
      code: 'EPSG:3857',
      units: Units.PIXELS,
      extent,
    });

    const source = new Static({
      url,
      projection,
      imageExtent: extent,
    });

    return new ImageLayer({
      extent, source, zIndex: 3,
    });
  };

  const getImageLayoutAverage = (
    {
      feature, extent, widthField, heightField, dataAreasImage, analysesTypes,
    }: AreaSchemeFromImage,
    indicator: IndicatorType,
  ) => {
    if (!suffix.value) {
      suffix.value = optionsIndicators.find(({ key }) => key === indicator).suffix;
    }

    if (!dataAreasImage?.length) {
      return createAndGetImageLayer(extent, '');
    }

    const { canvasPrint, ctxPrint } = createCanvasElement(widthField, heightField);

    const activeAnalyses = dataAreasImage.filter(({ analyses }) => analyses[indicator] != null);
    if (!activeAnalyses?.length) {
      return createAndGetImageLayer(extent, '');
    }
    const averageIndicatorData = activeAnalyses
      .reduce((sum, { analyses }) => sum + analyses[indicator], 0) / activeAnalyses.length;

    const { color, title } = getSecurityDataByIndicator(
      indicator,
      analysesTypes,
      averageIndicatorData,
    );
    const [x1, y1, x2, y2] = feature.getGeometry().getExtent();

    const leftTopPoint = [x1, y2];

    const formatPositionsPolygon = getFormatPositionsPolygons(feature.getGeometry(), leftTopPoint);
    drawPolygon(ctxPrint, formatPositionsPolygon, color);

    // TODO функционала работает со средними значениями поля

    const pointText = [x1 + ((x2 - x1) / 2), y2 + ((y1 - y2) / 2)];

    const pointFeature = createPoint(pointText, `${(Math.round(averageIndicatorData * 100) / 100).toString()} ${suffix.value}`, title);
    map.value.pointAssaySource.addFeature(pointFeature);

    return createAndGetImageLayer(extent, canvasPrint.toDataURL('image/png'));
  };

  const getImageLayoutScheme = (
    {
      extent, widthField, heightField, dataAreasImage, analysesTypes,
    }: AreaSchemeFromImage,
    indicator: IndicatorType,
  ) => {
    if (!dataAreasImage?.length) {
      return createAndGetImageLayer(extent, '');
    }

    const { canvasPrint, ctxPrint } = createCanvasElement(widthField, heightField);

    dataAreasImage?.forEach(({
      num, positionsPolygons, analyses, pointGPS, area,
    }) => {
      const { color, title } = getColorAndTitleIndicator(indicator, analyses, analysesTypes);
      // add polygon
      drawPolygon(ctxPrint, positionsPolygons, color);
      const pointFeature = createPoint(pointGPS, `${num.toString()} (${area} Га)`, title);
      map.value.pointAssaySource.addFeature(pointFeature);
    });

    return createAndGetImageLayer(extent, canvasPrint.toDataURL('image/png'));
  };

  const getImageLayer = {
    average: getImageLayoutAverage,
    scheme: getImageLayoutScheme,
  };

  const addLayerGroupImages = (areaSchemes: AreaSchemeFromImage[], indicator: IndicatorType) => {
    const layers = areaSchemes
      .map((areaScheme) => getImageLayer[settingsAssay.value.layout](areaScheme, indicator));
    setLayer(new LayerGroup({
      layers,
    }));
  };

  const addLayerImage = (areaScheme: AreaSchemeFromImage, indicator: IndicatorType) => {
    const keyImageLayer = settingsAssay.value.layout;
    setLayer(getImageLayer[keyImageLayer](areaScheme, indicator));
  };

  const getFormatAreaScheme = (areaScheme: ElementaryAreasScheme): AreaSchemeFromImage => {
    const feature = fieldsByKey.value[areaScheme.fieldId] as FieldFeature;
    const extent = feature.getGeometry().getExtent();
    const [x1, y1, x2, y2] = extent;
    const leftTopPoint = [x1, y2];

    const fieldCoordinatesCanvas = getFormatPositionsPolygons(
      feature.getGeometry(),
      leftTopPoint,
    );

    const width = Math.round((x2 - x1) / zoom);
    const height = Math.round((y2 - y1) / zoom);

    const attributesAnalysisByIdElementaryArea = areaScheme?.analyses?.data
      ? areaScheme.analyses.data.reduce((result, { elementaryAreaId, attributes }) => {
        // eslint-disable-next-line no-param-reassign
        result[elementaryAreaId] = attributes;
        return result;
      }, []) : [];

    const dataAreasImage = areaScheme.elementaryAreas?.map(({
      id, geometry, point, area, num,
    }) => {
      const polygon = cratePolygon(geometry);

      const formatPositionsPolygons = getFormatPositionsPolygons(polygon, leftTopPoint);

      const analysesElementaryArea = attributesAnalysisByIdElementaryArea[id];

      const centerPoint = getCoordinateCanvas(
        leftTopPoint,
        fromLonLat(centerOfMass(geometry).geometry.coordinates),
      );

      return {
        num: num.toString(),
        area: area.toFixed(2).toString(),
        positionsPolygons: formatPositionsPolygons,
        centerPoint,
        point: getCoordinateCanvas(leftTopPoint, fromLonLat(point.coordinates)),
        pointGPS: fromLonLat(point.coordinates),
        analyses: analysesElementaryArea,
      };
    });

    return {
      feature,
      widthField: width,
      heightField: height,
      extent,
      analysesTypes: areaScheme.analyses.types,
      dataAreasImage,
      fieldCoordinatesCanvas,
    };
  };

  const handlerShowImageLayer = () => {
    const {
      indicator, areaScheme, elementaryAreasScheme,
    } = settingsAssay.value;
    if (settingsAssay.value.interpolation && !!elementaryAreasScheme) {
      const {
        color,
        indicator: settingsIndicator,
      } = groupingOfSoilsAccordingToAvailability[indicator];
      const groupingOfSoils = Array.isArray(settingsIndicator)
        ? settingsIndicator
        : settingsIndicator[areaScheme.analysesTypes[indicator]];

      const breaks = [-100, ...groupingOfSoils, 100000];
      const colorsBreaks = [color[0], ...color];
      colorsBreaks.splice(3, 0, colorsBreaks[3]);
      const { heightField, widthField } = settingsAssay.value.areaScheme;

      getIsoBands(
        fieldStore.activeField.geometry,
        elementaryAreasScheme.elementaryAreas,
        elementaryAreasScheme.analyses,
        indicator,
        breaks,
        colorsBreaks,
        widthField,
        heightField,
        areaScheme.fieldCoordinatesCanvas,
      ).then((image: string) => {
        setLayer(createAndGetImageLayer(
          areaScheme.extent,
          image,
        ));
      });
    } else {
      addLayerImage(settingsAssay.value.areaScheme as AreaSchemeFromImage, indicator);
    }
  };

  const uploadElementaryAreas = () => {
    getAreaSchemeByDate().subscribe((elementaryAreasScheme) => {
      settingsAssay.value.elementaryAreasScheme = elementaryAreasScheme;
      settingsAssay.value.areaScheme = getFormatAreaScheme(elementaryAreasScheme);
      handlerShowImageLayer();
    });
  };

  const updateLayerMap = () => {
    const { indicator } = settingsAssay.value;
    removeLayer();
    if (!indicator) {
      return;
    }

    if (settingsAssay.value.applyToAll == null) {
      settingsAssay.value.applyToAll = !fieldId.value;
    }

    if (settingsAssay.value.applyToAll) {
      if (!settingsAssay.value.areaSchemes.length) {
        getAllAreaScheme()
          .subscribe((areaSchemes) => {
            const formatAreaSchemes = (areaSchemes || [])
              .map((areaScheme) => getFormatAreaScheme(areaScheme));
            settingsAssay.value.areaSchemes = formatAreaSchemes;
            addLayerGroupImages(formatAreaSchemes, indicator);
          });
      } else {
        addLayerGroupImages(settingsAssay.value.areaSchemes as AreaSchemeFromImage[], indicator);
      }
    } else if (settingsAssay.value.analysesDate) {
      if (settingsAssay.value.areaScheme) {
        uploadElementaryAreas();
      } else {
        addLayerImage(settingsAssay.value.areaScheme as AreaSchemeFromImage, indicator);
      }
    }
  };

  return {
    addLayerImage,
    addLayerGroupImages,
    removeLayer,
    updateLayerMap,
    setLayer,
    getFormatAreaScheme,
    handlerShowImageLayer,
    uploadElementaryAreas,
  };
}
