import CrudService from '@/api/configs/crud.service';
import Machinery from '@/modules/directories/components/machineries/types/Machinery';

class MachineriesApiService extends CrudService<Partial<Machinery>, Machinery> {
  protected ENDPOINT = 'machineries';

  protected normalizeData(data:Machinery) {
    return data;
  }
}

const machineriesApiService = new MachineriesApiService();

export default machineriesApiService;
