import script from "./HeaderCard.vue?vue&type=script&lang=ts&setup=true"
export * from "./HeaderCard.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QBtn,QToolbarTitle,QLinearProgress});
