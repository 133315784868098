import { ActiveCycle } from '@/modules/field/types/Field';
import { fallowTypes, FallowTypesValue } from '@/helpers/sowCycle';

export default function useFieldTitle() {
  const getTitleField = (fallowType: FallowTypesValue, activeCycle: Partial<ActiveCycle>) => {
    const textFallowType = fallowTypes.find(({ value }) => value === fallowType)?.text;
    let textTitle = 'Пар: ';
    if (fallowType === FallowTypesValue.occupied) {
      textTitle += activeCycle?.specieTitle ? activeCycle.specieTitle : textFallowType;
      return textTitle;
    }
    textTitle += textFallowType;
    return textTitle;
  };

  const getSpecieTitle = (activeCycle: Partial<ActiveCycle>) => {
    const fallowType = activeCycle?.fallowType;
    if (fallowType) {
      return getTitleField(fallowType, activeCycle);
    }
    return activeCycle?.specieTitle ? activeCycle.specieTitle : 'Нет данных о севе';
  };

  return { getSpecieTitle, getTitleField };
}
