import script from "./Laboratory.vue?vue&type=script&lang=ts&setup=true"
export * from "./Laboratory.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QInput,QToggle});
