// eslint-disable-next-line no-shadow
import { Organization } from '@/models/Organization';

export enum AdministrativeLevel {
  Region = 'Region',
  District = 'District'
}

type User = {
  id?: number;
  name: string;
  username: string;
  email: string;
  password: string;
  organizationId: number;
  organization: Organization;
  phone: string;
  provider: string;
  administrativeLevel?: AdministrativeLevel;
  administrates?: {id: number};
};

export default User;
