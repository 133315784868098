import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OperationType } from '@/modules/field/types/Operation';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';

let operationType: OperationType[] = null;

export default function useOperationTypesAutocompleteDataSource(): DataSource<OperationType> {
  const {
    get, dataSubject, ...anyData
  } = useDataSource<OperationType>('operation_types', (search: string) => {
    if (!operationType) {
      get({ params: { search } })
        .pipe(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          catchError(() => of([])),
        ).subscribe((response: OperationType[]) => {
          dataSubject.next(response);
          operationType = response;
        });
    } else {
      dataSubject.next(operationType as OperationType[]);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
