import elementaryAreaSchemeApiService from '@/api/elementaryAreaSchemeApiService';
import { finalize } from 'rxjs/operators';
import { toRefs } from 'vue';
import areaSchemeDateAnalysisApiService from '@/api/areaSchemeDateAnalysisApiService';
import moment from 'moment';
import { useFieldStore, useMapStore } from '@/stores';

export default function useApiData() {
  const mapStore = useMapStore();
  const fieldStore = useFieldStore();
  const { settingsAssay } = toRefs(mapStore);

  const getAreaSchemeByDate = () => {
    const { analysesDate } = settingsAssay.value;
    settingsAssay.value.loadingAreaSchemes = true;
    return elementaryAreaSchemeApiService
      .getAreaSchemeByDate(fieldStore.activeField?.id, analysesDate)
      .pipe(finalize(() => {
        settingsAssay.value.loadingAreaSchemes = false;
      }));
  };

  const getAllAreaScheme = () => {
    settingsAssay.value.loadingAreaSchemes = true;
    return elementaryAreaSchemeApiService.getAllAreaSchemes()
      .pipe(finalize(() => {
        settingsAssay.value.loadingAreaSchemes = false;
      }));
  };

  const uploadAnalysisCollectionDates = () => {
    areaSchemeDateAnalysisApiService.getAllDatesField(fieldStore.activeField?.id)
      .pipe(finalize(() => {
        settingsAssay.value.loadingListAnalysisDate = false;
      }))
      .subscribe((dates) => {
        settingsAssay.value.isNeedUpdateAssayData = false;
        settingsAssay.value.listAnalysisDate = (dates || []).map((date) => ({ key: date, title: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') }));
      });
  };

  return { getAreaSchemeByDate, getAllAreaScheme, uploadAnalysisCollectionDates };
}
