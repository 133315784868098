import script from "./PreviousCycle.vue?vue&type=script&lang=ts&setup=true"
export * from "./PreviousCycle.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QSeparator,QCardSection});
