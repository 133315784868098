import script from "./Simplify.vue?vue&type=script&lang=ts&setup=true"
export * from "./Simplify.vue?vue&type=script&lang=ts&setup=true"

import "./Simplify.vue?vue&type=style&index=0&id=6acc66be&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QSlider});
