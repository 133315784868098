import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import ElementaryAreasScheme from '@/dialogs/AssayDialog/types/ElementaryAreasScheme';

class ElementaryAreaSchemeApiService extends BaseCrud<ElementaryAreasScheme> {
  protected ENDPOINT = (fieldId: number) => `fields/${fieldId}/elementary_area_scheme`;

  getAreaScheme(fieldId: number, options?: AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT(fieldId)}`, options);
  }

  getLastAreaScheme(fieldId: number, options?: AxiosRequestConfig) {
    return super.getById(`${this.ENDPOINT(fieldId)}/last`, options);
  }

  getAllAreaSchemes(options?: AxiosRequestConfig) {
    return super.getData('/fields/elementary_area_scheme/all', options);
  }

  getAreaSchemeById(
    fieldId: number,
    elementaryAreaSchemeById: number,
    options?: AxiosRequestConfig,
  ) {
    return super.getById(`${this.ENDPOINT(fieldId)}/${elementaryAreaSchemeById}`, options);
  }

  getAreaSchemeByDate(
    fieldId: number,
    date: string,
    options?: AxiosRequestConfig,
  ) {
    return super.getById(`${this.ENDPOINT(fieldId)}/date/${date}`, options);
  }

  postAreaScheme(fieldId: number, data: ElementaryAreasScheme) {
    return super.post(`${this.ENDPOINT(fieldId)}`, data);
  }
}

const elementaryAreaSchemeApiService = new ElementaryAreaSchemeApiService();

export default elementaryAreaSchemeApiService;
