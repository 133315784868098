import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Ref, ref, toRefs, UnwrapRef,
} from 'vue';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { GrowthStage, SubStage } from '@/dialogs/ReportScoutingTaskDialog/types/GrowthStage';
import { useFieldStore } from '@/stores';

export default function
useGrowthStagesAutocompleteDataSource(id: number): DataSource<SubStage>
    & {growthSubStages: Ref<UnwrapRef<SubStage[]>>} {
  const growthSubStages = ref<SubStage[]>(null);

  const fieldStore = useFieldStore();

  const { species } = toRefs(fieldStore);

  const getSubStages = (species) => {
    let arr = [];
    species.stages.forEach((stage: GrowthStage) => {
      arr = [...arr, { group: stage.title, disable: true },
        ...stage.subStages.map(((item) => ({ ...item, stageTitle: stage.title })))];
    });
    return arr;
  };

  const {
    get, dataSubject, ...anyData
  } = useDataSource<SubStage>('stages', (search: string) => {
    if (!growthSubStages.value && id) {
      get({ params: { search, specieId: id } })
        .pipe(
          map((response: any) => getSubStages(response)),
          catchError(() => of(getSubStages(species.value[id]))),

        ).subscribe((value: SubStage[]) => {
          dataSubject.next(value);
          growthSubStages.value = value;
        });
    } else {
      dataSubject.next(growthSubStages.value as SubStage[]);
    }
  });

  return {
    get,
    dataSubject,
    growthSubStages,
    ...anyData,
  };
}
