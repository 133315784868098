import script from "./Satellite.vue?vue&type=script&lang=ts&setup=true"
export * from "./Satellite.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QIcon,QSlider,QToggle});
