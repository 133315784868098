import script from "./ReportScoutingTaskDialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./ReportScoutingTaskDialog.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QFile,QIcon,QItem,QItemSection,QItemLabel,QBadge,QSlider,QBtn});
