export const DIALOG_CONTAINER_ID = 'dialog-container';

export const DIALOG_CONTAINER_SELECTOR = `#${DIALOG_CONTAINER_ID} div`;

export const MAP_LEFT_DIALOG_CONTAINER_ID = 'map-left-dialog-container';

export const MAP_LEFT_DIALOG_CONTAINER_SELECTOR = `#${MAP_LEFT_DIALOG_CONTAINER_ID} div`;

export const NAME_TYPE_DIALOG_STORE = 'dialog/open';

export const FORMATS_OF_DATA = {
  date: 'YYYY-MM-DD',
  dateRus: 'DD.MM.YY',
  time: 'HH:mm',
  datetime: 'YYYY-MM-DD HH:mm',
};
