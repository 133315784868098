import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosRequestConfig as Config, AxiosResponse as Response } from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;
import client from '@/api/configs/client';
// eslint-disable-next-line import/no-cycle
import useClearAllData from '@/hooks/useClearAllData';

export class HttpService {
  constructor() {
    client.interceptors.request.use(
      this.useRequest.bind(this),
      this.handleError.bind(this),
    );

    client.interceptors.response.use(
      this.useResponse.bind(this),
      this.handleError.bind(this),
    );
  }

  private useRequest(config: Config): Config {
    return {
      ...config,
      withCredentials: true,
      headers: { ...config.headers },
    };
  }

  private useResponse(response: Response): Response {
    return response;
  }

  private handleError(e) {
    if (e.response == null) {
      Notify.create({
        type: 'negative',
        message: 'Доступ к серверу отсутствует.',
      });
    } else {
      switch (e.response.status) {
        case 400: {
          Notify.create({
            type: 'negative',
            message: e.response.data?.error || e.response.data?.message || e.response.data,
          });
          break;
        }
        case 401: {
          useClearAllData().clearAllData();
          document.location.href = '/login';
          break;
        }
        case 403: {
          Notify.create({
            type: 'negative',
            message: 'Ошибка 403: Доступ запрещен.',
          });
          break;
        }
        case 404: {
          Notify.create({
            type: 'negative',
            message: 'Ошибка 404',
          });
          break;
        }
        case 422: {
          Notify.create({
            type: 'negative',
            message: 'Ошибка заполнения полей!',
          });
          break;
        }
        case 429: {
          Notify.create({
            type: 'negative',
            message: 'Ошибка 429: Слишком много запросов.',
          });
          break;
        }
        case 500: {
          Notify.create({
            type: 'negative',
            message: e.response?.data?.message || 'Произошла непредвиденная ошибка! Обратитесь к администратору!',
          });
          break;
        }
        default: {
          Notify.create({
            type: 'negative',
            message: 'Произошла непредвиденная ошибка!',
          });
        }
      }
    }
    return Promise.reject(e);
  }

  private observableFactory<T = any>(
    promise: (...args) => Promise<Response<T>>,
    ...args: any[]
  ): Observable<Response<T>> {
    return new Observable<Response<T>>((observer) => from(promise(...args)).subscribe(observer));
  }

  private getObservableResponse<T = any>(
    promise: (...args) => Promise<Response<T>>,
    ...args: any[]
  ): Observable<T> {
    return this.observableFactory<T>(promise, ...args).pipe(this.transformResponse());
  }

  private transformResponse<T = any>() {
    return map<Response<T>, T>(({ data }) => {
      if (typeof data === 'object' && 'success' in data) {
        // @ts-ignore
        return data.data;
      }
      return data;
    });
  }

  get<T>(url: string, config?: Config): Observable<T> {
    return this.getObservableResponse<T>(client.get, url, config);
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: Config,
  ): Observable<T> {
    return this.getObservableResponse<T>(client.post, url, data, config);
  }

  put<T = any>(
    url: string,
    data?: any,
    config?: Config,
  ): Observable<T> {
    return this.getObservableResponse<T>(client.put, url, data, config);
  }

  patch<T = any>(
    url: string,
    data?: any,
    config?: Config,
  ): Observable<T> {
    return this.getObservableResponse<T>(client.patch, url, data, config);
  }

  delete(url: string): Observable<any> {
    return this.getObservableResponse(client.delete, url);
  }
}

const httpService = new HttpService();

export default httpService;
