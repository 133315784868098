import {
  createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw,
} from 'vue-router';
import Loading from 'quasar/src/plugins/Loading.js';;
// eslint-disable-next-line import/no-cycle
import restoreUserData from '@/modules/auth/guards/restoreUserData';
import Layout from '@/types/Layout';
// eslint-disable-next-line import/no-cycle
import checkAuthData from '@/modules/auth/guards/checkAuthData';
import routeNames from '@/router/routeNames';
import FieldsAnalysisReport from '@/modules/field/components/FieldsAnalysisReport/FieldsAnalysisReport.vue';
import Error403 from '../views/errors/403.vue';
import DefaultView from '../views/Default.vue';
import Map from '../views/Map.vue';
import ListFields from '../views/panels/ListFields.vue';
import Field from '../views/panels/Field.vue';
import DirectoriesOperations from '../views/DirectoriesOperations.vue';
import SplitMap from '../views/SplitMap.vue';
import Operations from '../views/panels/Operations.vue';
import SowingHistory from '../views/panels/SowingHistory.vue';
import Satellite from '../views/panels/Satellite.vue';
import Scouting from '../views/panels/Scouting.vue';
import ScoutingCard from '../views/panels/ScoutingCard.vue';
import Districts from '../views/panels/Districts.vue';
import MeteoList from '../views/panels/MeteoList.vue';
import StationOperability from '../views/reports/StationOperability.vue';
import OrganizationProfile from '../views/OrganizationProfile.vue';
import Meteos from '../views/panels/Meteos.vue';
import Login from '../views/Login.vue';
import Organizations from '../views/panels/Organizations.vue';
import Machineries from '../views/directories/Machineries.vue';
import OperationDirections from '../views/directories/Operations.vue';
import Error404 from '../views/errors/404.vue';
import FieldsTable from '../views/reports/FieldsTable.vue';
import SpeciesTable from '../views/reports/SpeciesTable.vue';
import SpeciesDistribution from '../views/reports/SpeciesDistribution.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/403',
    name: routeNames.error_403,
    component: Error403,
    meta: {
      title: 'Ошибка 403',
      layout: Layout.default,
    },
  },
  {
    path: '/',
    name: routeNames.default,
    component: DefaultView,
    meta: {
      title: 'КлеверFarmer',
      auth: true,
    },
  },
  {
    path: '/districts',
    name: routeNames.districts,
    components: {
      default: Map,
      navPanel: () => Districts,
    },
    meta: {
      title: 'Районы',
      navPanelTitle: 'Районы',
      requiresRights: 'Districts',
      auth: true,
    },
  },
  {
    path: '/organizations',
    name: routeNames.organizations,
    components: {
      default: Map,
      navPanel: Organizations,
    },
    meta: {
      title: 'Организации',
      navPanelTitle: 'Организации',
      requiresRights: 'Organizations',
      auth: true,
    },
  },
  {
    path: '/fields',
    name: routeNames.fields,
    components: {
      default: Map,
      navPanel: ListFields,
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      auth: true,
    },
  },
  {
    path: '/fields/:id',
    name: routeNames.fieldsId,
    components: {
      default: Map,
      navPanel: Field,
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      auth: true,
    },
  },
  {
    path: '/directories',
    name: routeNames.directories,
    components: {
      default: DirectoriesOperations,
    },
    meta: {
      title: 'Справочник работ',
      navPanelTitle: 'Справочник работ',
      auth: true,
    },
    children: [
      {
        path: '',
        redirect: { name: routeNames.directoriesOperations },
      },
      {
        path: 'operations',
        name: routeNames.directoriesOperations,
        component: OperationDirections,
        meta: {
          title: 'Справочник работ',
          navPanelTitle: 'Справочник работ',
          auth: true,
        },
      },
      {
        path: 'machineries',
        name: routeNames.directoriesMachineries,
        component: Machineries,
        meta: {
          title: 'Машины и агрегаты',
          navPanelTitle: 'Машины и агрегаты',
          auth: true,
        },
      },
      {
        path: '*',
        redirect: { name: routeNames.error_404 },
      },
    ],
  },
  {
    path: '/fields/:id/split',
    name: routeNames.splitMap,
    components: {
      default: SplitMap,
      navPanel: Field,
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      auth: true,
    },
  },
  {
    path: '/fields/:id/operations',
    name: routeNames.fieldOperations,
    components: {
      default: Map,
      navPanel: Field,
      expandedPanel: Operations,
    },
    meta: {
      title: 'Журнал работ',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Журнал работ',
      auth: true,
    },
  },
  {
    path: '/fields/:id/history',
    name: routeNames.fieldHistory,
    components: {
      default: Map,
      navPanel: Field, // FieldPanel
      expandedPanel: SowingHistory, // HistoryPanel
    },
    meta: {
      title: 'История поля',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'История поля',
      auth: true,

    },
  },
  {
    path: '/fields/:id/satellite',
    name: routeNames.fieldSatellite,
    components: {
      default: Map,
      navPanel: Field,
      expandedPanel: Satellite, // SatellitePanel
    },
    meta: {
      title: 'Спутниковые данные',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Спутниковые данные',
      auth: true,
    },
  },
  {
    path: '/fields/:id/scouting',
    name: routeNames.fieldScouting,
    components: {
      default: Map,
      navPanel: Field,
      expandedPanel: Scouting, // ScoutingPanel
    },
    meta: {
      title: 'Осмотры полей',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Осмотры полей',
      auth: true,

    },
  },
  {
    path: '/fields/:id/scouting/:taskId',
    name: routeNames.scoutingTask,
    components: {
      default: Map,
      navPanel: Field,
      expandedPanel: ScoutingCard, // ScoutingCard
    },
    meta: {
      title: 'Осмотры полей',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Осмотры полей',
      auth: true,
    },
  },
  {
    path: '/meteos',
    name: routeNames.meteos,
    components: {
      default: Map,
      navPanel: MeteoList,
    },
    meta: {
      title: 'Метеостанции',
      navPanelTitle: 'Метеостанции',
      auth: true,
    },
  },
  {
    path: '/meteo-health',
    name: routeNames.meteoHealth,
    components: {
      default: StationOperability,
    },
    meta: {
      title: 'Оценка работоспособности станций',
      auth: true,
    },
  },
  {
    path: '/organization-profile',
    name: routeNames.organizationProfile,
    components: {
      default: OrganizationProfile,
    },
    meta: {
      title: 'Профиль организации',
      auth: true,
    },
  },
  {
    path: '/species-distribution',
    name: routeNames.speciesDistribution,
    components: {
      default: SpeciesDistribution,
    },
    meta: {
      title: 'Структура посевов',
      auth: true,
    },
  },
  {
    path: '/species-table',
    name: routeNames.speciesTable,
    components: {
      default: SpeciesTable,
    },
    meta: {
      title: 'Структура посевов/табличный вывод',
      auth: true,
    },
  },
  {
    path: '/fields-table',
    name: routeNames.fieldsTable,
    components: {
      default: FieldsTable,
    },
    meta: {
      title: 'Список полей/табличный вывод',
      auth: true,
    },
  },
  {
    path: '/fields-analysis-report',
    name: routeNames.fieldsAnalysisReport,
    components: {
      default: FieldsAnalysisReport,
    },
    meta: {
      title: 'Отчет анализов полей',
      auth: true,
    },
  },
  {
    path: '/meteos/:id',
    name: routeNames.meteosId,
    components: {
      default: Map,
      navPanel: Meteos,
    },
    meta: {
      title: 'Метеостанции',
      navPanelTitle: 'Метеостанции',
      auth: true,
    },
  },
  {
    path: '/login',
    name: routeNames.login,
    component: Login,
    meta: {
      title: 'Вход',
      layout: Layout.default,
    },
    beforeEnter: checkAuthData,
  },
  {
    path: '/:pathMatch(.*)*',
    name: routeNames.error_404,
    component: Error404,
    meta: {
      title: 'Ошибка 404',
      layout: Layout.default,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  Loading.show();
  next();
});

router.beforeEach(restoreUserData);
router.afterEach(() => {
  setTimeout(
    () => {
      Loading.hide();
    },
    300,
  );
});

export default router;
