import Map from '@/modules/map/data/Map';
import useHelpers from '@/dialogs/AssayDialog/components/maps/CreateRouteMap/composables/useHelpers';
import Feature from 'ol/Feature';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import { onUnmounted } from 'vue';
import useHoverElementaryArea from './useHoverElementaryArea';

export default function useRemoveElementaryArea(
  map: Map,
  resetSettings: () => void,
  updateCurrentNumberOfSamplesToTake: ()=> void,
) {
  const { onHoverPolygon, offHoverPolygon } = useHoverElementaryArea(map);
  const { getElementaryAreaOnMapByClick } = useHelpers();
  const {
    setIsRemove, getPointByPolygon, removeAllInfo,
  } = useAdditionalFunctionsFeature();

  const removePoint = (polygonFeature: Feature) => {
    const point = getPointByPolygon(polygonFeature);
    removeAllInfo(point);
    setIsRemove(point, true);
    resetSettings();
    updateCurrentNumberOfSamplesToTake();
  };

  const removeEvent = (e) => {
    const clickFeature = getElementaryAreaOnMapByClick(e, map);
    if (clickFeature) {
      removePoint(clickFeature);
    }
  };

  const onRemoveElementaryArea = () => {
    onHoverPolygon();
    map.map.on('click', removeEvent);
  };

  const offRemoveElementaryArea = () => {
    map.map.un('click', removeEvent);
    offHoverPolygon();
  };

  onUnmounted(() => {
    map.map.un('click', removeEvent);
  });

  return { onRemoveElementaryArea, offRemoveElementaryArea };
}
