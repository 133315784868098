import { Icon, Style } from 'ol/style';

export const crossStyle = (feature) => {
  feature.setStyle(() => [
    new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        scale: 0.6,
        crossOrigin: 'anonymous',
        src: '/images/icons/cross.png',
      }),
      zIndex: 2000,
    }),
  ]);
  return null;
};

export const crossModifyStyle = (feature) => {
  feature.setStyle(() => [
    new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        scale: 0.9,
        crossOrigin: 'anonymous',
        color: '#FFB74D',
        src: '/images/icons/cross.png',
      }),
      zIndex: 2000,
    }),
  ]);
  return null;
};
