import script from "./Assay.vue?vue&type=script&setup=true&lang=ts"
export * from "./Assay.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToggle,QSeparator});
