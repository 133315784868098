import CrudService from '@/api/configs/crud.service';

abstract class FormCrudService<RequestDataT = any, ResponseDataT = any>
  extends CrudService<RequestDataT, ResponseDataT> {
  edit(id: number, data: RequestDataT) {
    return super.post(`${this.ENDPOINT}/${id}?_method=PUT`, this.normalizeData(data));
  }
}

export default FormCrudService;
