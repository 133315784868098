import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Extent } from 'ol/extent';
import useMap from '@/hooks/useMap';

export default function useManagerMap(map = useMap()) {
  const {
    map: mapOl, source, select, selected, pointSource, clusterFeaturesSource,
  } = map;

  const clearSelected = () => selected.clear();

  const addOverlay = (overlay: Overlay) => {
    mapOl.addOverlay(overlay);
  };

  const removeOverlay = (overlay: Overlay) => {
    mapOl.removeOverlay(overlay);
  };

  const addSourceFeature = (feature: Feature<Geometry>) => {
    if (!source.hasFeature(feature)) {
      source.addFeature(feature);
    }
  };

  const removeSourceFeature = (feature: Feature<Geometry>) => {
    if (source.hasFeature(feature)) {
      source.removeFeature(feature);
    }
  };

  const addPointSourceFeature = (feature: Feature<Geometry>) => {
    if (pointSource.hasFeature(feature)) {
      pointSource.addFeature(feature);
    }
  };

  const removePointSourceFeature = (feature: Feature<Geometry>) => {
    if (pointSource.hasFeature(feature)) {
      pointSource.removeFeature(feature);
    }
  };

  const removeClusterSourceFeature = (feature: Feature<Geometry>) => {
    if (clusterFeaturesSource.hasFeature(feature)) {
      clusterFeaturesSource.removeFeature(feature);
    }
  };

  const getView = () => map.getView();

  const fit = (geometryOrExtent: SimpleGeometry | Extent) => {
    map.getView().fit(
      geometryOrExtent,
      { duration: 1000, padding: [15, 15, 15, 15] },
    );
  };

  const setActiveFeature = (activeFeature:Feature<Geometry>) => {
    map.setActiveFeature(activeFeature);
  };

  return {
    map: mapOl,
    source,
    select,
    viewport: mapOl.getViewport(),
    pointSource,
    clusterSource: clusterFeaturesSource,
    selected,
    clearSelected,
    addOverlay,
    removeOverlay,
    addSourceFeature,
    removeSourceFeature,
    addPointSourceFeature,
    removePointSourceFeature,
    removeClusterSourceFeature,
    getView,
    fit,
    setActiveFeature,
  };
}
