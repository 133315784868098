enum Route {
    Auto = 'Auto',
    Hand = 'Hand'
}
export default Route;

export const routeLabel = {
  [Route.Auto]: 'Автоматический',
  [Route.Hand]: 'Ручной',
};
