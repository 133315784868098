export enum IndicatorType {
  n = 'n',
  p = 'p',
  k = 'k',
  s = 's',
  ph = 'ph',
  humus='humus',
  salinization = 'salinization',
  ca = 'ca',
  mg = 'mg',
  fe = 'fe',
  mn = 'mn',
  zn = 'zn',
  cu = 'cu',
  b = 'b',
  mo = 'mo',
  na = 'na',
  cl = 'cl',
  co = 'co',
  soilCompaction = 'soilCompaction'
}

export enum TypeIndicator {
    base = 'base',
    additional = 'additional'
}

export type OptionsIndicatorType = {
  key: IndicatorType,
  title: string,
  suffix: string,
  type: TypeIndicator,
  isDefinitionForm?: boolean,
  sizeExcel: number,
};

const optionsIndicators:OptionsIndicatorType[] = [{
  key: IndicatorType.n,
  title: 'Азот (N)',
  suffix: 'мг/кг',
  type: TypeIndicator.base,
  isDefinitionForm: true,
  sizeExcel: 13,
}, {
  key: IndicatorType.p,
  title: 'Фосфор (Р)',
  suffix: 'мг/кг',
  type: TypeIndicator.base,
  isDefinitionForm: true,
  sizeExcel: 13,
}, {
  key: IndicatorType.k,
  title: 'Калий (К)',
  suffix: 'мг/кг',
  type: TypeIndicator.base,
  isDefinitionForm: true,
  sizeExcel: 13,
}, {
  key: IndicatorType.s,
  title: 'Сера (S)',
  suffix: 'мг/кг',
  type: TypeIndicator.base,
  sizeExcel: 13,
},
{
  key: IndicatorType.ph,
  title: 'рН',
  suffix: '',
  type: TypeIndicator.base,
  sizeExcel: 10,
},
{
  key: IndicatorType.humus,
  title: 'Гумус',
  suffix: '%',
  type: TypeIndicator.base,
  sizeExcel: 10,
},
{
  key: IndicatorType.salinization,
  title: 'Засоление',
  suffix: 'мг/кг',
  type: TypeIndicator.base,
  sizeExcel: 13,
},
{
  key: IndicatorType.ca,
  title: 'Кальций (Ca)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.mg,
  title: 'Магний (Mg)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 15,
},
{
  key: IndicatorType.fe,
  title: 'Железо (Fe)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 15,
},
{
  key: IndicatorType.mn,
  title: 'Марганец (Mn)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 15,
},
{
  key: IndicatorType.zn,
  title: 'Цинк (Zn)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.cu,
  title: 'Медь (Cu)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.b,
  title: 'Бор (B)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.mo,
  title: 'Молибден (Mo)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
}, {
  key: IndicatorType.na,
  title: 'Натрий (Na)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.cl,
  title: 'Хлор (Cl)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
}, {
  key: IndicatorType.co,
  title: 'Кобальт (Co)',
  suffix: 'мг/кг',
  type: TypeIndicator.additional,
  sizeExcel: 13,
},
{
  key: IndicatorType.soilCompaction,
  title: 'Уплотненность почвы',
  suffix: 'г/см³',
  type: TypeIndicator.additional,
  sizeExcel: 17,
},

];

export default optionsIndicators;
