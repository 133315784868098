import { Draw } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { LineString, Point } from 'ol/geom';
import { Feature } from 'ol';
import { Stroke, Style } from 'ol/style';
import Notify from 'quasar/src/plugins/Notify.js';;
import Map from '@/modules/map/data/Map';
import { computed, Ref, UnwrapRef } from 'vue';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import useErrors from '@/dialogs/AssayDialog/hooks/useErrors';
import usePositionFunctionsFeature from '@/dialogs/AssayDialog/hooks/usePositionFunctionsFeature';
import AssayType from '@/dialogs/AssayDialog/types/AssayType';
import booleanIntersects from '@turf/boolean-intersects';
import useDrawAutoHelpers from '@/dialogs/AssayDialog/hooks/useDrawAutoHelpers';

export default function useDrawHand(
  map: Map,
  positionsPolygons: Ref<UnwrapRef<{polygon: Feature, point: Feature<Point> }[][]>>,
  polygons: Ref<UnwrapRef<Feature[]>>,
  points: Ref<UnwrapRef<Feature<Point>[]>>,
  activeGridType: Ref<UnwrapRef<AssayType>>,
  startNumberPolygon: Ref<UnwrapRef<number>>,
) {
  const {
    getPointByPolygon, getPolygonByPoint,
    getPositionsFeature,
    setLinePoint, setNextPoint, getCoordinateFeature, setBackPoint,
    getIsRemove, getDataFeature, getGeoJsonFeature,
  } = useAdditionalFunctionsFeature();

  const { isEdgePolygon, getPoint } = usePositionFunctionsFeature(positionsPolygons);

  const { errorSelectPolygonPoint } = useErrors();

  const { isActivePolygon } = useDrawAutoHelpers();

  let doubleClick = false;
  let indexPoint = -1;

  let selectedPoints: Feature<Point>[] = [];

  let listLines: Feature<LineString>[] = [];

  const removeLastLinerFeature = () => {
    const lineRemove = listLines.pop();
    map.lineSource.removeFeature(lineRemove);
  };

  const dblclickFunction = () => {
    doubleClick = true;
    // eslint-disable-next-line no-use-before-define
    drawHandInteraction.finishDrawing();
    map.map.un('dblclick', dblclickFunction);
    map.pointSource.clear();
    map.pointSource.addFeatures(points.value as Feature<Point>[]);
  };

  const isDiagonalPoint = (
    itemY: number,
    itemX: number,
    y: number,
    x: number,
  ) => (itemY === y + 1 && itemX === x + 1) || (itemY === y + 1 && itemX === x - 1)
      || (itemY === y - 1 && itemX === x - 1) || (itemY === y - 1 && itemX === x + 1);

  const getHandActivePoints = (
    y: number,
    x: number,
  ): Feature<Point>[] => (points.value as Feature<Point>[])
    .filter((item) => {
      const [itemY, itemX] = getPositionsFeature(item as Feature<Point>);
      return ((itemY === y || x === itemX || isDiagonalPoint(itemY, itemX, y, x))
          && getDataFeature(item).index == null);
    });

  let activePoints: Feature<Point>[] = null;

  const findPolygonByPoint = ([x, y]: number[]) => polygons.value
    .find((item) => item.getGeometry().containsXY(x, y));

  const updateLines = () => {
    setTimeout(() => {
      map.lineSource.clear();
      map.lineSource.addFeatures(listLines);
    }, 200);
  };

  const checkAndFinishDrawingStatus = () => {
    const isFinishDraw = !map.staticSource.getFeatures()
      .some((elementaryArea) => !getIsRemove(getPointByPolygon(elementaryArea))
        && getDataFeature(elementaryArea).index == null);
    if (isFinishDraw) {
      // eslint-disable-next-line no-use-before-define
      drawHandInteraction.finishDrawing();
      updateLines();
    }
  };

  const drawHandGost = (coordinates: Coordinate[], geom: LineString) => {
    if (!geom) {
      doubleClick = false;
      map.map.on('dblclick', dblclickFunction);
      if (!map.lineSource.getFeatures().length) {
        listLines = [];
      }
      const firstPolygon = findPolygonByPoint(coordinates[0] as number[]) as Feature;
      if (firstPolygon && (isEdgePolygon(firstPolygon) || selectedPoints.length)
        && !getIsRemove(getPointByPolygon(firstPolygon))
        && getDataFeature(firstPolygon).index == null) {
        const startPoint = getPointByPolygon(firstPolygon) as Feature<Point>;

        const newCoordinate = startPoint
          .getGeometry()
          .getCoordinates();

        const startPositions = getPositionsFeature(firstPolygon);

        const newLineString = new LineString([newCoordinate, coordinates[0]]);
        newLineString.setProperties({ startPositions });

        const [y, x] = startPositions;

        if (indexPoint >= 0) {
          const backPoint = selectedPoints[indexPoint];
          const newLine = new Feature({
            geometry: new LineString([
              getCoordinateFeature(backPoint as Feature), newCoordinate]),
          });

          if (!booleanIntersects(
            getGeoJsonFeature(getPolygonByPoint(startPoint)),
            getGeoJsonFeature(getPolygonByPoint(backPoint)),
          )) {
            newLine.setStyle(new Style({
              stroke: new Stroke({
                color: [0, 0, 0, 0.6],
                width: 2,
                lineDash: [4, 8],
              }),
            }));
          }

          setLinePoint(backPoint, newLine);
          map.lineSource.addFeature(newLine);
          listLines.push(newLine);
          setNextPoint(backPoint, startPoint);
          setBackPoint(startPoint, backPoint);
        }
        indexPoint += 1;
        const dataFeature = getDataFeature(startPoint);
        dataFeature.index = indexPoint;
        if (dataFeature.num == null) {
          dataFeature.num = Number(startNumberPolygon.value) + indexPoint;
        }
        selectedPoints.push(startPoint);

        activePoints = getHandActivePoints(y, x);
        map.pointSource.clear();
        map.pointSource.addFeatures([...selectedPoints, ...activePoints]);
        checkAndFinishDrawingStatus();
        // eslint-disable-next-line no-param-reassign
        coordinates[0] = newCoordinate;
        return newLineString;
      }
      errorSelectPolygonPoint();

      // eslint-disable-next-line no-use-before-define
      drawHandInteraction.abortDrawing();
      return new LineString([]);
    }

    if (coordinates.length === 2) {
      geom.setCoordinates(coordinates);
    } else if (coordinates.length >= 3) {
      const endPolygon = findPolygonByPoint(coordinates[coordinates.length - 1] as number[]);
      const startPoint = selectedPoints[selectedPoints.length - 1];
      const endPoint = getPointByPolygon(endPolygon as Feature);
      if (endPolygon && startPoint !== endPoint) {
        const [startY, startX] = getPositionsFeature(startPoint);
        const [endY, endX] = getPositionsFeature(endPoint);

        const sizeY = startY - endY;
        const sizeX = startX - endX;

        let selectedPointsLine = [];

        if ((sizeY === -1 && sizeX === -1)
          || (sizeY === -1 && sizeX === 1)
          || (sizeY === 1 && sizeX === -1)
          || (sizeY === 1 && sizeX === 1)) {
          selectedPointsLine.push(getPoint(startY, startX));
          selectedPointsLine.push(getPoint(endY, endX));
        } else if (sizeY !== 0) {
          if (sizeY > 0) { // top
            for (let y = startY; y >= endY; y -= 1) {
              selectedPointsLine.push(getPoint(y, startX));
            }
          } else { // bottom
            for (let y = startY; y <= endY; y += 1) {
              selectedPointsLine.push(getPoint(y, startX));
            }
          }
        } else if (sizeX > 0) { // left
          for (let x = startX; x >= endX; x -= 1) {
            selectedPointsLine.push(getPoint(startY, x));
          }
        } else { // right
          for (let x = startX; x <= endX; x += 1) {
            selectedPointsLine.push(getPoint(startY, x));
          }
        }
        selectedPointsLine = selectedPointsLine.filter((item) => !!item);
        const newPointsLine = [...selectedPointsLine];
        newPointsLine.splice(0, 1);

        const isLineCrossing = newPointsLine
          .some((item) => getDataFeature(item).index != null);

        if (isLineCrossing) {
          Notify.create({
            type: 'negative',
            message: 'Одна из точек линии уже была выбрана!',
          });
          // eslint-disable-next-line no-use-before-define
          drawHandInteraction.removeLastPoint();
          return geom;
        }

        const listSelectedPointLines = selectedPointsLine.reduce<Feature<LineString>[]>((
          listLines,
          point,
          index,
          positionsArray,
        ) => {
          if ((index - 1) >= 0) {
            const backPoint = positionsArray[index - 1];
            indexPoint += 1;
            const dataFeature = getDataFeature(point);
            dataFeature.index = indexPoint;
            if (dataFeature.num == null) {
              dataFeature.num = Number(startNumberPolygon.value) + indexPoint;
            }
            setBackPoint(point, backPoint);
            setNextPoint(backPoint, point);
            const line = new Feature(
              {
                geometry: new LineString([
                  getCoordinateFeature(backPoint), getCoordinateFeature(point)]),
              },
            );

            if (!booleanIntersects(
              getGeoJsonFeature(getPolygonByPoint(point)),
              getGeoJsonFeature(getPolygonByPoint(backPoint)),
            )) {
              line.setStyle(new Style({
                stroke: new Stroke({
                  color: [0, 0, 0, 0.6],
                  width: 2,
                  lineDash: [4, 8],
                }),
              }));
            }
            setLinePoint(backPoint, line);
            listLines.push(line);
          }

          return listLines;
        }, []);
        listLines.push(...listSelectedPointLines);
        map.lineSource.addFeatures(listSelectedPointLines);
        selectedPoints.push(...newPointsLine);

        const [y, x] = getPositionsFeature(endPoint);
        map.pointSource.clear();
        activePoints = getHandActivePoints(y, x);
        map.pointSource.addFeatures([...activePoints, ...selectedPoints]);
        checkAndFinishDrawingStatus();
        // eslint-disable-next-line no-param-reassign
        coordinates[0] = selectedPoints[selectedPoints.length - 1].getGeometry().getCoordinates();
        // eslint-disable-next-line no-use-before-define
        drawHandInteraction.removeLastPoint();
      } else {
        // eslint-disable-next-line no-use-before-define
        drawHandInteraction.removeLastPoint();
        setTimeout(() => {
          if (!doubleClick) {
            errorSelectPolygonPoint();
          } else {
            updateLines();
          }
        }, 50);
      }
    }

    return geom;
  };

  const drawHandBionic = (coordinates: Coordinate[], geom: LineString) => {
    if (!geom) {
      doubleClick = false;
      map.map.on('dblclick', dblclickFunction);
      if (!map.lineSource.getFeatures().length) {
        listLines = [];
      }
      const firstPolygon = findPolygonByPoint(coordinates[0] as number[]) as Feature;
      if (firstPolygon && isActivePolygon(map, firstPolygon)) {
        const startPoint = getPointByPolygon(firstPolygon) as Feature<Point>;

        const newCoordinate = startPoint
          .getGeometry()
          .getCoordinates();

        const newLineString = new LineString([newCoordinate, coordinates[0]]);
        newLineString.setProperties({ polygon: firstPolygon });

        if (indexPoint >= 0) {
          const backPoint = selectedPoints[indexPoint];
          const newLine = new Feature({
            geometry: new LineString([
              getCoordinateFeature(backPoint as Feature), newCoordinate]),
          });

          setLinePoint(backPoint, newLine);
          map.lineSource.addFeature(newLine);
          listLines.push(newLine);

          setNextPoint(backPoint, startPoint);
          setBackPoint(startPoint, backPoint);
        }
        indexPoint += 1;
        const dataFeature = getDataFeature(startPoint);
        dataFeature.index = indexPoint;
        if (dataFeature.num == null) {
          dataFeature.num = Number(startNumberPolygon.value) + indexPoint;
        }
        selectedPoints.push(startPoint);

        activePoints = map.staticSource.getFeatures()
          .filter((elementaryAreaFeature) => !getIsRemove(elementaryAreaFeature)
            && getDataFeature(elementaryAreaFeature).index == null
            && !!booleanIntersects(
              getGeoJsonFeature(elementaryAreaFeature),
              getGeoJsonFeature(firstPolygon),
            ))
          .map((elementaryAreaFeature) => getPointByPolygon(elementaryAreaFeature));

        map.pointSource.clear();
        map.pointSource.addFeatures([...activePoints, ...selectedPoints]);
        checkAndFinishDrawingStatus();
        // eslint-disable-next-line no-param-reassign
        coordinates[0] = newCoordinate;

        return newLineString;
      }
      errorSelectPolygonPoint();

      // eslint-disable-next-line no-use-before-define
      drawHandInteraction.abortDrawing();
      return new LineString([]);
    } if (coordinates.length === 2) {
      geom.setCoordinates(coordinates);
    } else if (coordinates.length >= 3) {
      const endPolygon = findPolygonByPoint(
        coordinates[coordinates.length - 1] as number[],
      ) as Feature;
      if (endPolygon && getDataFeature(getPointByPolygon(endPolygon)).index != null) {
        // eslint-disable-next-line no-use-before-define
        drawHandInteraction.removeLastPoint();
        updateLines();
        return geom;
      }
      if (endPolygon && isActivePolygon(map, endPolygon)) {
        const startPoint = selectedPoints[selectedPoints.length - 1];
        const endPoint = getPointByPolygon(endPolygon);
        selectedPoints.push(endPoint);
        indexPoint += 1;
        const dataFeature = getDataFeature(endPoint);
        dataFeature.index = indexPoint;
        if (dataFeature.num == null) {
          dataFeature.num = Number(startNumberPolygon.value) + indexPoint;
        }

        setBackPoint(endPoint, startPoint);
        setNextPoint(startPoint, endPoint);
        const line = new Feature(
          {
            geometry: new LineString([
              getCoordinateFeature(startPoint), getCoordinateFeature(endPoint)]),
          },
        );
        setLinePoint(startPoint, line);
        listLines.push(line);
        map.lineSource.addFeature(line);

        map.pointSource.clear();
        activePoints = map.staticSource.getFeatures()
          .filter((elementaryAreaFeature) => !getIsRemove(elementaryAreaFeature)
            && getDataFeature(elementaryAreaFeature).index == null
            && !!booleanIntersects(
              getGeoJsonFeature(elementaryAreaFeature),
              getGeoJsonFeature(endPolygon),
            ))
          .map((elementaryAreaFeature) => getPointByPolygon(elementaryAreaFeature));

        map.pointSource.addFeatures([...activePoints, ...selectedPoints]);
        checkAndFinishDrawingStatus();
        // eslint-disable-next-line no-param-reassign
        coordinates[0] = endPoint.getGeometry().getCoordinates();
        // eslint-disable-next-line no-use-before-define
        drawHandInteraction.removeLastPoint();
      } else {
        // eslint-disable-next-line no-use-before-define
        drawHandInteraction.removeLastPoint();
        setTimeout(() => {
          if (!doubleClick) {
            errorSelectPolygonPoint();
          }
        }, 50);
      }
    }
    return geom;
  };

  const drawHandFunction = computed(() => (activeGridType.value === AssayType.Gost
    ? drawHandGost : drawHandBionic));

  const drawHandInteraction = new Draw({
    source: map.lineSource,
    type: 'LineString',
    // @ts-ignore
    geometryFunction: drawHandFunction.value,

  });

  const finishDrawHand = () => {
    drawHandInteraction.finishDrawing();
    doubleClick = false;
    indexPoint = -1;
    selectedPoints = [];
  };

  const removeLastPoint = () => {
    const lastSelectedPoint = selectedPoints.pop();
    getDataFeature(lastSelectedPoint).index = null;
    indexPoint -= 1;
  };

  const rollBackHand = () => {
    drawHandInteraction.finishDrawing();
    updateLines();
    map.pointSource.clear();
    map.pointSource.addFeatures(points.value as Feature<Point>[]);
    if (selectedPoints.length === map.lineSource.getFeatures().length) {
      removeLastLinerFeature();
    }
    removeLastLinerFeature();
    setNextPoint(selectedPoints[selectedPoints.length - 2], null);
    removeLastPoint();

    if (selectedPoints.length === 1) {
      removeLastPoint();
    }
  };

  return { drawHandInteraction, finishDrawHand, rollBackHand };
}
