import { catchError, finalize, tap } from 'rxjs/operators';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { forkJoin, throwError } from 'rxjs';
import { useAbility } from '@casl/vue';
import fieldApiService from '@/api/fieldApiService';
import MeteoFeature from '@/modules/map/features/MeteoFeature';
import FieldFeature from '@/modules/map/features/FieldFeature';
import { FieldMap } from '@/modules/field/types/Field';
import meteoApiService from '@/api/meteoApiService';
import { MeteoMap } from '@/models/Meteo';
import useManagerMap from '@/modules/map/hooks/useManagerMap';
import routeNames from '@/router/routeNames';
import { useMapStore, useFieldStore } from '@/stores';
import userApiService from '@/api/userApiService';
import scoutingApiService from '@/api/scoutingApiService';
import elementaryAreaSchemeApiService from '@/api/elementaryAreaSchemeApiService';
import stagesApiService from '@/api/stagesApiService';

export default function useUploadMeteoField() {
  const {
    pointSource, source, clearSelected, fit, getView,
  } = useManagerMap();
  const { can } = useAbility();
  const $route = useRoute();
  const mapStore = useMapStore();
  const fieldStore = useFieldStore();
  const fieldsLoading = ref(false);

  const meteoLoading = ref(false);

  const getMeteoStations = () => {
    meteoLoading.value = true;
    return meteoApiService.get()
      .pipe(
        catchError((err) => {
          throw err;
        }),
        finalize(() => {
          meteoLoading.value = false;
        }),
      );
  };

  const getFields = (organizationId?: string | string[]) => {
    fieldsLoading.value = true;

    forkJoin([scoutingApiService.get(), elementaryAreaSchemeApiService.getAllAreaSchemes(),
      userApiService.get()])
      .subscribe(([scoutingTacks, elementaryAreaSchemes]) => {
        fieldStore.setScoutingTasks(scoutingTacks);
        fieldStore.setElementaryAreasSchemes(elementaryAreaSchemes);
      });

    return fieldApiService.getFieldsPWA({
      params: {
        organizationId,
      },
    })
      .pipe(
        catchError((err) => {
          throw err;
        }),
        tap((fields) => {
          const specieIds = Object.values(fields.reduce((list, field) => {
            if (field.activeCycle) {
              // eslint-disable-next-line no-param-reassign
              list[field.activeCycle.specieId] = field.activeCycle.specieId;
            }
            return list;
          }, []));

          stagesApiService.getStages(specieIds).subscribe((species) => {
            fieldStore.setSpecies(species);
          });
        }),
        finalize(() => {
          fieldsLoading.value = false;
        }),
      );
  };

  const setMeteos = (meteos: MeteoMap[]) => {
    const newMeteos = meteos.map((meteo) => new MeteoFeature(meteo));
    if (newMeteos.length) {
      pointSource.addFeatures(newMeteos);
      mapStore.setMeteos(newMeteos);
    }
  };

  const setFields = (fields: FieldMap[]) => {
    const newFields = fields.map((el) => new FieldFeature(el));
    if (newFields.length) {
      source.addFeatures(newFields);
      mapStore.setFields(newFields);
    }
  };

  onMounted(() => {
    if (mapStore.fields.length) {
      return;
    }

    clearSelected();
    source.clear();
    pointSource.clear();

    const organizationId = $route.query?.organizationId;

    const listRouteNameFet = [routeNames.fields, routeNames.meteos];

    if (can('show', 'fieldAndMeteoData')) {
      forkJoin([getMeteoStations(), getFields()])
        .subscribe((data: any[]) => {
          setMeteos(data[0] || []);
          setFields(data[1] || []);
          if (listRouteNameFet.includes($route.name.toString()) && source.getFeatures().length) {
            getView().fit(source.getExtent(), {
              padding: [15, 15, 15, 15],
            });
          }
        }, (err) => throwError(err));
    } else {
      getFields(organizationId)
        .subscribe((data: FieldMap[]) => {
          setFields(data || []);

          setTimeout(() => {
            if (source.getFeatures().length) {
              fit(source.getExtent());
            }
          }, 200);
        });
    }
  });

  return { fieldsLoading, meteoLoading };
}
