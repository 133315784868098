import { ref } from 'vue';
import { CropStructure } from '@/modules/species-structure/types/CropStructure';
import { Specie } from '@/modules/species-structure/species-table/types/SpeciesData';
import { useUserStore } from '@/stores';

export default function useSpeciesTable() {
  const userStore = useUserStore();

  const speciesData = ref([]);

  const columns: Partial<any>[] = [
    {
      label: 'Наименование хозяйства',
      name: 'organization',
      align: 'center',
      sortable: false,
      headerStyle: 'background-color: #bdbebd',
    },
    {
      label: 'Культура',
      name: 'specie',
      align: 'center',
      sortable: false,
      headerStyle: 'background-color: #bdbebd',
    },
    {
      label: 'Площадь, га',
      name: 'sumArea',
      align: 'center',
      sortable: false,
      headerStyle: 'background-color: #bdbebd',
    },
  ];

  const formData = (data: CropStructure[]) => {
    const graph = data.reduce<{
      [key: number]: {
        title: string,
        species: { specieTitle: string, area: number }[]
      }
    }>((list, item) => {
      if (!(item.organizationId in list)) {
        // eslint-disable-next-line no-param-reassign
        list[item.organizationId] = {
          title: item.organization,
          species: [],
        };
      }

      const organization = list[item.organizationId];

      if (!(item.specieId in organization.species)) {
        organization.species[item.specieId] = { specieTitle: item.specie, area: 0 };
      }

      organization.species[item.specieId].area += item.sumArea;
      return list;
    }, {});

    const listWithoutKeys = Object.values(graph)
      .map((org) => ({ ...org, species: Object.values(org.species) }));

    return listWithoutKeys;
  };

  const calcAreaForOrg = (species: Specie[]) => {
    let speciesArea = 0;
    species.forEach((specie: Specie) => {
      speciesArea += specie.area;
    });

    return speciesArea;
  };

  return {
    formData,
    calcAreaForOrg,
    columns,
    speciesData,
    user: userStore.user,
  };
}
