import ChangeNumberDialog from '@/dialogs/ChangeNumberDialog/ChangeNumberDialog.vue';
import useHelpers from '@/dialogs/AssayDialog/components/maps/CreateRouteMap/composables/useHelpers';
import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import useHoverElementaryArea from '@/dialogs/AssayDialog/components/maps/CreateRouteMap/composables/useHoverElementaryArea';
import { onUnmounted } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default function useChangeNumberElementaryArea(map: Map) {
  const { onHoverPolygon, offHoverPolygon } = useHoverElementaryArea(map);
  const { getElementaryAreaOnMapByClick } = useHelpers();
  const { getDataFeature } = useAdditionalFunctionsFeature();
  const q = useQuasar();

  const changeNumberEvent = (e) => {
    const clickFeature = getElementaryAreaOnMapByClick(e, map);
    if (clickFeature) {
      q.dialog({
        component: ChangeNumberDialog,
        componentProps: { numberPoint: getDataFeature(clickFeature).num },
      }).onOk((number) => {
        getDataFeature(clickFeature).num = number;
        map.pointSource.dispatchEvent('change');
      });
    }
  };

  const onChangeNumberElementaryArea = () => {
    onHoverPolygon();
    map.map.on('click', changeNumberEvent);
  };
  const offChangeNumberElementaryArea = () => {
    map.map.un('click', changeNumberEvent);
    offHoverPolygon();
  };

  onUnmounted(() => {
    map.map.un('click', changeNumberEvent);
  });

  return { onChangeNumberElementaryArea, offChangeNumberElementaryArea };
}
