import { SatelliteLayer, SatellitePresentation } from '@/models/SatelliteType';

export const layerTypes: {value: string, text: string}[] = [
  {
    value: null,
    text: 'Не показывать',
  },
  {
    value: SatelliteLayer.TRUE_COLOR,
    text: ' Натуральный цвет',
  },
  {
    value: SatelliteLayer.COLOR_INFRARED,
    text: 'Инверсный ИК',
  },
  {
    value: SatelliteLayer.NDVI,
    text: 'NDVI',
  },
  {
    value: SatelliteLayer.EVI,
    text: 'EVI',
  },
  {
    value: SatelliteLayer.NDWI,
    text: 'NDWI',
  },
  {
    value: SatelliteLayer.LAI,
    text: 'LAI',
  },
  {
    value: SatelliteLayer.NDRE,
    text: 'NDRE',
  },
  {
    value: SatelliteLayer.GNDVI,
    text: 'GNDVI',
  },
  {
    value: SatelliteLayer.ARVI,
    text: 'ARVI',
  },
  {
    value: SatelliteLayer.REPO,
    text: 'REPO',
  },
  {
    value: SatelliteLayer.RVI,
    text: 'RVI',
  },
];

export const presentationTypes:{value: string, text: string}[] = [
  {
    value: '',
    text: 'Индекс',
  },
  {
    value: SatellitePresentation.DISP,
    text: 'Однородность',
  },
  {
    value: SatellitePresentation.DYN,
    text: 'Динамика',
  },
];
