import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import { MeteoLastMeasurement } from '@/models/Meteo';

class MeteoChartApiService extends BaseCrud<MeteoLastMeasurement> {
  protected ENDPOINT = 'meteo_data';

  getMeteoData(id: number, options?: AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT}/${id}`, options);
  }
}

const meteoChartApiService = new MeteoChartApiService();

export default meteoChartApiService;
