import { Point } from 'ol/geom';

import {
  Style, Icon,
} from 'ol/style';
import Feature from 'ol/Feature';

export default class ContextPointFeature extends Feature<Point> {
  constructor() {
    super();
    this.setDefaultStyle();
    this.set('unselectable', true);
  }

  setGeometryFeature(coordinates: number[]) {
    this.setGeometry(new Point(coordinates));
  }

  setDefaultStyle() {
    this.setStyle([
      new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          scale: 0.6,
          crossOrigin: 'anonymous',
          src: '/images/icons/cross.png',
        }),
        zIndex: 2000,
      }),
    ]);
  }
}
