import script from "./Context.vue?vue&type=script&lang=ts&setup=true"
export * from "./Context.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QItem,QItemSection,QIcon});qInstall(script, 'directives', {Ripple});
