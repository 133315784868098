import { AxiosRequestConfig } from 'axios';
import CrudService from '@/api/configs/crud.service';
import { Meteo } from '@/models/Meteo';

class MeteoApiService extends CrudService<Partial<Meteo>, Meteo> {
  protected ENDPOINT = 'meteos';

  protected normalizeData(data:Meteo) {
    return data;
  }

  getMeteoData(id: number, options?: AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT}/${id}`, options);
  }
}

const meteoApiService = new MeteoApiService();

export default meteoApiService;
