import '@/plugins/highcharts';
import Highcharts from 'highcharts';

export default function useSaveGraphics() {
  function clickSaveGraphics(refs: any, type: string, fileName: string) {
    const listCharts = refs.$refs ? Object.values(refs.$refs)
      .filter((item) => Object.hasOwnProperty.call(item, 'chart'))
      // @ts-ignore
      .map((item) => item.chart) : refs.filter((item) => Object.hasOwnProperty.call(item, 'chart')).map((item) => item.chart);

    if (listCharts.length) {
      // @ts-ignore
      Highcharts.exportCharts(listCharts, { type, filename: fileName });
    }
  }

  function formatDate(date: string) {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  return {
    clickSaveGraphics,
    formatDate,
  };
}
