export enum AttributeType {
    string= 'string',
    text = 'text',
    flag = 'flag',
    list = 'list'
}

export type OperationAttribute = {
    id?: number,
    organizationId?: number,
    operationTypeId: number,
    alias?: string,
    title: string,
    type: AttributeType,
    isMandatory?: boolean,
    values: any
};

export type OperationType = {
    id: number,
    operationUnitId: number,
    title: string,
    unitTitle: string,
    description?: string,
    operationAttributes: OperationAttribute[]
};

export type Operation = {
    amount: number,
    eventDt: string,
    fieldId: number,
    id?: number,
    operationTypeId: number,
    title: string,
    type?: OperationType,
    unitTitle: string,
    attrs: {[ket: string]: any}
};
