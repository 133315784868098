import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { GeometryObject } from '@turf/helpers/dist/js/lib/geojson';
import ElementType from '@/modules/map/contracts/ElemetType';

export default abstract class FeatureRoot<T = any,
  GeomType extends Geometry = Geometry> extends Feature<GeomType> {
  abstract type: ElementType;

  props: T;

  abstract setGeometryFeature(geometry: GeometryObject);

  abstract setDefaultStyle();

  abstract setSelectStyle();

  setProps(props: T) {
    this.props = props;
  }
}
