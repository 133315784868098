import AnalysesData, { AnalysesTableTypes } from '@/dialogs/AssayDialog/types/AnalysesData';
import {
  Cell, Row, Workbook, Worksheet,
} from 'exceljs';
import { saveExcelFile, updateWidthColumns } from '@/helpers/excel';
import {
  kTypes, nTypes, pTypes, salinizationTypes,
} from '@/dialogs/AssayDialog/constants/optionsTypes';
import { Laboratory } from '@/dialogs/AssayDialog/types/Laboratory';
import moment from 'moment';
import axios from 'axios';
import optionsIndicators, { TypeIndicator } from '@/dialogs/AssayDialog/constants/optionsIndicators';

export default function useExcel() {
  const setBackgroundCell = (cell: Cell, color: string) => {
    // eslint-disable-next-line no-param-reassign
    cell.fill = {
      type: 'pattern',
      pattern: 'darkVertical',
      fgColor: {
        argb: color,
      },
    };
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  };

  const formatDate = (date: string, format: string, returnFormat: string) => {
    const momentDate = moment(date, format);
    if (momentDate.isValid()) {
      return momentDate.format(returnFormat);
    }
    return '';
  };

  const getRuFormat = (date: string) => formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY');

  const getNormalFormat = (date: string) => formatDate(date, 'DD.MM.YYYY', 'YYYY-MM-DD');

  const setBackgroundColorRow = (startCell: number, length: number, color: string) => {
    const endCell = startCell + length;
    if (!length) {
      return () => {};
    }
    return (row: Row) => {
      for (let i = startCell; i < endCell; i += 1) {
        setBackgroundCell(row.getCell(i), color);
      }
    };
  };

  const getBaseAdditionalIndicators = (laboratory: Laboratory) => {
    const baseIndicators = optionsIndicators
      .filter(({ type, key }) => type === TypeIndicator.base
        && (laboratory ? laboratory.typesAnalyses[key] : true));

    const additionalIndicators = optionsIndicators
      .filter(({ type, key }) => type === TypeIndicator.additional
        && (laboratory ? laboratory.typesAnalyses[key] : true));

    if (laboratory ? laboratory.typesAnalyses.salinization : true) {
      additionalIndicators.unshift({
        // @ts-ignore
        key: 'salinizationType',
        title: 'Тип засоления',
        type: TypeIndicator.additional,
        suffix: '',
        sizeExcel: 17,
      });
    }
    return { baseIndicators, additionalIndicators };
  };

  const addAnalysisData = (
    worksheet: Worksheet,
    attributes: AnalysesTableTypes,
    laboratory: Laboratory,
  ) => {
    const { additionalIndicators, baseIndicators } = getBaseAdditionalIndicators(laboratory);

    const activeIndicators = [...baseIndicators, ...additionalIndicators];

    const arrayLengthText = [13, 15, ...activeIndicators.map(({ sizeExcel }) => sizeExcel)];

    const headerRow2 = worksheet.addRow(['', '',
      ...(baseIndicators.length ? ['Базовые атрибуты', ...(new Array(baseIndicators.length - 1).map(() => ''))] : []),
      ...(additionalIndicators.length ? ['Дополнительные атрибуты', ...(new Array(additionalIndicators.length - 1).map(() => ''))] : []),
    ]);

    const setBaseColor = setBackgroundColorRow(3, baseIndicators.length, 'e2f0d9');
    const setAdditionalColor = setBackgroundColorRow(3 + baseIndicators.length, additionalIndicators.length, 'fff2cc');
    const setBackgroundColor = (row: Row) => {
      setBaseColor(row);
      setAdditionalColor(row);
    };

    setBaseColor(headerRow2);
    setAdditionalColor(headerRow2);
    const rowMerge = laboratory ? 10 : 2;

    baseIndicators.length && worksheet.mergeCells(
      rowMerge,
      3,
      rowMerge,
      3 + baseIndicators.length - 1,
    );
    additionalIndicators.length && worksheet.mergeCells(
      rowMerge,
      3 + baseIndicators.length,
      rowMerge,
      3 + baseIndicators.length - 1 + additionalIndicators.length,
    );

    headerRow2.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    headerRow2.font = {
      bold: true,
    };

    const headerRow3 = worksheet.addRow(['№ ЭУ \nп/п', 'Номер \n образца', ...activeIndicators.map(({ title, suffix }) => `${title} \n${suffix}`)]);
    setBackgroundColor(headerRow3);
    headerRow3.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    headerRow3.font = {
      bold: true,
    };
    headerRow3.height = 40;

    const headerRow4 = worksheet.addRow(['Форма \nопред.', '', ...activeIndicators.map(({ isDefinitionForm, key }) => (isDefinitionForm ? attributes.types[key] : ''))]);
    setBackgroundColor(headerRow4);
    headerRow4.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    headerRow4.font = {
      bold: true,
    };
    headerRow4.height = 40;

    const optionsTypesIndicator = {
      n: nTypes,
      p: pTypes,
      k: kTypes,
    };
    activeIndicators.forEach(({ key, isDefinitionForm }, index) => {
      if (isDefinitionForm) {
        headerRow4.getCell(3 + index).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [`"${optionsTypesIndicator[key].join(',')}"`],
        };
      }
    });

    attributes.data.forEach(({
      sampleNumber,
      number, ...indicatorsData
    }) => {
      const dataRow = worksheet.addRow([
        number,
        sampleNumber,
        ...activeIndicators.map(({ key }) => indicatorsData[key])]);
      dataRow.alignment = { wrapText: true, shrinkToFit: true };
      if (laboratory === null || laboratory?.typesAnalyses?.salinization) {
        dataRow.getCell(3 + baseIndicators.length).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [`"${salinizationTypes.join(',')}"`],
        };
      }

      setBackgroundColor(dataRow);
    });
    updateWidthColumns(worksheet, arrayLengthText, 0);
  };

  const exportExcelFileAnalysisForLaboratory = async (
    nameField: string,
    attributes: AnalysesTableTypes,
    laboratory: Laboratory,
  ) => {
    const workbook = new Workbook();
    workbook.creator = 'Агрохимические показатели и методики';

    workbook.lastModifiedBy = 'Форма выгрузки-загрузки';
    const worksheet = workbook.addWorksheet('Форма выгрузки-загрузки', {
      views: [
        { state: 'frozen', ySplit: 2 },
      ],
    });
    const response = await axios.get(laboratory.headerImg, {
      responseType: 'arraybuffer',
    });
    if (response.status === 200) {
      const imageId = workbook.addImage({
        buffer: response.data,
        extension: 'png',
      });

      const imageItem = worksheet.addRow([]);
      worksheet.mergeCells('A1:H1');
      imageItem.height = 70;

      const settingsImage = {
        tl: { col: 0, row: 0 },
        br: { col: 9, row: 1 },
        editAs: 'absolute',
      };
      // @ts-ignore
      worksheet.addImage(imageId, settingsImage);
    }

    const protocolRow = worksheet.addRow(['ПРОТОКОЛ ИСПЫТАНИЙ №', '', '', '', 'от', '']);
    protocolRow.getCell('A').alignment = { horizontal: 'right' };
    protocolRow.font = {
      bold: true,
      size: 14,
    };
    protocolRow.getCell('D').value = laboratory.protocolNumber;
    protocolRow.getCell('E').value = 'от';
    protocolRow.getCell('E').alignment = { horizontal: 'center' };
    protocolRow.getCell('F').value = getRuFormat(laboratory.protocolDate);
    protocolRow.getCell('F').numFmt = 'dd.mm.yyyy';
    protocolRow.getCell('F').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A2', 'C2');
    worksheet.mergeCells('F2', 'G2');

    const nameAddressRow = worksheet.addRow(['Наименование и адрес заказчика:', '', '', 'ООО «КлеверFarmer»']);
    nameAddressRow.getCell('A').alignment = { horizontal: 'right' };
    nameAddressRow.getCell('D').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A3:C3');
    worksheet.mergeCells('D3:I3');

    const nameFarmRow = worksheet.addRow(['Наименование хозяйства:', '', '', laboratory.nameFarm || '']);
    nameFarmRow.getCell('A').alignment = { horizontal: 'right' };
    nameFarmRow.getCell('D').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A4:C4');
    worksheet.mergeCells('D4:I4');

    const objectNameRow = worksheet.addRow(['Наименование объекта исследования (пробы):', '', '', 'почва']);
    objectNameRow.getCell('A').alignment = { horizontal: 'right' };
    objectNameRow.getCell('D').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A5:C5');
    worksheet.mergeCells('D5:I5');

    const transportRow = worksheet.addRow(['Отбор и транспортировка пробы:', '', '', laboratory.selectionTransportationSample || '']);
    transportRow.getCell('A').alignment = { horizontal: 'right' };
    transportRow.getCell('D').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A6:C6');
    worksheet.mergeCells('D6:I6');

    const dateSampleReceiptRow = worksheet.addRow(['Дата поступления объекта (пробы):', '', '', getRuFormat(laboratory.receiptDate)]);
    dateSampleReceiptRow.getCell('A').alignment = { horizontal: 'right' };
    dateSampleReceiptRow.getCell('D').numFmt = 'dd.mm.yyyy';
    dateSampleReceiptRow.getCell('D').alignment = { horizontal: 'left' };
    worksheet.mergeCells('A7:C7');
    worksheet.mergeCells('D7:I7');

    const testDate = worksheet.addRow(['Дата проведения испытаний:', '', '', laboratory?.dateTesting?.map((date) => getRuFormat(date)).join(' - ') || '']);
    testDate.getCell('A').alignment = { horizontal: 'right' };
    testDate.getCell('D').alignment = { horizontal: 'left' };
    testDate.getCell('D').numFmt = 'dd.mm.yyyy - dd.mm.yyyy';
    worksheet.mergeCells('A8:C8');
    worksheet.mergeCells('D8:I8');

    const titleField = worksheet.addRow([`№ ${nameField}`]);
    worksheet.mergeCells('A9:T9');
    titleField.alignment = { horizontal: 'left', vertical: 'middle' };
    titleField.font = {
      bold: true,
    };

    // ----------------------------------
    addAnalysisData(worksheet, attributes, laboratory);
    saveExcelFile(workbook, `${nameField} Агрохимические показатели и методики`);
  };

  const exportExcelFileAnalysis = (
    nameField: string,
    attributes: AnalysesTableTypes,
  ) => {
    const workbook = new Workbook();
    workbook.creator = 'Агрохимические показатели и методики';

    workbook.lastModifiedBy = 'Форма выгрузки-загрузки';
    const worksheet = workbook.addWorksheet('Форма выгрузки-загрузки', {
      views: [
        { state: 'frozen', ySplit: 4 },
      ],
    });
    const titleField = worksheet.addRow([`№ ${nameField}`]);
    worksheet.mergeCells('A1:T1');
    titleField.alignment = { horizontal: 'left', vertical: 'middle' };
    titleField.font = {
      bold: true,
    };

    addAnalysisData(worksheet, attributes, null);
    saveExcelFile(workbook, `${nameField} Агрохимические показатели и методики`);
  };

  const importExcelFileAnalysisForLaboratory = (
    file: File,
    updateData: (newData: AnalysesTableTypes) => void,
    laboratory: Laboratory,
  ) => {
    const wb = new Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      // @ts-ignore
      wb.xlsx.load(buffer).then((workbook) => {
        const ws = workbook.getWorksheet(1);
        const newDataLaboratory: Laboratory = { ...laboratory };
        const { baseIndicators, additionalIndicators } = getBaseAdditionalIndicators(laboratory);
        const allIndicators = [...baseIndicators, ...additionalIndicators];
        const protocolRowValues = ws.getRow(2).values;
        // eslint-disable-next-line prefer-destructuring
        newDataLaboratory.protocolNumber = protocolRowValues[4];
        // eslint-disable-next-line prefer-destructuring
        newDataLaboratory.protocolDate = getNormalFormat(protocolRowValues[6]);
        const nameFarmRowValues = ws.getRow(4).values;
        // eslint-disable-next-line prefer-destructuring
        newDataLaboratory.nameFarm = nameFarmRowValues[4];
        const transportRowValues = ws.getRow(6).values;
        // eslint-disable-next-line prefer-destructuring
        newDataLaboratory.selectionTransportationSample = transportRowValues[4];
        const dateSampleReceiptRowValues = ws.getRow(7).values;
        // eslint-disable-next-line prefer-destructuring
        newDataLaboratory.receiptDate = getNormalFormat(dateSampleReceiptRowValues[4]);
        const testDateValues = ws.getRow(8).values;
        newDataLaboratory.dateTesting = moment.utc(testDateValues[4]).isValid()
          ? [moment.utc(testDateValues[4]).format('YYYY-MM-DD')]
          : testDateValues[4].split('-')?.map((item) => getNormalFormat(item.trim())).filter((item) => item) || [];
        const rowTypes = ws.getRow(12).values;
        const rowsData = ws.getRows(13, ws.actualRowCount - 12);

        const types = baseIndicators.reduce((result, { isDefinitionForm, key }, index) => {
          if (isDefinitionForm) {
            // eslint-disable-next-line no-param-reassign
            result[key] = rowTypes[3 + index];
          }
          return result;
        }, {});

        updateData({
          types,
          // @ts-ignore
          data: rowsData.map<Partial<AnalysesData>>((row) => {
            const [, number, sampleNumber, ...analysesData] = row.values as any;
            const result = { number, sampleNumber };
            allIndicators.forEach(({ key }, index) => {
              result[key] = analysesData[index];
            });

            return result;
          }),
          laboratory: newDataLaboratory,
        });
      });
    };
  };

  const importExcelFileAnalysis = (
    file: File,
    updateData: (newData: AnalysesTableTypes) => void,
  ) => {
    const wb = new Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      // @ts-ignore
      wb.xlsx.load(buffer).then((workbook) => {
        const ws = workbook.getWorksheet(1);
        const rowTypes = ws.getRow(4).values;
        const rowsData = ws.getRows(5, ws.actualRowCount - 4);
        updateData({
          types: {
            n: rowTypes[3],
            p: rowTypes[4],
            k: rowTypes[5],
          },
          data: rowsData.map<AnalysesData>((row) => {
            const [, number, sampleNumber, n,
              p, k, s, ph, humus,
              salinization, salinizationType, ca, mg, fe,
              mn, zn, cu, b, mo, na,
              cl, co, soilCompaction] = row.values as any;

            return {
              sampleNumber,
              number,
              n,
              p,
              k,
              s,
              ph,
              humus,
              salinization,
              salinizationType,
              ca,
              mg,
              fe,
              mn,
              zn,
              cu,
              b,
              mo,
              na,
              cl,
              co,
              soilCompaction,
            };
          }),
        });
      });
    };
  };

  return {
    exportExcelFileAnalysis,
    exportExcelFileAnalysisForLaboratory,
    importExcelFileAnalysisForLaboratory,
    importExcelFileAnalysis,
  };
}
