import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { abilitiesPlugin } from '@casl/vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { ability } from '@/services/ability';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import quasarUserOptions from './quasar-user-options';
import register from './register-service-worker';

register();

const pinia = createPinia();

createApp(App)
  // @ts-ignore
  .use(Quasar, quasarUserOptions)
  .use(VueAxios, axios)
  .use(abilitiesPlugin, ability, { useGlobalProperties: true })
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  })
  .use(pinia)
  .use(router)
  .mount('#app');

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
