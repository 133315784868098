import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// eslint-disable-next-line import/no-cycle
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { LaboratoryTypeDS } from '@/dialogs/AssayDialog/types/Laboratory';

let laboratories: LaboratoryTypeDS[] = null;

export default function useLaboratoriesAutocompleteDataSource(): DataSource<LaboratoryTypeDS> {
  const {
    get, dataSubject, ...anyData
  } = useDataSource<LaboratoryTypeDS>('organizations/lab_list', (search: string) => {
    if (!laboratories) {
      get({ params: { search } })
        .pipe(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          catchError(() => of([])),
        ).subscribe((response: LaboratoryTypeDS[]) => {
          dataSubject.next(response);
          laboratories = response;
        });
    } else {
      dataSubject.next(laboratories as LaboratoryTypeDS[]);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
