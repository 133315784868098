import Feature from 'ol/Feature';
import booleanIntersects from '@turf/boolean-intersects';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import { Point, Polygon } from 'ol/geom';
import {
  cratePolygon, formatGeoJSONReadFeature, formatGeoJSONWriteFeature, getJsonPolygon,
} from '@/helpers/map';
import union from '@turf/union';
import { getArea as getAreaOL } from 'ol/sphere';
import centerOfMass from '@turf/center-of-mass';
import Map from '@/modules/map/data/Map';
import { onUnmounted, Ref, UnwrapRef } from 'vue';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import useHoverElementaryArea from '@/dialogs/AssayDialog/components/maps/CreateRouteMap/composables/useHoverElementaryArea';
import useErrors from '@/dialogs/AssayDialog/hooks/useErrors';
import useHelpers from '@/dialogs/AssayDialog/components/maps/CreateRouteMap/composables/useHelpers';

export default function useMergeElementaryArea(
  map: Map,
  centerPoints: Ref<UnwrapRef<Feature<Point>[]>>,
  resetSettings: () => void,
  updateCurrentNumberOfSamplesToTake: ()=> void,
) {
  const {
    setIsRemove, getPointByPolygon, setArea, setGeoJsonFeature, getGeoJsonFeature, getArea,
  } = useAdditionalFunctionsFeature();

  const { onHoverPolygon, offHoverPolygon, setSelectionFeatures } = useHoverElementaryArea(map);
  const { errorSelectActivePolygon } = useErrors();
  const { getElementaryAreaOnMapByClick } = useHelpers();

  let selectClick = null;
  let eventSelectClick = null;

  const finishMergePoints = () => {
    map.pointSource.clear();
    map.removeInteraction(selectClick);
    if (selectClick) {
      if (eventSelectClick) {
        selectClick.un('select', eventSelectClick);
        eventSelectClick = null;
      }
      selectClick = null;
    }
  };

  const onClickEscape = (el) => {
    if (el.key === 'Escape') {
      finishMergePoints();
      resetSettings();
      setSelectionFeatures(null);
      // eslint-disable-next-line no-use-before-define
      removeEventClickEscape();
      map.pointSource.addFeatures(centerPoints.value as Feature<Point>[]);
      // eslint-disable-next-line no-use-before-define
      map.map.on('click', mergeEvent);
    }
  };

  const removeEventClickEscape = () => {
    document.removeEventListener('keydown', onClickEscape);
  };

  const addEventClickEscape = () => {
    document.addEventListener('keydown', onClickEscape);
  };

  const mergePoints = (onePolygonMerge: Feature, twoPolygonMerge: Feature) => {
    setIsRemove(getPointByPolygon(onePolygonMerge), true);
    map.pointSource.addFeatures(centerPoints.value as Feature<Point>[]);
    twoPolygonMerge.setGeometry(cratePolygon(union(
      getJsonPolygon(onePolygonMerge.getGeometry()),
      getJsonPolygon(twoPolygonMerge.getGeometry()),
    ).geometry));
    const newArea = Math.round(getAreaOL(twoPolygonMerge.getGeometry()) * 0.01) / 100;
    setArea(twoPolygonMerge, newArea);
    setArea(getPointByPolygon(twoPolygonMerge), newArea);

    const centerPolygon = centerOfMass(formatGeoJSONWriteFeature(twoPolygonMerge));
    getPointByPolygon(twoPolygonMerge)
      .setGeometry(formatGeoJSONReadFeature(centerPolygon).getGeometry() as Point);
    setGeoJsonFeature(twoPolygonMerge);
    onePolygonMerge.setGeometry(new Polygon([]));
    resetSettings();

    setIsRemove(getPointByPolygon(twoPolygonMerge), false);
  };

  const startMergePoints = (onePolygonMerge: Feature) => {
    map.pointSource.clear();
    addEventClickEscape();
    const features = map.staticSource.getFeatures()
      .filter((elementaryAreaFeature) =>
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        elementaryAreaFeature.ol_uid !== onePolygonMerge.ol_uid
        && (elementaryAreaFeature.getGeometry() as Polygon).getCoordinates().length
        && !!booleanIntersects(
          getGeoJsonFeature(elementaryAreaFeature),
          getGeoJsonFeature(onePolygonMerge),
        ));

    map.pointSource.addFeatures(features);
    setSelectionFeatures(features);

    selectClick = new Select({ condition: click, layers: [map.pointVector] });

    eventSelectClick = ({ selected: [twoPolygonMerge] }) => {
      if (!twoPolygonMerge) {
        errorSelectActivePolygon();
        return;
      }

      // eslint-disable-next-line no-use-before-define
      map.map.on('click', mergeEvent);
      finishMergePoints();
      if (getArea(onePolygonMerge) > getArea(twoPolygonMerge)) {
        mergePoints(twoPolygonMerge, onePolygonMerge);
      } else {
        mergePoints(onePolygonMerge, twoPolygonMerge);
      }
      updateCurrentNumberOfSamplesToTake();
      setSelectionFeatures(null);
      removeEventClickEscape();
    };

    selectClick.on('select', eventSelectClick);

    setTimeout(() => {
      map.addInteraction(selectClick);
    }, 200);
  };

  const mergeEvent = (e) => {
    const clickPolygonFeature = getElementaryAreaOnMapByClick(e, map);
    if (clickPolygonFeature) {
      map.map.un('click', mergeEvent);
      startMergePoints(clickPolygonFeature as Feature);
    }
  };

  const onMerge = () => {
    onHoverPolygon();
    map.map.on('click', mergeEvent);
  };

  const offMerge = () => {
    map.map.un('click', mergeEvent);
    finishMergePoints();
    offHoverPolygon();
    removeEventClickEscape();
  };

  onUnmounted(() => {
    map.map.un('click', mergeEvent);
    if (selectClick && eventSelectClick) {
      selectClick.un('select', eventSelectClick);
    }
    removeEventClickEscape();
  });

  return { onMerge, offMerge };
}
