import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ref } from 'vue';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { Region } from '@/dialogs/FieldManageDialog/types/Region';

export default function useRegionsDataSource(): DataSource<Region> {
  const regions = ref<Region[]>(null);

  const {
    get, dataSubject, ...anyData
  } = useDataSource<Region>('regions', (search: string) => {
    if (!regions.value) {
      get({ params: { search } })
        .pipe(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          catchError(() => of([])),
          tap((response: Region[]) => {
            dataSubject.next(response);
            regions.value = response;
          }),
          map((response: Region[]) => response),
        ).subscribe();
    } else {
      dataSubject.next(regions.value as Region[]);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
