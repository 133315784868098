import CrudService from '@/api/configs/crud.service';
import { ScoutingTask } from '@/models/ScoutingTask';

class ScoutingCrudService extends CrudService<ScoutingTask, ScoutingTask> {
  protected ENDPOINT = 'scouting_tasks';

  protected normalizeData(data:ScoutingTask) {
    return data;
  }
}

const scoutingCrudService = new ScoutingCrudService();

export default scoutingCrudService;
