import {
  Point, MultiPoint, Polygon, MultiPolygon,
} from 'ol/geom';
import {
  Style, Stroke, Fill, Circle as CircleStyle,
} from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import ElementType from '@/modules/map/contracts/ElemetType';
import { FieldMap } from '@/modules/field/types/Field';
import BasePolygonFeature from '@/modules/map/features/BasePolygonFeature';
import moment from 'moment';

export default class FieldFeature extends BasePolygonFeature<FieldMap> {
  type = ElementType.Field;

  constructor(props: FieldMap) {
    super();
    this.setProps(props);
    this.setDefaultStyle();
    this.setGeometryFeature(props.geometry);
  }

  static borderStyle(feature) {
    feature.setStyle(new Style({
      stroke: new Stroke({
        color: '#CFD8DC50',
        width: 1,
      }),
      fill: new Fill({
        color: '#37474F77',
      }),
    }));
  }

  static modifyStyle(feature, fillColor = '#C8E6C920') {
    feature.setStyle((featureGeometry) => {
      switch (featureGeometry.getGeometry().getType()) {
        case 'Point': {
          return new Style({
            image: new CircleStyle({
              stroke: new Stroke({
                color: 'white',
                width: 1,
              }),
              fill: new Fill({
                color: '#388E3C',
              }),
              radius: 5,
            }),
            geometry: new Point(featureGeometry.getGeometry().getCoordinates()),
          });
        }
        case 'Polygon': {
          const coordinates = (featureGeometry.getGeometry() as Polygon).getCoordinates();
          return this.getPolygonModifyStyle(coordinates, 1, fillColor);
        }
        case 'MultiPolygon': {
          const coordinates = (featureGeometry.getGeometry() as MultiPolygon).getCoordinates();
          return this.getPolygonModifyStyle(coordinates, 2, fillColor);
        }
        default: return null;
      }
    });
  }

  private static getPolygonModifyStyle(
    coordinates: Coordinate[][] | Coordinate[][][],
    numberFlat: number,
    fillColor: string,
  ) {
    return [
      new Style({
        stroke: new Stroke({
          color: 'white',
          width: 3,
        }),
      }),

      new Style({
        stroke: new Stroke({
          color: '#388E3C',
          width: 1.5,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),

      new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
          fill: new Fill({
            color: '#388E3C',
          }),
          radius: 3,
        }),
        geometry: new MultiPoint(
          (coordinates as []).flat(numberFlat),
        ),
      }),
    ];
  }

  private getColorAssay(dateStartAnalysis: string): string {
    const { date, isCollectionAnalyzesCompleted } = this.props.analyses;
    if (date && date >= dateStartAnalysis) {
      switch (isCollectionAnalyzesCompleted) {
        case true: return '#388E3C';
        case false: return '#fa8600';
        default: return '#fa002e';
      }
    }

    return '#AAAAAA';
  }

  getLastDateAssay() {
    if (this.props.analyses?.date) {
      return moment(this.props.analyses.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return null;
  }

  private getDefaultStyle() {
    const { color } = this.props;
    let stroke = null;
    const assayFilterData = this.getAssayFilterData();

    if (assayFilterData?.isHighlightField) {
      const colorAssay = this.getColorAssay(assayFilterData.dateStartAnalysis);
      stroke = new Stroke({
        color: colorAssay,
        width: 3,
      });
    } else {
      stroke = new Stroke({
        color,
        width: 1.5,
      });
    }

    return [new Style({
      stroke,
      fill: new Fill({
        color: `${color}40`,
      }),
    })];
  }

  setIsSelectedDate(status: boolean) {
    this.set('isSelected', status);
  }

  getIsSelectedDate(): boolean {
    return !!this.get('isSelected');
  }

  setDefaultStyle() {
    this.setIsSelectedDate(false);
    this.setStyle(
      ...this.getDefaultStyle(),
    );
  }

  setSelectStyle() {
    this.setIsSelectedDate(true);
    this.setStyle([
      new Style({
        stroke: new Stroke({
          color: 'white',
          width: 3,
        }),
      }),

      ...this.getDefaultStyle(),
    ]);
  }

  updateStyles() {
    if (this.getIsSelectedDate()) {
      this.setSelectStyle();
    } else {
      this.setDefaultStyle();
    }
  }

  setAssayFilterData(data: {isHighlightField: boolean, dateStartAnalysis: string}) {
    this.set('assayFilter', data);
    this.updateStyles();
  }

  getAssayFilterData(): {isHighlightField: boolean, dateStartAnalysis: string} {
    return this.get('assayFilter');
  }
}
