export type OrganizationType = {
  alias: string,
  text: string
};

export enum OrganizationTypeAlias {
  Household = 'Household',
  Region = 'Region',
  District = 'District'
}
