import script from "./CreateGridOneStep.vue?vue&type=script&lang=ts&setup=true"
export * from "./CreateGridOneStep.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnToggle,QSlider});
