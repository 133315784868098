<template>
  <q-card
    class="q-page mx-auto cardLogin text-center shaped shadow-24"
  >
    <q-card-section class="text-center text-body2">
      404 - Ресурс не существует. <a href="/">home</a>
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>
  a {
    color: blue;
  }
</style>
