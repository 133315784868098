import { MeteoNode } from '@/dialogs/MeteoStationDialog/types/MeteoNode';
import CrudService from '@/api/configs/crud.service';

class MeteoNodeApiService extends CrudService<MeteoNode> {
  protected ENDPOINT = 'meteo_nodes';

  protected normalizeData(data: MeteoNode) {
    return data;
  }
}

const meteoNodeApiService = new MeteoNodeApiService();

export default meteoNodeApiService;
