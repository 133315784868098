import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import {
  Circle, Fill, Stroke, Style, Text,
} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';

export default function useStyles() {
  const q = useQuasar();
  const { getDataFeature, getIsRemove } = useAdditionalFunctionsFeature();

  const setStylePoint = (map: Map) => {
    map.pointVector.setStyle((e) => {
      const isRemove = getIsRemove(e as Feature<Point>);
      if (isRemove) {
        return [];
      }
      // TODO оптимизировать отработку стилей
      const numPoint = getDataFeature(e as Feature).num;
      const zoom = map.getView().getZoom();

      let text = '';
      let color = 'rgba(255,255,255,0.4)';
      if (numPoint != null) {
        text = zoom > 14 ? numPoint.toString() : '';
        color = 'rgba(186,108,108,0.4)';
      }

      const fill = new Fill({
        color,
      });

      const stroke = new Stroke({
        color: '#3399CC',
        width: 1.25,
      });

      return [new Style({
        text: new Text({
          font: '15px roboto,sans-serif',
          text,
          fill: new Fill({
            color: '#333',
          }),
          textAlign: 'center',
          textBaseline: 'bottom',
          offsetY: -5,
          offsetX: 0,
        }),
        image: new CircleStyle({
          fill,
          stroke,
          radius: 5,
        }),
        fill,
        stroke,
      })];
    });
  };

  const setStylePolygons = (map: Map) => {
    map.staticVector.setStyle((e) => {
      const fill = new Fill({
        color: 'rgba(255,255,255,0.4)',
      });
      const stroke = new Stroke({
        color: '#3399CC',
        width: 1.25,
      });
      return [
        new Style({
          image: new Circle({
            fill,
            stroke,
            radius: 5,
          }),
          fill,
          stroke,
          text: q.platform.is.mobile ? new Text({
            font: '12px roboto,sans-serif',
            text: `${e.get('area').toString()} Га`,
            fill: new Fill({
              color: '#333',
            }),
            textAlign: 'center',
            textBaseline: 'top',
            offsetY: 5,
            offsetX: 0,
          }) : undefined,
        }),
      ];
    });
  };

  return { setStylePoint, setStylePolygons };
}
