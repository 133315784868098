import { ref } from 'vue';
import Highcharts from 'highcharts';
import { CropStructure } from '@/modules/species-structure/types/CropStructure';
import { PointType } from '@/types/charts/PointType';

export default function useSpeciesStructure() {
  const speciesData = ref([]);

  const varietiesData = ref([]);

  const plotVarietiesBySpecies = () => ({
    chart: {
      type: 'pie',
      width: 850,
      height: 800,
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: 'Га',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          distance: 20,
        },
      },
    },
    series: [{
      name: 'Species',
      data: speciesData.value,
      size: '60%',
      dataLabels: {
        formatter(this: PointType) {
          const {
            point: {
              options: { name },
            },
          } = this;
          return name;
        },
        color: '#ffffff',
        distance: 2,
        borderRadius: 5,
        backgroundColor: '#D5D5D5',
        borderWidth: 1,
        borderColor: '#AAA',
      },
    }, {
      name: 'Varieties',
      data: varietiesData.value,
      size: '80%',
      dataLabels: {
        formatter(this: PointType) {
          const {
            point: {
              options: { name },
            },
          } = this;
          return name || 'БЕЗ СОРТА';
        },
        distance: 35,
      },
      innerSize: '60%',
      id: 'varieties',
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 450,
        },
        chartOptions: {
          series: [{
          }, {
            id: 'varieties',
            dataLabels: {
              enabled: false,
            },
          }],
        },
      }],
    },
  });

  const getVarietiesBySpeciesData = (data: CropStructure[]) => {
    const speciesGraph = data.reduce<{[key: number]: {y: number, color: string,
        name: string, data: {name: string, y: number}[]
      }}>((list, item) => {
        if (!(item.specieId in list)) {
        // eslint-disable-next-line no-param-reassign
          list[item.specieId] = {
            y: 0,
            color: item.color,
            name: item.specie,
            data: [],
          };
        }
        const specie = list[item.specieId];

        if (!(item.varietyId in specie.data)) {
          specie.data[item.varietyId] = { name: item.variety, y: 0 };
        }

        specie.data[item.varietyId].y += item.sumArea;

        specie.y += item.sumArea;

        return list;
      }, {});

    const speciesGraphStructure = Object.values(speciesGraph);
    // Build the data arrays

    speciesGraphStructure.forEach(({
      y, color, name, data: dataOfVarieties,
    }) => {
      speciesData.value.push({ name, y, color });

      Object.values(dataOfVarieties).forEach((
        { name: varietyName, y: varietySum },
        index: number,
        array: any[],
      ) => {
        const brightness = 0.2 - (index / array.length) / 3;
        varietiesData.value.push({
          name: varietyName,
          y: varietySum,
          color: Highcharts.color(color).brighten(brightness).get(),
        });
      });
    });
  };

  return {
    plotVarietiesBySpecies,
    getVarietiesBySpeciesData,
  };
}
