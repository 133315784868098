import { Draw } from 'ol/interaction';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import booleanIntersects from '@turf/boolean-intersects';
import { Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { isEqual } from 'lodash';
import Map from '@/modules/map/data/Map';
import { ref, Ref, UnwrapRef } from 'vue';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import usePositionFunctionsFeature from '@/dialogs/AssayDialog/hooks/usePositionFunctionsFeature';
import useErrors from '@/dialogs/AssayDialog/hooks/useErrors';

export default function useDrawAutoGost(
  map: Map,
  points: Ref<UnwrapRef<Feature<Point>[]>>,
  positionsPolygons: Ref<UnwrapRef<{polygon: Feature, point: Feature<Point> }[][]>>,
  startNumberPolygon: Ref<UnwrapRef<number>>,
) {
  const {
    getPointByPolygon,
    getPositionsFeature,
    setLinePoint,
    setNextPoint,
    getCoordinateFeature,
    setBackPoint,
    getIsRemove,
    getDataFeature,
    getPolygonByPoint,
    getGeoJsonFeature,
  } = useAdditionalFunctionsFeature();

  const {
    isEdgePolygon,
    getAutoActivePoints, getPoint,
  } = usePositionFunctionsFeature(positionsPolygons);

  const { errorSelectPolygonPoint } = useErrors();
  const arrayPositions = ref<number[][]>([]);

  const getNextPositionsArray = (
    list: number[][],
    step: number[],
    activePositions: {[key: string]: boolean},
  ) => {
    const lastPositions = list[list.length - 1];
    const [yNext, xNext] = [lastPositions[0] + step[0], lastPositions[1] + step[1]];

    const addAndUpdateActivePosition = (y: number, x: number) => {
      list.push([y, x]);
      // eslint-disable-next-line no-param-reassign
      activePositions[`${y}_${x}`] = true;
    };

    const isActive = ({ y, x }: {y: number, x: number}) => activePositions[`${y}_${x}`];

    if (yNext in positionsPolygons.value && xNext in positionsPolygons.value[yNext]
      && !isActive({ y: yNext, x: xNext })
      && !getIsRemove(positionsPolygons.value[yNext][xNext].point as Feature<Point>)) {
      addAndUpdateActivePosition(yNext, xNext);
      getNextPositionsArray(list, step, activePositions);
    }

    const nextStep = () => {
      const newStep = [step[0] * -1, step[1] * -1];
      getNextPositionsArray(list, newStep, activePositions);
    };

    const topBottonStep = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const geyTopYPosition = (bias: number, direction: -1 | 1) => {
        const newBias = bias + 1;
        const newYPosition = lastPositions[0] + (direction * newBias);
        if (positionsPolygons.value[newYPosition]
          && positionsPolygons.value[newYPosition][lastPositions[1]]) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return geyTopYPosition(newBias, direction);
        }
        return bias;
      };

      const topPositions = {
        y: lastPositions[0] - 1,
        x: lastPositions[1],
      };

      const newPointTop = positionsPolygons.value[topPositions.y]; // top

      if (newPointTop && newPointTop[topPositions.x] && !isActive(topPositions)
        && !getIsRemove(newPointTop[topPositions.x].point as Feature<Point>)) {
        addAndUpdateActivePosition(topPositions.y, topPositions.x);
        nextStep();
      }

      const bottomPosition = {
        y: lastPositions[0] + 1,
        x: lastPositions[1],
      };

      const newPointBottom = positionsPolygons.value[bottomPosition.y]; // bottom

      if (newPointBottom && newPointBottom[bottomPosition.x] && !isActive(bottomPosition)
        && !getIsRemove(newPointBottom[bottomPosition.x].point as Feature<Point>)) {
        addAndUpdateActivePosition(bottomPosition.y, bottomPosition.x);
        nextStep();
      }
    };

    const leftRightStep = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const geyTopXPosition = (bias: number, direction: -1 | 1) => {
        const newBias = bias + 1;
        const newXPosition = lastPositions[1] + (direction * newBias);
        if (positionsPolygons.value[lastPositions[0]]
          && positionsPolygons.value[lastPositions[0]][newXPosition]) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return geyTopXPosition(newBias, direction);
        }
        return bias;
      };

      const leftPosition = {
        y: lastPositions[0],
        x: lastPositions[1] - 1,
      };

      const newPointLeftRight = positionsPolygons.value[leftPosition.y]; // left

      if ((leftPosition.x) in newPointLeftRight && !isActive(leftPosition)
        && !getIsRemove(newPointLeftRight[leftPosition.x].point as Feature<Point>)) {
        addAndUpdateActivePosition(leftPosition.y, leftPosition.x);
        nextStep();
      }

      const rightPosition = {
        y: lastPositions[0],
        x: lastPositions[1] + 1,
      };

      if ((rightPosition.x) in newPointLeftRight && !isActive(rightPosition)
        && !getIsRemove(newPointLeftRight[rightPosition.x].point as Feature<Point>)) {
        addAndUpdateActivePosition(rightPosition.y, rightPosition.x);
        nextStep();
      }
    };

    if (step[0] === 0) { // y  - top => bottom
      topBottonStep();
      leftRightStep();
    } else if (step[1] === 0) { // x - left -> right
      leftRightStep();
      topBottonStep();
    }

    return list;
  };

  const findPolygonByPoint = (coordinate: Coordinate) => {
    const polygons = map.staticSource.getFeaturesAtCoordinate(coordinate);
    if (polygons.length) {
      return polygons[0];
    }
    return null;
  };

  const drawAutoGost = (coordinates: Coordinate[], geom: LineString) => {
    if (!geom) {
      const firstPolygon = findPolygonByPoint(coordinates[0]);
      if (firstPolygon && isEdgePolygon(firstPolygon)) {
        const newCoordinate = (getPointByPolygon(firstPolygon)
          .getGeometry() as Point)
          .getCoordinates();

        const startPositions = getPositionsFeature(firstPolygon);

        const newLineString = new LineString([newCoordinate, coordinates[0]]);
        newLineString.setProperties({ startPositions });

        const [y, x] = startPositions;

        map.pointSource.clear();
        map.pointSource.addFeatures([...getAutoActivePoints(y, x), getPoint(y, x)]);

        // eslint-disable-next-line no-param-reassign
        coordinates[0] = newCoordinate;
        return newLineString;
      }
      errorSelectPolygonPoint();

      // eslint-disable-next-line no-use-before-define
      drawAutoInteractionGost.abortDrawing();
      return new LineString([]);
    }

    if (coordinates.length === 2) {
      geom.setCoordinates(coordinates);
    } else if (coordinates.length >= 3) {
      const lastPolygon = findPolygonByPoint(coordinates[1] as number[]);
      if (lastPolygon) {
        const startPositions = geom.get('startPositions');
        const [y, x] = getPositionsFeature(lastPolygon as Feature);
        const kArray = [startPositions[0] - y, startPositions[1] - x];

        const listAllK = [[-1, 0], [0, 0], [1, 0], [0, 1], [0, -1]];

        if (listAllK.some((item) => isEqual(item, kArray))) {
          const newCoordinate = (positionsPolygons.value[y][x].point
            .getGeometry() as Point)
            .getCoordinates();
          if (isEqual(newCoordinate, coordinates[0])) {
            // eslint-disable-next-line no-use-before-define
            drawAutoInteractionGost.removeLastPoint();
          } else {
            // eslint-disable-next-line no-param-reassign
            coordinates[1] = newCoordinate;
            const positionPolygon = getPositionsFeature(lastPolygon as Feature);
            arrayPositions.value = [startPositions, positionPolygon];
            geom.set('endPositions', positionPolygon);
            geom.setCoordinates(coordinates);
            // eslint-disable-next-line no-use-before-define
            drawAutoInteractionGost.finishDrawing();
            map.pointSource.clear();
            map.pointSource.addFeatures(points.value as Feature<Point>[]);
          }
        } else {
          errorSelectPolygonPoint();
          // eslint-disable-next-line no-use-before-define
          drawAutoInteractionGost.removeLastPoint();
        }
      } else {
        errorSelectPolygonPoint();
        // eslint-disable-next-line no-use-before-define
        drawAutoInteractionGost.removeLastPoint();
      }
    }

    return geom;
  };
  const drawAutoInteractionGost = new Draw({
    source: map.lineSource,
    type: 'LineString',
    finishCondition: () => false,
    // @ts-ignore
    geometryFunction: drawAutoGost,
  });

  drawAutoInteractionGost.on('drawend', () => {
    if (!arrayPositions.value.length) {
      return;
    }
    const [startPositionArr, endPositionArr] = arrayPositions.value;
    const step = [
      endPositionArr[0] - startPositionArr[0],
      endPositionArr[1] - startPositionArr[1],
    ];

    const lineArrPoints = [startPositionArr];
    const activePositions = { [`${startPositionArr[0]}_${startPositionArr[1]}`]: true };

    // eslint-disable-next-line no-param-reassign
    points.value = [];

    const lines = getNextPositionsArray(lineArrPoints, step, activePositions)
      .reduce<Feature<LineString>[]>((listLines, [y, x], index, positionsArray) => {
        const point = getPoint(y, x);
        const dataFeature = getDataFeature(point);
        dataFeature.index = index;
        if (dataFeature.num == null) {
          dataFeature.num = Number(startNumberPolygon.value) + index;
        }
        points.value.push(point);
        if ((index - 1) >= 0) {
          const [backY, backX] = positionsArray[index - 1];
          const backPoint = getPoint(backY, backX);
          setBackPoint(point, backPoint);
          setNextPoint(backPoint, point);
          const line = new Feature(
            {
              geometry: new LineString([
                getCoordinateFeature(backPoint), getCoordinateFeature(point)]),
            },
          );

          if (!booleanIntersects(
            getGeoJsonFeature(getPolygonByPoint(point)),
            getGeoJsonFeature(getPolygonByPoint(backPoint)),
          )) {
            line.setStyle(new Style({
              stroke: new Stroke({
                color: [0, 0, 0, 0.6],
                width: 2,
                lineDash: [4, 8],
              }),
            }));
          }
          setLinePoint(backPoint, line);
          listLines.push(line);
        }

        return listLines;
      }, []);
    map.pointSource.clear();
    map.pointSource.addFeatures(points.value as Feature<Point>[]);

    setTimeout(() => {
      map.lineSource.clear();
      map.lineSource.addFeatures(lines);
    }, 300);
  });

  return drawAutoInteractionGost;
}
