import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import Role from '@/modules/auth/data/RoleInterface';

class RolesApiService extends BaseCrud<Role> {
  ENDPOINT = 'roles';

  getData(options?: AxiosRequestConfig) {
    return super.getData(this.ENDPOINT, options);
  }
}

const rolesApiService = new RolesApiService();

export default rolesApiService;
