import Notify from 'quasar/src/plugins/Notify.js';;

export default function useErrors() {
  const errorSelectPolygonPoint = () => {
    Notify.create({
      type: 'negative',
      message: 'Выберите полигон, в котором есть точка!',
      timeout: 700,
    });
  };

  const errorSelectActivePolygon = () => {
    Notify.create({
      type: 'negative',
      message: 'Выберите активный полигон!',
    });
  };

  const errorImportPolygonsLength = () => {
    Notify.create({
      type: 'negative',
      message: 'Ошибка импорта файла! Импортированное количество полигонов не соответствует текущему!',
    });
  };

  const errorImportPolygon = () => {
    Notify.create({
      type: 'negative',
      message: 'Ошибка импорта файла! Проверьте соответствие полигонов!',
    });
  };

  const errorSelectRoute = () => {
    Notify.create({
      type: 'negative',
      message: 'Ошибка! Выберите маршрут!',
    });
  };

  const errorEnter = () => {
    Notify.create({
      type: 'negative',
      message: 'Ошибка! Заполните необходимы поля!',
    });
  };

  const errorImportTypes = (type: string) => {
    Notify.create({
      type: 'negative',
      message: `Ошибка импорта типов! Проверьте тип ${type}!`,
    });
  };

  const errorInit = () => {
    Notify.create({
      type: 'negative',
      message: 'Ошибка инициализации!',
    });
  };

  return {
    errorSelectPolygonPoint,
    errorSelectActivePolygon,
    errorImportPolygonsLength,
    errorImportPolygon,
    errorSelectRoute,
    errorEnter,
    errorImportTypes,
    errorInit,
  };
}
