import BaseCrud from '@/api/configs/base-crud';
import { AxiosRequestConfig } from 'axios';
import Analyses from '@/dialogs/AssayDialog/types/Analyses';

class AreaSchemeAnalysisApiService extends BaseCrud<Analyses> {
    protected ENDPOINT = (fieldId: number, elementaryAreasSchemeId: number) => `fields/${fieldId}/elementary_area_scheme/${elementaryAreasSchemeId}/analysis`;

    postAnalysis(fieldId: number, elementaryAreasSchemeId: number, data: Analyses) {
      return super.post(this.ENDPOINT(fieldId, elementaryAreasSchemeId), data);
    }

    putAnalysis(fieldId: number, elementaryAreasSchemeId: number, data: Analyses) {
      return super.put(this.ENDPOINT(fieldId, elementaryAreasSchemeId), data);
    }

    getAnalysisByDate(
      fieldId: number,
      elementaryAreasSchemeId: number,
      date: string,
      options?: AxiosRequestConfig,
    ) {
      return super.getById(`${this.ENDPOINT(fieldId, elementaryAreasSchemeId)}/${date}`, options);
    }
}

const areaSchemeAnalysisApiService = new AreaSchemeAnalysisApiService();

export default areaSchemeAnalysisApiService;
