import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import { Quota } from '@/models/Quota';

class QuotaApiService extends BaseCrud<Quota> {
  ENDPOINT = 'quotas';

  getData(options?: AxiosRequestConfig) {
    return super.getData(this.ENDPOINT, options);
  }
}

const quotaApiService = new QuotaApiService();
export default quotaApiService;
