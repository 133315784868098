import CrudService from '@/api/configs/crud.service';
import { Field } from '@/modules/field/types/Field';
import { AxiosRequestConfig } from 'axios';

class FieldApiService extends CrudService<Partial<Field>, Field> {
  protected ENDPOINT = 'fields';

  getFieldsPWA(options?:AxiosRequestConfig) {
    return super.getData(`${this.ENDPOINT}_pwa`, options);
  }

  protected normalizeData(data:Field) {
    return data;
  }
}

const fieldApiService = new FieldApiService();

export default fieldApiService;
