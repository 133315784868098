import { AxiosRequestConfig } from 'axios';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { District } from '@/models/District';
import BaseCrud from '@/api/configs/base-crud';

class DistrictApiService extends BaseCrud<District> {
  ENDPOINT = 'districts';

  districts: District[];

  get(options?: AxiosRequestConfig) {
    return !this.districts
      ? super.getData(`${this.ENDPOINT}`, options)
        .pipe((tap((data) => { this.districts = data; })))
      : of(this.districts);
  }

  getDistrictById(id: number) {
    return super.getById(`${this.ENDPOINT}/${id}`);
  }
}
const districtApiService = new DistrictApiService();

export default districtApiService;
