<template>
  <div class="wrapper-map">
    <div class="split map-left">
      <slot name="map1" />
    </div>
    <div class="split map-right">
      <slot name="map2" />
    </div>
    <slot name="someData" />
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
  .wrapper-map {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
    .map-left {
      border-right: #333333 solid 4px;
    }
    .split {
      position: relative;
      width: 50%;
      height: 100%;
    }
  }
</style>
