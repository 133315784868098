import BaseCrud from '@/api/configs/base-crud';
import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';
import {
  AnalysisReport,
} from '@/modules/field/components/FieldsAnalysisReport/types/AnalysisReport';
import client from '@/api/configs/client';

class SoilReportApiService extends BaseCrud {
  protected ENDPOINT = 'soil/report/';

  get(year: string, options?: AxiosRequestConfig): Observable<AnalysisReport> {
    return super.getById(`${this.ENDPOINT}${year}`, options);
  }

  postSoilReport(year: string, data: any): Observable<string> {
    return super.post(`${this.ENDPOINT}${year}`, data);
  }

  downloadFile(name): Promise<any> {
    return client.get(`${this.ENDPOINT}download/${name}`, { baseURL: undefined, responseType: 'blob', headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' } });
  }
}

const soilReportApiService = new SoilReportApiService();

export default soilReportApiService;
