const fieldCategories = [
  {
    value: 'arable',
    text: 'Пашня',
  },
  {
    value: 'haymaking',
    text: 'Сенокос',
  },
  {
    value: 'perennial',
    text: 'Многолетние насаждения',
  },
  {
    value: 'pastures',
    text: 'Пастбище',
  },
  {
    value: 'fallow',
    text: 'Залеж',
  },
];
export default fieldCategories;
