import { onMounted } from 'vue';
import { Extent } from 'ol/extent';
import { fromEvent } from 'rxjs';
import { buffer, debounceTime, filter } from 'rxjs/operators';
import { FeatureLike } from 'ol/Feature';
import { CollectionEvent } from 'ol/Collection';
import { useRoute, useRouter } from 'vue-router';
import FeatureRoot from '@/modules/map/features/FeatureRoot';
import ElementType from '@/modules/map/contracts/ElemetType';
import useMap from '@/hooks/useMap';
import routeNames from '@/router/routeNames';

const PATHS = {
  Field: '/fields/',
  Meteo: '/meteos/',
  ScoutingTask: '/scouting/',
  ScoutingReport: '/scouting/',
};

export default function useLoadMap() {
  const map = useMap();
  const $router = useRouter();
  const $route = useRoute();

  const getParams = (feature: FeatureRoot) => {
    map.setActiveFeature(feature);
    const pathBase = PATHS[feature.type];
    const query = Object.keys($route.query)
      .map((k) => `${k}=${$route.query[k]}`)
      .join('&');
    return { pathBase, query };
  };

  onMounted(() => {
    if (localStorage.extentCoords) {
      const extentCoords = JSON.parse(localStorage.getItem('extentCoords'));
      setTimeout(() => {
        map.getView().fit(extentCoords as Extent);
      }, 300);
    }

    map.map.on('moveend', (evt) => {
      localStorage.setItem('extentCoords', JSON.stringify(evt.frameState.extent));
    });

    // doubleClick
    const mouse$ = fromEvent(map.map.getViewport(), 'click');
    mouse$.pipe(
      buffer(mouse$.pipe(debounceTime(250))),
      filter((x) => x.length === 2),
    )
      .subscribe((evt) => {
        const features: FeatureLike[] = map.map.getFeaturesAtPixel(
          map.map.getEventPixel(evt[evt.length - 1] as UIEvent),
        );
        if (!features.length) {
          return;
        }

        const activeTaskFeature = map.clusterFeaturesSource
          .getClosestFeatureToCoordinate(map.map
            .getEventCoordinate(evt[evt.length - 1] as MouseEvent)) as FeatureRoot;

        const activeFeature = features[0] as FeatureRoot;

        if (activeFeature.type) {
          const { type, props: { id } } = activeFeature;
          if (type === ElementType.District) {
            $router.push({
              name: routeNames.organizations,
              query: {
                districtId: id,
              },
            }).catch(() => null);
            return;
          }

          if (type === ElementType.Organization) {
            $router.push({
              name: routeNames.fields,
              query: {
                ...$route.query,
                organizationId: id,
              },
            }).catch(() => null);
            return;
          }

          const { pathBase, query } = getParams(activeFeature);

          if (type === ElementType.Field || type === ElementType.Meteo) {
            const urlTail = type === ElementType.Meteo ? '' : `/${$route.path.split('/')[3] || ''}`;
            $router.push(`${pathBase + id + urlTail}?${query}`).catch(() => null);
          }
        } else {
          const { props: { id, fieldId } } = activeTaskFeature;

          const { pathBase, query } = getParams(activeTaskFeature);

          $router.push(`/fields/${fieldId}${pathBase}${id}?${query}`).catch(() => null);
        }
      });

    map.select.getFeatures().on('add', ({ element: feature }: CollectionEvent) => {
      if (feature) {
        feature.setSelectStyle();
      }
    });

    map.select.getFeatures().on(
      'remove',
      ({ element: feature }: CollectionEvent) => {
        if (feature) {
          feature.setDefaultStyle();
        }
      },
    );
  });

  return null;
}
