export default function useHelperRangeDate() {
  const oneDateMask = '##/##/####';
  const twoDateMask = '##/##/#### - ##/##/####';

  const getMask = (data: {from: string, to: string} | string) => (typeof data === 'string' ? oneDateMask : twoDateMask);

  const formatInputDate = (inputDate: string) => {
    if (!inputDate) return null;

    const [year, month, day] = inputDate.split('-');
    return `${day}/${month}/${year}`;
  };

  const formatOutputDate = (outputDate: string) => {
    if (!outputDate) return null;

    const [day, month, year] = outputDate.split('/');
    return `${year}-${month}-${day}`;
  };

  return {
    getMask, formatInputDate, formatOutputDate, oneDateMask, twoDateMask,
  };
}
