import Map from '@/modules/map/data/Map';
import { jsPDF } from 'jspdf';

const sizes = [210, 297];
const pdi = 150;

export default function usePrintMap(map: Map) {
  const savePdfFile = (name: string, organization: string, data: string, coordinates: number[]) => {
    document.body.style.cursor = 'progress';
    const size = map.getSize();
    const width = Math.round((sizes[0] * pdi) / 25.4);
    const height = Math.round((sizes[1] * pdi) / 25.4);
    const viewResolution = map.getView().getResolution();
    map.map.once('rendercomplete', () => {
      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = width;
      mapCanvas.height = height;
      const ctx = mapCanvas.getContext('2d');
      ctx.font = '30px Arial';
      ctx.fillText(name, 0, 35);
      ctx.fillText(`Организация: ${organization}`, 0, 70);
      ctx.fillText(`Дата сбора: ${data}`, 0, 105);
      ctx.font = '23px Arial';
      ctx.fillText(`Долгота: ${coordinates[0].toFixed(12)}   Широта: ${coordinates[1].toFixed(12)}`, 650, 105);

      Array.prototype.forEach.call(
        document.querySelectorAll('#assayMap .ol-layer canvas'),
        (canvas) => {
          if (canvas.width > 0) {
            const { opacity } = canvas.parentNode.style;
            ctx.globalAlpha = opacity === '' ? 1 : Number(opacity);
            const { transform } = canvas.style;
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              ctx,
              matrix,
            );
            ctx.drawImage(canvas, 0, 110);
          }
        },
      );
      ctx.globalAlpha = 1;
      ctx.setTransform(1, 0, 0, 1, 0, 0);

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('portrait', undefined, 'a4');

      const imageData = mapCanvas.toDataURL('image/png');
      pdf.addImage(
        imageData,
        'png32',
        10,
        10,
        sizes[0] - 20,
        sizes[1] - 20,
      );
      pdf.save(`${name} ${data} ${organization}.pdf`);
      // Reset original map size
      map.map.setSize(size);
      map.getView().setResolution(viewResolution);
      document.body.style.cursor = 'auto';
    });
    // Set print size
    const printSize = [width, height - 100];
    map.map.setSize(printSize);
    const fieldFeature = map.source.getFeatureById('field');
    map.getView().fit(fieldFeature.getGeometry().getExtent(), { padding: [15, 15, 15, 15] });
    map.getView().setResolution(
      map.getView()
        .getResolutionForExtent(
          fieldFeature.getGeometry().getExtent(),
          printSize,
        ) + 0.08,
    );
  };

  return { savePdfFile };
}
