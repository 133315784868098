import { MultiPoint, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import {
  Style, Stroke, Fill, Circle as CircleStyle, Icon, Text,
} from 'ol/style';
import FeatureRoot from '@/modules/map/features/FeatureRoot';
import ElementType from '@/modules/map/contracts/ElemetType';
import { ScoutingTask } from '@/models/ScoutingTask';
import incidentTypes from '@/constants/incidentTypes';
import { getCenter } from 'ol/extent';

export default class ScoutingTaskFeature extends FeatureRoot<ScoutingTask, Point | MultiPoint> {
  type = ElementType.ScoutingTask;

  constructor(props) {
    super();
    this.setProps(props);
    this.setDefaultStyle();
    this.setGeometryFeature(props.location);
  }

  setGeometryFeature(geometry) {
    if (geometry.type) {
      this.setGeometry(geometry.type === 'MultiPoint'
        ? new Point(getCenter(new MultiPoint(geometry.coordinates
          .map((item) => fromLonLat(item))).getExtent()))
        : new Point(fromLonLat(geometry.coordinates)));
    }
  }

  setDefaultStyle() {
    this.formStyle(1.5);
  }

  setSelectStyle() {
    this.formStyle(4);
  }

  setOneIncidentStyle(width: number) {
    return [new Style({
      image: new CircleStyle({
        stroke: new Stroke({
          color: '#ff0000',
          width,
        }),
        fill: new Fill({
          color: '#ffffff',
        }),
        radius: 10,
      }),
    }),
    new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        scale: 0.5,
        crossOrigin: 'anonymous',
        color: '#ff0000',
        src: incidentTypes[this.props.report?.incidents[0].incidentType].url,
      }),
    }),
    ];
  }

  formStyle(width: number) {
    if (this.props.report?.incidents?.length === 1) {
      this.setStyle(
        this.setOneIncidentStyle(width),
      );
    } else if (this.props.report?.incidents?.length > 1) {
      this.setStyle([
        ...this.setOneIncidentStyle(width),
        new Style({
          image: new CircleStyle({
            stroke: new Stroke({
              color: '#ffffff',
              width: 1.5,
            }),
            fill: new Fill({
              color: '#ff0000',
            }),
            radius: 6,
            displacement: [8, 10],
          }),
          text: new Text({
            font: '9px Calibri,sans-serif',
            // eslint-disable-next-line no-unsafe-optional-chaining
            text: String(this.props.report?.incidents?.length - 1),
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke({
              color: '#fff', width: 2,
            }),
            textAlign: 'center',
            offsetX: 9,
            offsetY: -9,
          }),
        }),
      ]);
    } else {
      this.setStyle(new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: '#42A5F5',
            width,
          }),
          fill: new Fill({
            color: '#42A5F540',
          }),
          radius: 10,
        }),
      }));
    }
  }

  clone(): FeatureRoot<ScoutingTask, Point> {
    const clone = new ScoutingTaskFeature(this.props);
    clone.setStyle(this.getStyle());
    // @ts-ignore
    return new ScoutingTaskFeature(this.props);
  }
}
