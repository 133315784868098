import script from "./ColorPicker.vue?vue&type=script&lang=ts&setup=true"
export * from "./ColorPicker.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QPopupProxy,QColor});
