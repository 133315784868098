import { Point } from 'ol/geom';
import { Feature } from 'ol';
import {
  onMounted, onUnmounted, ref, Ref, UnwrapRef, watch,
} from 'vue';
import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import useDrawHand from '@/dialogs/AssayDialog/hooks/useDrawHand';
import useDrawAuto from '@/dialogs/AssayDialog/hooks/useDrawAuto';
import Route from '@/dialogs/AssayDialog/types/Route';
import usePositionFunctionsFeature from '@/dialogs/AssayDialog/hooks/usePositionFunctionsFeature';
import AssayType from '@/dialogs/AssayDialog/types/AssayType';
import useDrawAutoHelpers from '@/dialogs/AssayDialog/hooks/useDrawAutoHelpers';

export default function useDraw(
  polygons: Ref<UnwrapRef<Feature[]>>,
  points: Ref<UnwrapRef<Feature<Point>[]>>,
  positionsPolygons: Ref<UnwrapRef<{polygon: Feature, point: Feature<Point> }[][]>>,
  activeGridType: Ref<UnwrapRef<AssayType>>,
  startNumberPolygon: Ref<UnwrapRef<number>>,
  map: Map,
  activeRoute: Ref<UnwrapRef<Route>>,
) {
  const {
    removeAllInfo, getPointByPolygon,
  } = useAdditionalFunctionsFeature();

  const isDraw = ref(false);
  const { getEdgePolygons } = useDrawAutoHelpers();

  const { isEdgePolygon } = usePositionFunctionsFeature(positionsPolygons);

  const { drawAutoInteraction, finishDrawAuto } = useDrawAuto(
    map,
    positionsPolygons,
    polygons,
    points,
    activeGridType,
    startNumberPolygon,
  );

  const { drawHandInteraction, finishDrawHand, rollBackHand } = useDrawHand(
    map,
    positionsPolygons,
    polygons,
    points,
    activeGridType,
    startNumberPolygon,
  );

  const resetSettings = () => {
    finishDrawHand();
    finishDrawAuto();

    (points.value as Feature<Point>[]).forEach((item) => {
      removeAllInfo(item);
    });

    setTimeout(() => {
      map.lineSource.clear();
    }, 200);
  };

  const addDrawAutoInteraction = () => {
    map.removeInteraction(drawAutoInteraction);
    map.removeInteraction(drawHandInteraction);
    map.addInteraction(drawAutoInteraction);

    if (!map.lineSource.getFeatures().length) {
      const edgePoints = (activeGridType.value === AssayType.Gost ? (polygons.value as Feature[])
        .filter((item) => isEdgePolygon(item))
        : getEdgePolygons(map, polygons.value as Feature[]))
        .map((item) => getPointByPolygon(item));
      map.pointSource.clear();
      map.pointSource.addFeatures(edgePoints as Feature<Point>[]);
    }
  };

  const addDrawHandInteraction = () => {
    map.removeInteraction(drawHandInteraction);
    map.removeInteraction(drawAutoInteraction);
    map.addInteraction(drawHandInteraction);

    if (!map.lineSource.getFeatures().length) {
      const edgePoints = (activeGridType.value === AssayType.Gost ? (polygons.value as Feature[])
        .filter((item) => isEdgePolygon(item))
        : getEdgePolygons(map, polygons.value as Feature[]))
        .map((item) => getPointByPolygon(item));
      map.pointSource.clear();
      map.pointSource.addFeatures(edgePoints as Feature<Point>[]);
    }
  };

  const addDrawInteractionByKey:{[key in Route]: () => void} = {
    [Route.Auto]: addDrawAutoInteraction,
    [Route.Hand]: addDrawHandInteraction,
  };

  const clearRoutes = () => {
    resetSettings();
    addDrawInteractionByKey[activeRoute.value]();
  };

  const onClickEscape = (el) => {
    if (el.key === 'Escape' && isDraw.value) {
      if (activeRoute.value === Route.Auto) {
        drawAutoInteraction.finishDrawing();
        clearRoutes();
        return;
      }

      drawHandInteraction.abortDrawing();
      if (!map.lineSource.getFeatures().length) {
        clearRoutes();
      }
    }
  };

  watch(activeRoute, () => {
    clearRoutes();
  });

  onMounted(() => {
    document.addEventListener('keydown', onClickEscape);
    [drawAutoInteraction, drawHandInteraction].forEach((interaction) => {
      interaction.on('drawstart', () => {
        isDraw.value = true;
      });
      interaction.on('drawend', () => {
        isDraw.value = false;
      });
      interaction.on('drawabort', () => {
        isDraw.value = false;
      });
    });
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', onClickEscape);
    map.removeInteraction(drawAutoInteraction);
    map.removeInteraction(drawHandInteraction);
  });

  return {
    drawAutoInteraction,
    drawHandInteraction,
    rollBackHand,
    resetSettings,
    addDrawInteractionByKey,
    isDraw,
  };
}
