import { AxiosRequestConfig } from 'axios';
import BaseCrud from '@/api/configs/base-crud';
import { TempAndPrecip } from '@/modules/meteo/components/Card/types/TempAndPrecip';

class MeteoCumulativeChartApiService extends BaseCrud<TempAndPrecip> {
  protected ENDPOINT = 'meteos';

  getSumMeteoData(id: number, options?: AxiosRequestConfig) {
    return super.getById(`${this.ENDPOINT}/${id}/data/sum`, options);
  }
}
const meteoCumulativeChartApiService = new MeteoCumulativeChartApiService();

export default meteoCumulativeChartApiService;
