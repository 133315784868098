import Feature, { FeatureLike } from 'ol/Feature';
import { Layer } from 'ol/layer';
import { Point } from 'ol/geom';
import Map from '@/modules/map/data/Map';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';
import { formatGeoJSONReadFeature } from '@/helpers/map';
import { fromLonLat } from 'ol/proj';
import AssayType from '@/dialogs/AssayDialog/types/AssayType';
import { Feature as FeatureTurf } from '@turf/helpers/dist/js/lib/geojson';

export default function useHelpers() {
  const {
    getPolygonByPoint, setIsRemove,
    setArea, setGeoJsonFeature,
    setPositionsFeature, setPointInPolygon, setPolygonInPoint, setDataFeature,
  } = useAdditionalFunctionsFeature();

  const getElementaryAreaOnMapByClick = (e, map: Map): Feature => {
    const features: FeatureLike[] = map.map.getFeaturesAtPixel(e.pixel, {
      layerFilter: (layer: Layer) => map.pointVector === layer,
      hitTolerance: 20,
    });

    if (features.length) {
      return getPolygonByPoint(features[0] as Feature<Point>);
    }

    const newLocation = map.map.getCoordinateFromPixel(e.pixel);
    const activeElementaryAreas = map.staticSource.getFeaturesAtCoordinate(newLocation);

    if (activeElementaryAreas.length) {
      return activeElementaryAreas[0];
    }

    return null;
  };

  const getElementaryAreasFromJsonData = (
    gridGeoJsonList: FeatureTurf[],
    activeGridType: AssayType,
  ) => {
    const positionPolygonsXY: {polygon: Feature, point: Feature<Point> }[][] = [];

    const { elementaryAreas, points } = gridGeoJsonList.reduce((value, item) => {
      // @ts-ignore
      if (!item.geometry?.coordinates.length) {
        return value;
      }

      const { properties } = item;
      // eslint-disable-next-line no-param-reassign
      item.properties = {};

      const elementaryAreaFeature = formatGeoJSONReadFeature(item);
      const point = new Feature({ geometry: new Point(fromLonLat(properties.point)) });

      setPointInPolygon(elementaryAreaFeature, point);
      setPolygonInPoint(point, elementaryAreaFeature);
      setArea(elementaryAreaFeature, properties.area);
      setArea(point, properties.area);
      setIsRemove(point, properties.isRemove);
      setPositionsFeature(elementaryAreaFeature, properties.positions);
      setPositionsFeature(point, properties.positions);
      const newData = { index: null, attributes: null, num: properties.num || null };
      setDataFeature(elementaryAreaFeature, newData);
      setDataFeature(point, newData);
      setGeoJsonFeature(elementaryAreaFeature);
      if (activeGridType === AssayType.Gost) {
        const [y, x] = properties.positions;
        if (!positionPolygonsXY[y]) {
          positionPolygonsXY[y] = [];
        }
        positionPolygonsXY[y][x] = { polygon: elementaryAreaFeature, point };
      }

      value.elementaryAreas.push(elementaryAreaFeature);
      value.points.push(point);

      return value;
    }, { elementaryAreas: [], points: [] });

    return { elementaryAreas, points, positionPolygonsXY };
  };

  return { getElementaryAreaOnMapByClick, getElementaryAreasFromJsonData };
}
