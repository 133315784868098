import script from "./ComparisonSlider.vue?vue&type=script&lang=ts&setup=true"
export * from "./ComparisonSlider.vue?vue&type=script&lang=ts&setup=true"

import "./ComparisonSlider.vue?vue&type=style&index=0&id=3a7ca6f8&lang=scss"

const __exports__ = script;

export default __exports__
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QResizeObserver});
