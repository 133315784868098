import { Ref, UnwrapRef } from 'vue';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';

export default function usePositionFunctionsFeature(
  positionsPolygons: Ref<UnwrapRef<{polygon: Feature, point: Feature<Point> }[][]>>,
) {
  const { getPositionsFeature, getIsRemove } = useAdditionalFunctionsFeature();

  const isNotEmptyPositionPolygon = (
    y: number,
    x: number,
    isNotRemoved = false,
  ) => (positionsPolygons.value[y] && positionsPolygons.value[y][x]
        && (!isNotRemoved ? !getIsRemove(positionsPolygons.value[y][x].point as Feature<Point>)
          : true));

  const isEdgePolygon = (polygon: Feature): boolean => {
    const [y, x] = getPositionsFeature(polygon);
    return !isNotEmptyPositionPolygon(y + 1, x) || !isNotEmptyPositionPolygon(y - 1, x)
            || !isNotEmptyPositionPolygon(y, x + 1) || !isNotEmptyPositionPolygon(y, x - 1);
  };

  const getPoint = (y: number, x: number, isNotRemoved = false): Feature<Point> => (
    isNotEmptyPositionPolygon(y, x, isNotRemoved)
      ? positionsPolygons.value[y][x].point as Feature<Point>
      : null);

  const getAutoActivePoints = (y: number, x: number): Feature<Point>[] => [
    getPoint(y + 1, x),
    getPoint(y - 1, x),
    getPoint(y, x + 1),
    getPoint(y, x - 1),
  ]
    .filter((item) => item);

  const getAllAutoActivePoints = (y: number, x: number): Feature<Point>[] => [
    getPoint(y + 1, x, true),
    getPoint(y - 1, x, true),
    getPoint(y, x + 1, true),
    getPoint(y, x - 1, true),
    getPoint(y + 1, x + 1, true),
    getPoint(y - 1, x - 1, true),
    getPoint(y - 1, x + 1, true),
    getPoint(y + 1, x - 1, true),
  ]
    .filter((item) => item);

  return {
    isNotEmptyPositionPolygon, isEdgePolygon, getAutoActivePoints, getPoint, getAllAutoActivePoints,
  };
}
