import Map from '@/modules/map/data/Map';
import { Modify } from 'ol/interaction';
import Feature, { FeatureLike } from 'ol/Feature';
import FieldFeature from '@/modules/map/features/FieldFeature';
import { Point } from 'ol/geom';
import { ModifyEvent } from 'ol/interaction/Modify';
import { onMounted, onUnmounted, ref } from 'vue';
import useAdditionalFunctionsFeature from '@/dialogs/AssayDialog/hooks/useAdditionalFunctionsFeature';

export default function useChangeLocationPoint(map: Map) {
  const {
    getPolygonByPoint,
    getLinePoint, getCoordinateFeature, getBackPointFeature,
  } = useAdditionalFunctionsFeature();

  const modifyPointInteraction: Modify = new Modify({
    source: map.pointSource,
    style: (feature: FeatureLike) => {
      FieldFeature.modifyStyle(feature);
      return null;
    },
    pixelTolerance: 20,

  });
  const isModifyPoint = ref(false);

  const modifyEndFunction = ({ features }: ModifyEvent) => {
    const point = features.item(0) as Feature<Point>;
    const newCoordinate = getCoordinateFeature(point);
    const line = getLinePoint(point);
    if (line) {
      line.getGeometry().setCoordinates([
        newCoordinate, getCoordinateFeature(line)[1]]);
    }

    const backPoint = getBackPointFeature(point);
    if (backPoint) {
      const backLine = getLinePoint(backPoint);
      backLine.getGeometry().setCoordinates([
        getCoordinateFeature(backLine)[0], newCoordinate]);
    }
  };

  const initModifyPoints = () => {
    map.addInteraction(modifyPointInteraction);

    let activePolygon: Feature = null;

    const pointerDragFunction = ({ coordinate: [x, y] }) => activePolygon
      .getGeometry().containsXY(x, y);

    modifyPointInteraction.on('modifystart', (e) => {
      isModifyPoint.value = true;
      // @ts-ignore
      map.map.on('pointerdrag', pointerDragFunction);
      modifyPointInteraction.removePoint();
      const point = e.features.item(0) as Feature<Point>;
      activePolygon = getPolygonByPoint(point);
    });
    modifyPointInteraction.on('modifyend', () => {
      isModifyPoint.value = false;
      // @ts-ignore
      map.map.un('pointerdrag', pointerDragFunction);
    });
  };

  const onChangeLocationPoint = () => {
    modifyPointInteraction.setActive(true);
    modifyPointInteraction.on('modifyend', modifyEndFunction);
  };

  const offChangeLocationPoint = () => {
    modifyPointInteraction.setActive(false);
    modifyPointInteraction.un('modifyend', modifyEndFunction);
  };

  onMounted(() => {
    initModifyPoints();
    modifyPointInteraction.setActive(false);
  });

  onUnmounted(() => {
    map.removeInteraction(modifyPointInteraction);
    modifyPointInteraction.un('modifyend', modifyEndFunction);
  });

  return { isModifyPoint, onChangeLocationPoint, offChangeLocationPoint };
}
