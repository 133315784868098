import { Row, Workbook, Worksheet } from 'exceljs';
import moment from 'moment';
import FileSaver from 'file-saver';

export function toExportFileName(excelFileName: string): string {
  return `${excelFileName}${moment().format(' YYYY_MM_DD')}`;
}

export function saveExcelFile(
  workbook: Workbook,
  excelFileName: string,
  isAddSaveDate = true,
) {
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob(
      [data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
    );
    FileSaver.saveAs(blob, `${isAddSaveDate ? toExportFileName(excelFileName) : excelFileName}.xlsx`);
  });
}

export function updateMaxWidthCells(row: Row, arrayLengthText: number[]): number[] {
  for (let i = 0; i < row.cellCount; i += 1) {
    const cell = row.getCell(i + 1);
    if (cell.value != null) {
      // eslint-disable-next-line no-param-reassign
      arrayLengthText[i] = Math.max(arrayLengthText[i], cell.text.length);
    }
  }

  return arrayLengthText;
}

export function updateWidthColumns(worksheet: Worksheet, arrayLengthText: number[], indent = 4) {
  arrayLengthText.forEach((length, index) => {
    // eslint-disable-next-line no-param-reassign
    worksheet.columns[index].width = length + indent;
  });
}

export function createArrayLengthText(length: number) {
  return Array.from(Array(length), () => 0);
}

export function setTotalColor(row: Row, color: string) {
  for (let i = 0; i < row.cellCount; i += 1) {
    const cell = row.getCell(i + 1);
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
      bgColor: { argb: color },
    };
  }
}
