const weatherIcons = {
  200: ['thunder.svg', 'thunder.svg'],
  201: ['thunder.svg', 'thunder.svg'],
  210: ['thunder.svg', 'thunder.svg'],
  211: ['thunder.svg', 'thunder.svg'],
  212: ['thunder.svg', 'thunder.svg'],
  221: ['thunder.svg', 'thunder.svg'],
  230: ['thunder.svg', 'thunder.svg'],
  231: ['thunder.svg', 'thunder.svg'],
  232: ['thunder.svg', 'thunder.svg'],

  300: ['rainy-4.svg', 'rainy-4.svg'],
  301: ['rainy-4.svg', 'rainy-4.svg'],
  302: ['rainy-4.svg', 'rainy-4.svg'],
  310: ['rainy-4.svg', 'rainy-4.svg'],
  311: ['rainy-4.svg', 'rainy-4.svg'],
  312: ['rainy-4.svg', 'rainy-4.svg'],
  313: ['rainy-4.svg', 'rainy-4.svg'],
  314: ['rainy-4.svg', 'rainy-4.svg'],
  321: ['rainy-4.svg', 'rainy-4.svg'],

  500: ['rainy-1.svg', 'rainy-1.svg'],
  501: ['rainy-2.svg', 'rainy-2.svg'],
  502: ['rainy-2.svg', 'rainy-2.svg'],
  503: ['rainy-3.svg', 'rainy-3.svg'],
  504: ['rainy-3.svg', 'rainy-3.svg'],
  511: ['rainy-7.svg', 'rainy-7.svg'],
  520: ['rainy-4.svg', 'rainy-4.svg'],
  521: ['rainy-5.svg', 'rainy-5.svg'],
  522: ['rainy-6.svg', 'rainy-6.svg'],
  531: ['rainy-6.svg', 'rainy-6.svg'],

  600: ['snowy-1.svg', 'snowy-1.svg'],
  601: ['snowy-2.svg', 'snowy-2.svg'],
  602: ['snowy-3.svg', 'snowy-3.svg'],
  611: ['rainy-7.svg', 'snowy-7.svg'],
  612: ['rainy-7.svg', 'snowy-7.svg'],
  613: ['rainy-7.svg', 'snowy-7.svg'],
  615: ['rainy-7.svg', 'snowy-7.svg'],
  616: ['rainy-7.svg', 'snowy-7.svg'],
  620: ['snowy-4.svg', 'snowy-4.svg'],
  621: ['snowy-5.svg', 'snowy-5.svg'],
  622: ['snowy-6.svg', 'snowy-6.svg'],

  800: ['day.svg', 'night.svg'],

  801: ['cloudy-day-1.svg', 'cloudy-night-1.svg'],
  802: ['cloudy-day-2.svg', 'cloudy-night-2.svg'],
  803: ['cloudy-day-3.svg', 'cloudy-night-3.svg'],
  804: ['cloudy.svg', 'cloudy.svg'],
};

export default function weatherIcon(code: number, pod: string) {
  return (pod === 'd' ? weatherIcons[code][0] : weatherIcons[code][1]);
}
