export enum SowUnitValue {
  tn ='tn',
  cwt ='cwt',
  kg ='kg',
  num = 'num',
  su = 'su',
  suha ='suha',
  tnha = 'tnha',
  cwtha = 'cwtha',
  kgha = 'kgha',
  numha = 'numha'
}
export const textUnits = {
  [SowUnitValue.tn]: 'т',
  [SowUnitValue.cwt]: 'ц',
  [SowUnitValue.kg]: 'кг',
  [SowUnitValue.num]: 'шт.',
  [SowUnitValue.su]: 'cwt',
  [SowUnitValue.suha]: 'пос.ед',
  [SowUnitValue.tnha]: 'т/га',
  [SowUnitValue.cwtha]: 'ц/га',
  [SowUnitValue.kgha]: 'кг/га',
  [SowUnitValue.numha]: 'шт./га',
};

export const sowUnits = [
  { value: SowUnitValue.tn, text: textUnits[SowUnitValue.tn] },
  { value: SowUnitValue.cwt, text: textUnits[SowUnitValue.cwt] },
  { value: SowUnitValue.kg, text: textUnits[SowUnitValue.kg] },
  { value: SowUnitValue.num, text: textUnits[SowUnitValue.num] },
  { value: SowUnitValue.su, text: textUnits[SowUnitValue.su] },
  { value: SowUnitValue.suha, text: textUnits[SowUnitValue.suha] },
  { value: SowUnitValue.tnha, text: textUnits[SowUnitValue.tnha] },
  { value: SowUnitValue.cwtha, text: textUnits[SowUnitValue.cwtha] },
  { value: SowUnitValue.kgha, text: textUnits[SowUnitValue.kgha] },
];

export enum FallowTypesValue {
  clear = 'clear',
  occupied = 'occupied',
  half = 'half',
  sideral = 'sideral'
}

export const textFallow = {
  [FallowTypesValue.clear]: 'Чистый',
  [FallowTypesValue.occupied]: 'Занятый',
  [FallowTypesValue.half]: 'Полупар',
  [FallowTypesValue.sideral]: 'Сидеральный',
};

export const fallowTypes = [
  { value: FallowTypesValue.clear, text: textFallow[FallowTypesValue.clear] },
  { value: FallowTypesValue.occupied, text: textFallow[FallowTypesValue.occupied] },
  { value: FallowTypesValue.half, text: textFallow[FallowTypesValue.half] },
  { value: FallowTypesValue.sideral, text: textFallow[FallowTypesValue.sideral] },
];
