const saveGraphicsMenu = [
  {
    title: 'Сохранить в JPEG',
    type: 'application/jpeg',
  },
  {
    title: 'Сохранить в PNG',
    type: 'application/png',
  },
  {
    title: 'Сохранить в SVG',
    type: 'application/svg',
  },
];
export default saveGraphicsMenu;
