import { defineStore } from 'pinia';
import { Field } from '@/modules/field/types/Field';
import { ScoutingTask } from '@/models/ScoutingTask';
import ElementaryAreasScheme from '@/dialogs/AssayDialog/types/ElementaryAreasScheme';
import { SubStage } from '@/dialogs/ReportScoutingTaskDialog/types/GrowthStage';

export type State = {
  activeField: Field;
  scoutingTacks: ScoutingTask[],
  elementaryAreasSchemes: ElementaryAreasScheme[],
  species: SubStage[]
};

export const useFieldStore = defineStore('field', {
  state: ():State => ({
    activeField: null,
    scoutingTacks: [],
    elementaryAreasSchemes: [],
    species: [],
  }),
  actions: {
    setActiveField(activeField) {
      this.activeField = activeField;
    },
    setScoutingTasks(scoutingTasks: ScoutingTask[]) {
      this.scoutingTacks = scoutingTasks;
    },

    setElementaryAreasSchemes(elementaryAreasSchemes: ElementaryAreasScheme[]) {
      this.elementaryAreasSchemes = elementaryAreasSchemes;
    },

    setSpecies(species: SubStage[]) {
      this.species = species;
    },

    clearAllData() {
      this.$reset();
    },
  },
});
