const timezoneTypes = [
  {
    value: 1,
    text: 'UTC+2, Калининград',
  },
  {
    value: 2,
    text: 'UTC+3, Москва',
  },
  {
    value: 3,
    text: 'UTC+4, Астрахань',
  },
  {
    value: 4,
    text: 'UTC+5, Екатерингбург',
  },
  {
    value: 5,
    text: 'UTC+6, Омск',
  },
  {
    value: 6,
    text: 'UTC+7, Красноярск',
  },
  {
    value: 7,
    text: 'UTC+8, Иркутск',
  },
  {
    value: 8,
    text: 'UTC+9, Якутск',
  },
  {
    value: 9,
    text: 'UTC+10, Владивосток',
  },
  {
    value: 10,
    text: 'UTC+11, Магадан',
  },
];
export default timezoneTypes;
