import Highcharts from 'highcharts';
import useExporting from 'highcharts/modules/exporting';
import useWindbarb from 'highcharts/modules/windbarb';
import useHeatmap from 'highcharts/modules/heatmap';
import useData from 'highcharts/modules/data';
import useAccessibility from 'highcharts/modules/accessibility';
import useOfflineExporting from 'highcharts/modules/offline-exporting';

useExporting(Highcharts);
useOfflineExporting(Highcharts);
useHeatmap(Highcharts);
useData(Highcharts);
useWindbarb(Highcharts);
useAccessibility(Highcharts);
// @ts-ignore
Highcharts.getSVG = (charts, options, callback) => {
  const svgArr = [];
  let top = 0;
  let width = 0;
  const addSVG = (svgres) => {
    // Grab width/height from exported chart
    // eslint-disable-next-line no-useless-escape
    const svgWidth = +svgres.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
    // eslint-disable-next-line no-useless-escape
    const svgHeight = +svgres.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
    // Offset the position of this chart in the final SVG
    let svg = svgres.replace('<svg', `<g transform="translate(0,${top})" `);
    svg = svg.replace('</svg>', '</g>');
    top += svgHeight;
    width = Math.max(width, svgWidth);
    svgArr.push(svg);
  };
  const exportChart = (i: number) => {
    if (i === charts.length) {
      return callback(`<svg height="${top}" width="${width
      }" version="1.1" xmlns="http://www.w3.org/2000/svg">${svgArr.join('')}</svg>`);
    }
    const svg = charts[i].getSVG(options);
    addSVG(svg);
    return exportChart(i + 1); // Export next only when this SVG is received
  };
  exportChart(0);
};

/**
 * Create a global exportCharts method that takes an array of charts as an argument,
 * and exporting options as the second argument
 */
// @ts-ignore
Highcharts.exportCharts = (charts, options) => {
  // eslint-disable-next-line no-param-reassign
  options = Highcharts.merge(Highcharts.getOptions().exporting, options);
  // Get SVG asynchronously and then download the resulting SVG
  // @ts-ignore
  Highcharts.getSVG(charts, options, (svg) => {
    Highcharts.downloadSVGLocal(svg, options, () => {
      // eslint-disable-next-line no-alert
      alert('Ошибка при сохранении файла');
    });
  });
};
// @ts-ignore
Highcharts.seriesTypes.windbarb.prototype.beaufortName = ['Штиль',
  'Легкий ветер', 'Легкий бриз', 'Слабый ветер', 'Умеренный ветер',
  'Свежий бриз', 'Сильный ветер', 'Близкий шторм', 'Шторм',
  'Сильный ветер', 'Шторм', 'Свирепый шторм',
  'Ураган',
];

Highcharts.setOptions({
  lang: {
    loading: 'Загрузка...',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
    shortMonths: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Нояб',
      'Дек',
    ],
    rangeSelectorFrom: 'С',
    rangeSelectorTo: 'По',
    rangeSelectorZoom: 'Период',
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG',
    printChart: 'Напечатать график',
    resetZoom: 'Cбросить Zoom',
    viewFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Обычный режим',
  },
  exporting: {
    fallbackToExportServer: false, // Ensure the export happens on the client side or not at all
    buttons: {
      contextButton: {
        menuItems: [
          'printChart', 'downloadPNG', 'downloadJPEG', 'downloadSVG',
        ],
      },
    },
  },
});
