import { ref } from 'vue';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { NodeModel } from '@/dialogs/MeteoStationDialog/types/NodeModel';

export default function useNodeModelsDataSource(): DataSource<NodeModel> {
  const nodeModels = ref<NodeModel[]>(null);

  const {
    get, dataSubject, ...anyData
  } = useDataSource<NodeModel>('meteo_node_models', (search: string) => {
    if (!nodeModels.value) {
      get({ params: { search } })
        .pipe(
          catchError(() => of([])),
          tap((response: NodeModel[]) => {
            dataSubject.next(response);
            nodeModels.value = response;
          }),
          map((response: NodeModel[]) => response),
        ).subscribe();
    } else {
      dataSubject.next(nodeModels.value);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
