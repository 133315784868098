import { toRefs } from 'vue';
import useManagerMap from '@/modules/map/hooks/useManagerMap';
import ScoutingTaskFeature from '@/modules/map/features/ScoutingTaskFeature';
import { ScoutingTask } from '@/models/ScoutingTask';
import { useMapStore } from '@/stores';

export default function useScotingMap() {
  const { clusterSource, selected, removeClusterSourceFeature } = useManagerMap();
  const mapStore = useMapStore();
  const { scoutingTasks, showScoutingAll } = toRefs(mapStore);

  const addScoutingTasks = (scoutingTaskFeature: ScoutingTaskFeature) => {
    const newScoutingTasks = [...(scoutingTasks.value as ScoutingTaskFeature[])];
    newScoutingTasks.push(scoutingTaskFeature);
    mapStore.setScoutingTasks(newScoutingTasks);
    clusterSource.addFeature(scoutingTaskFeature);
  };

  const updateScoutingTasksData = (scoutingTask: ScoutingTask) => {
    const newScoutingTasks = [...(scoutingTasks.value as ScoutingTaskFeature[])];
    const scoutingTaskFeature = newScoutingTasks.find((item) => item.props.id === scoutingTask.id);

    scoutingTaskFeature.setProps(scoutingTask);
    scoutingTaskFeature.setGeometryFeature(scoutingTask.location);
  };

  const createAndSetScoutingTasks = (scoutingTasks: ScoutingTask[]) => {
    const featureScoutingTasks = scoutingTasks
      .map((scoutingTask) => new ScoutingTaskFeature(scoutingTask));
    mapStore.setScoutingTasks(featureScoutingTasks);

    clusterSource.addFeatures(featureScoutingTasks);
    return featureScoutingTasks;
  };

  const selectedScoutingTasks = (scoutingTaskFeatures: ScoutingTaskFeature[]) => {
    scoutingTaskFeatures.forEach((item) => {
      selected.push(item);
    });
  };

  const removeSelectedScoutingTasks = (scoutingTaskFeatures: ScoutingTaskFeature[]) => {
    scoutingTaskFeatures.forEach((item) => {
      selected.remove(item);
    });
  };

  const removeScoutingTasks = (scoutingTasks: ScoutingTaskFeature[]) => {
    scoutingTasks.forEach((feature) => {
      removeClusterSourceFeature(feature);
    });
  };

  const setShowScoutingAll = (showScoutingAll: boolean) => {
    mapStore.setShowScoutingAll(showScoutingAll);
  };

  return {
    showScoutingAll,
    setShowScoutingAll,
    addScoutingTasks,
    updateScoutingTasksData,
    createAndSetScoutingTasks,
    selectedScoutingTasks,
    removeSelectedScoutingTasks,
    removeScoutingTasks,
  };
}
