import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ref } from 'vue';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { District } from '@/models/District';

export default function useDistrictsDataSource(): DataSource<District> {
  const oldFilter = ref(null);

  const {
    get, dataSubject, ...anyData
  } = useDataSource<District>('districts', (search: string, filter: any) => {
    if (oldFilter.value !== filter) {
      oldFilter.value = filter;
      get({ params: { search, ...filter } })
        .pipe(
          catchError(() => of([])),
          map((data) => data),
        ).subscribe((data) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dataSubject.next(data);
        });
    }
  });

  return {
    dataSubject,
    get,
    ...anyData,
  };
}
