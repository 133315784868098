import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ref } from 'vue';
import useDataSource, { DataSource } from '@/common/Autocomplete/useDataSource';
import { Module } from '@/dialogs/MeteoStationDialog/types/Module';

export default function useModulesDataSource(): DataSource<Module> {
  const modules = ref<Module[]>(null);

  const {
    get, dataSubject, ...anyData
  } = useDataSource<Module>('meteo_module_models', (search: string) => {
    if (!modules.value) {
      get({ params: { search } })
        .pipe(
          catchError(() => of([])),
          tap((response: Module[]) => {
            dataSubject.next(response);
            modules.value = response;
          }),
          map((response: Module[]) => response),
        ).subscribe();
    } else {
      dataSubject.next(modules.value);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
