import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
import { AnalysesType } from '@/dialogs/AssayDialog/types/AnalysesData';
import {
  groupingOfSoilsAccordingToAvailability, Indicator,
  titleIndicator,
} from '@/dialogs/AssayDialog/constants/spectrogramIndicators';

export const getIndexIndicator = (
  dataIndicator: number,
  settingsIndicator: [number, number, number, number, number, number, ],
) => {
  if (dataIndicator != null) {
    // eslint-disable-next-line default-case
    switch (true) {
      case dataIndicator < settingsIndicator[0]: return Indicator.veryLow;
      case dataIndicator >= settingsIndicator[0]
      && dataIndicator < settingsIndicator[1]: return Indicator.low;
      case dataIndicator >= settingsIndicator[1]
      && dataIndicator < settingsIndicator[3]: return Indicator.medium;
      case dataIndicator >= settingsIndicator[3]
      && dataIndicator < settingsIndicator[4]: return Indicator.high;
      case dataIndicator >= settingsIndicator[4]: return Indicator.veryHigh;
    }
  }
  return Indicator.veryLow;
};

export const getSecurityDataByIndicator = (
  indicator: IndicatorType,
  analysesTypes: AnalysesType,
  dataIndicator: number,
) => {
  const { color, indicator: settingsIndicator } = groupingOfSoilsAccordingToAvailability[indicator];
  const groupingOfSoils = Array.isArray(settingsIndicator)
    ? settingsIndicator
    : settingsIndicator[analysesTypes[indicator]];

  const indexIndicator = getIndexIndicator(dataIndicator, groupingOfSoils);

  return {
    color: color[indexIndicator] || color[color.length - 1],
    title: titleIndicator[indexIndicator] || titleIndicator[Indicator.veryHigh],
  };
};
