import useManagerMap from '@/modules/map/hooks/useManagerMap';
import meteoApiService from '@/api/meteoApiService';
import { Meteo } from '@/models/Meteo';
import MeteoFeature from '@/modules/map/features/MeteoFeature';
import { useMapStore, useMeteoStore } from '@/stores';

export default function useMeteoMap() {
  const mapStore = useMapStore();
  const meteoStore = useMeteoStore();

  const { addSourceFeature } = useManagerMap();
  const updateMeteoData = (meteo: Meteo) => {
    const { meteos } = mapStore;
    const meteoFeature = meteos.find((item) => item.props.id === meteo.id);

    meteoFeature.setProps(meteo);
    meteoFeature.setGeometryFeature(meteo.location);

    if (meteoStore.activeMeteo.id === meteo.id) {
      meteoStore.setActiveMeteo(meteo);
    }
  };
  const loadAndUpdateMeteo = (id: number) => {
    meteoApiService.getByID(id)
      .subscribe((meteo) => {
        updateMeteoData(meteo);
      });
  };

  const addMeteo = (meteo: MeteoFeature) => {
    const { meteos } = mapStore;
    addSourceFeature(meteo);
    mapStore.setMeteos([...meteos, meteo] as MeteoFeature[]);
  };

  return {
    loadAndUpdateMeteo, updateMeteoData, addMeteo,
  };
}
